import { Component, Input } from '@angular/core';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { Meeting } from 'src/app/models/meeting.model';
import { Participation } from 'src/app/models/participation.model';
import { Seminar } from 'src/app/models/seminar.model';

@Component({
  selector: 'app-invitation-pdf',
  styleUrl: './invitation-pdf.component.scss',
  templateUrl: './invitation-pdf.component.html',
})
export class InvitationPdfComponent {
  @Input() translatePrefix: string = 'PDF.INVITATION';
  @Input() participation?: Participation;
  @Input() seminar?: Seminar;
  @Input() meeting?: Meeting;
  @Input() conferenceVenue?: ConferenceVenue;
  today: Date = new Date();
    
  constructor() { }
}