import { Injectable } from "@angular/core";
import { Form } from "../models/form.model";
import { RestService } from "./rest.service";
import { GetQuery } from "../models/get-query.model";
import { FormFilter } from "../models/form-filter.model";
import { AuthService } from "./auth.service";
import { Observable, Subject, finalize } from "rxjs";

@Injectable()
export class FormService {
    data?: Form;
    orderDirection: string = 'asc';
    search: FormFilter = new FormFilter();
    isError: boolean = false;
    errorCode: string = '';

    private refreshSubject: Subject<boolean> = new Subject();
    refresh: Observable<boolean> = this.refreshSubject.asObservable();
    
    constructor(private restService: RestService, private authService: AuthService) { }

    updateData() {
        let getQuery: GetQuery = new GetQuery();
        getQuery.keyMap.set("orderDirection", this.orderDirection??"");
        
        this.refreshSubject.next(true);
        this.restService.allForm(this.search, getQuery).pipe(
            finalize(() => {
                this.refreshSubject.next(false);
            })
        ).subscribe({
            next: (res : Form) => {
                this.data = res;
                this.isError = false;
                this.errorCode = '';
            }, error: err => {
                console.log(err);
                this.isError = true;
                this.errorCode = String(err.status);
                this.authService.checkAuth(err.status);
            }
        });
    }
}