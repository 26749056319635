<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ translatePrefix + '.HEADER' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form name="createRevisorForm" *ngIf="user">
            <div class="mb-4 alert alert-info" role="alert">
                {{ 'FORMS.REQUIRED' | translate }}
            </div>
            <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                <div class="d-flex">
                    <i class="fa-solid fa-xmark mt-1 me-2"></i>
                    {{ 'ERRORCODE.' + errorCode | translate }}
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-3 mb-3">
                    <label for="salutaion" class="mb-1">{{ translatePrefix + '.SALUTATION' | translate }}</label>
                    <select class="form-select" id="salutaion" name="salutaion" [(ngModel)]="user.salutationId" [ngClass]="{'required-input': !user.salutationId}" required>
                        <ng-container *ngFor="let salutaion of salutaions">
                            <option class="form-option" [value]="salutaion[0]">{{ translatePrefix + '.SALUTATIONS.' + salutaion[1] | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="username" class="mb-1">{{ translatePrefix + '.USERNAME' | translate }}</label>
                    <input type="text" class="form-control" id="username" name="username" [(ngModel)]="user.userName" [ngClass]="{'required-input': !user.userName}" required>
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="email" class="mb-1">{{ translatePrefix + '.EMAIL' | translate }}</label>
                    <input type="text" class="form-control" id="email" name="email" [(ngModel)]="user.eMail" [ngClass]="{'required-input': !user.eMail}" required>
                </div>                
                <div class="form-group col-sm-3 mb-3">
                    <label for="organisation" class="mb-1">{{ translatePrefix + '.ORGANISATION' | translate }}</label>
                    <select class="form-select" id="organisation" name="organisation" [(ngModel)]="user.organisationId" [ngClass]="{'required-input': !user.organisationId}">
                        <ng-container *ngIf="organisationsList && organisationsList.length != 0">
                            <option *ngFor="let id of organisationsList" class="form-option" [ngValue]="id.organisationId">{{ id.organisationValue }}</option>
                        </ng-container>
                        <option *ngIf="organisationsList && organisationsList.length == 0" class="form-option">{{ translatePrefix + '.NOORGANISATION' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="position" class="mb-1">{{ translatePrefix + '.POSITION' | translate }}</label>
                    <input type="text" class="form-control" id="position" name="position" [(ngModel)]="user.position">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="name" class="mb-1">{{ translatePrefix + '.NAME' | translate }}</label>
                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="user.firstName" [ngClass]="{'required-input': !user.firstName}" required>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-3 mb-3">
                    <label for="zip" class="mb-1">{{ translatePrefix + '.ZIP' | translate }}</label>
                    <input type="text" class="form-control" id="zip" name="zip" [(ngModel)]="user.zip">
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="location" class="mb-1">{{ translatePrefix + '.LOCATION' | translate }}</label>
                    <input type="text" class="form-control" id="location" name="location" [(ngModel)]="user.location">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="street" class="mb-1">{{ translatePrefix + '.STREET' | translate }}</label>
                    <input type="text" class="form-control" id="street" name="street" [(ngModel)]="user.street">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="d-flex w-100 justify-content-end">
            <button class="btn btn-secondary me-1" (click)="submit()" [disabled]="validate()">{{ translatePrefix + '.BUTTONS.SAVE' | translate }}</button>
            <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-container>