import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { GetQuery } from 'src/app/models/get-query.model';
import { Meeting } from 'src/app/models/meeting.model';
import { AuthService } from 'src/app/services/auth.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-invoice-info-modal',
  styleUrl: './invoice-info-modal.component.scss',
  templateUrl: './invoice-info-modal.component.html',
})
export class InvoiceInfoModalComponent implements OnInit {
  @Input() translatePrefix: string = '';
  @Input() meeting?: Meeting;
  @Input() modal?: NgbActiveModal;
  isLoading: boolean = false;
  isError: boolean = false;
  errorCode: string = '';
  
  @Output() invoiceSet: EventEmitter<any> = new EventEmitter();
    
  constructor(private restService: RestService, private authService: AuthService) { }

  ngOnInit() {
    if(this.meeting)
    this.meeting = new Meeting(this.meeting.meetingId, this.meeting.seminarId, this.meeting.shorthand, this.meeting.series, this.meeting.startDate, this.meeting.endDate,
        this.meeting.overnightStays, this.meeting.externalReportingDate, this.meeting.internalReportingDate, this.meeting.documentDispatchDate, this.meeting.invoiceDate, 
        this.meeting.dueDate, this.meeting.signedParticipants, this.meeting.expectedParticipants, this.meeting.conferenceVenueId, this.meeting.media, this.meeting.userId, 
        this.meeting.userSignId, this.meeting.speakerIds, this.meeting.invitation, this.meeting.invoiceInfo
    );
  }  

  submit() {
    let payload = new GetQuery();
    payload.keyMap.set('meetingId', this.meeting?.meetingId ?? '');
    payload.keyMap.set('invoiceInfo', this.meeting?.invoiceInfo ?? '');

    this.restService.setInvoiceInfo(payload).pipe(
        finalize(() => {
            this.isLoading = false;
        })
    ).subscribe({
        next: res => {
            this.emitSet();
            this.modal?.dismiss();
        }, error: err => {
            this.isError = true;
            this.errorCode = String(err.status);
            this.authService.checkAuth(err.status);
        }
    });
  }

  emitSet() {
    this.invoiceSet.emit(this.meeting);
  }
}