<div class="d-flex flex-column pt-2 h-100 w-100" style="overflow-y: auto;">
    <div class="d-flex justify-content-between nav-border pb-3 pt-2">
        <button class="nav-button" [routerLink]="homePath" (click)="close()">
            <i class="fa-solid fa-house fa-lg"></i>
        </button>
        <button class="nav-button" (click)="close()">
            <i class="fa-solid fa-xmark fa-lg"></i>
        </button>
    </div>
    <ng-container *ngFor="let route of adminRoutes">
        <div class="nav-item">
            <button class="w-100 nav-button text-start" [routerLink]="route[1]" (click)="close()">
                <div class="text-primary" style="font-size: large; font-weight: 600;">{{ translatePrefix + '.' + route[0] | translate }}</div>
                <ng-container *ngIf="signUps && signUps > 0 && route[0] === 'PARTICIPANT'"><div class="text-primary" style="font-size: small;">({{  signUps }})</div></ng-container>
            </button>
        </div>
    </ng-container>
    <!--<div class="nav-item">
        <a>{{ translatePrefix + ".PARTICIPANT_CERTIFICATION" | translate }}</a>
    </div>
    <div class="nav-item">
        <a>{{ translatePrefix + ".MANAGE_DOCS" | translate }}</a>
    </div>-->
</div>