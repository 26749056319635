export class MeetingSingUp {
    meetingId?: string = '';
    participantId?: string = '';
    info?: string | null = null;
    date?: Date = new Date();
    participating: boolean = false;
    careerId?: number | null = null;
    statusId?: number | null = null;
    position?: number | null = null;
    partTime: boolean = false;
    overnightStay: boolean = false;
    departmentId?: string | null = null;
    reportingOfficeUserId?: string | null = null;
    confirmationDate?: Date;
    cancellationDate?: Date | null = new Date();
    invoiceStatus?: number | null = null;
    invoiceIdentifier?: string | null = null;
    reason?: string | null = null;
    editConfirmedEntry: boolean = false;

    constructor(meetingId?: string, participantId?: string, info?: string | null, date?: Date, participating?: boolean, careerId?: number | null, statusId?: number | null,
        position?: number | null, partTime?: boolean, overnightStay?: boolean, departmentId?: string | null, reportingOfficeUserId?: string | null, confirmationDate?: Date, cancellationDate?: Date | null,
        invoiceStatus?: number | null, invoiceIdentifier?: string | null, reason?: string | null, editConfirmedEntry?: boolean) {

        this.meetingId = meetingId;
        this.participantId = participantId;
        this.info = info;
        this.date = date;
        this.participating = participating === undefined ? false : participating;
        this.careerId = careerId;
        this.statusId = statusId;
        this.position = position;
        this.partTime = partTime === undefined ? false : partTime;
        this.overnightStay = overnightStay === undefined ? false : overnightStay;
        this.departmentId = departmentId;
        this.reportingOfficeUserId = reportingOfficeUserId;
        this.confirmationDate = confirmationDate;
        this.cancellationDate = cancellationDate;
        this.invoiceStatus = invoiceStatus;
        this.invoiceIdentifier = invoiceIdentifier;
        this.reason = reason;
        this.editConfirmedEntry = editConfirmedEntry === undefined ? false : editConfirmedEntry;
    }
}