import { PageableFilter } from "./pageable-filter.model";

export class KeyDatesFilter extends PageableFilter {
    dispatchDateFrom?: Date | null | string = null;
    dispatchDateTo?: Date | null | string = null;
    internalDateFrom?: Date | null | string = null;
    internalDateTo?: Date | null | string = null;
    year?: number | null = null;

    constructor(page: number, pageSize: number, orderColumn?: string | null, orderDirection?: string | null, 
        dispatchDateFrom?: Date | null | string, dispatchDateTo?: Date | null | string, internalDateFrom?: Date | null | string, internalDateTo?: Date | null | string, year?: number | null) {
        super(page, pageSize, orderColumn, orderDirection);

        this.dispatchDateFrom = dispatchDateFrom;
        this.dispatchDateTo = dispatchDateTo;
        this.internalDateFrom = internalDateFrom;
        this.internalDateTo = internalDateTo;
        this.year = year;
    }
}