import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as GLOBALS from ".././globals";
import { Credentials } from "../models/credentials.model";
import { User } from "../models/user.model";
import { Observable, Subject, finalize } from "rxjs";

@Injectable()
export class AuthService { 
    authUser?: User;
    isError: boolean = false;
    errorCode: string = '';

    private loginSubject: Subject<boolean> = new Subject();
    loginEvent: Observable<boolean> = this.loginSubject.asObservable();
    
    constructor(private restService: RestService, private router: Router, private route: ActivatedRoute) { }

    login(user: Credentials, redirectRoute: string, params?: any) {
        this.loginSubject.next(true);

        this.restService.login(user).pipe(
            finalize(() => {
                this.loginSubject.next(false);
            })
        ).subscribe({
            next: (res : User) => {
                this.authUser = res;
                if(this.authUser?.roleName == GLOBALS.noaccountRole) {
                    this.router.navigate(['/' + GLOBALS.routeParameterCreateAccount]);
                } else {
                    if(this.authUser?.isInitialPassword) {
                        this.router.navigate(['/' + GLOBALS.routeParameterSetInitialPassword]);
                    } else {
                        if(this.authUser?.roleName == GLOBALS.participantRole) { // Reporting Office participant application
                            if(params) {
                                this.router.navigate(['/' + GLOBALS.routeParameterParticipant, params]);
                            } else {
                                this.router.navigate(['/' + GLOBALS.routeParameterParticipant]);
                            }
                        } else {
                            if(params && params['seminarname'] && params['meetingId']) { // Admin participant application
                                this.router.navigate(['/' + GLOBALS.routeParameterParticipant, params]); 
                            } else {
                                this.router.navigate([redirectRoute]);
                            }
                        }
                    }
                }
                this.isError = false;
                this.errorCode = '';
            },
            error: err => {
                console.log(err);
                this.isError = true;
                this.errorCode = String(err.status) != '404' ? String(err.status) : 'WRONGPW';
            }
        });
    }

    logout() {
        this.loginSubject.next(true);
        
        this.restService.logout().pipe(
            finalize(() => {
                this.loginSubject.next(false);
            })
        ).subscribe({
            next: res => {
                this.authUser = undefined;
                this.router.navigate(['/' + GLOBALS.routeParameterLogin]);
                this.isError = false;
                this.errorCode = '';
            },
            error: err => {
                console.log(err);
                this.checkAuth(err.status);
                this.isError = true;
                this.errorCode = String(err.status);
            }
        });
    }

    info() {
        this.loginSubject.next(true);

        this.restService.info().pipe(
            finalize(() => {
                this.loginSubject.next(false);
            })
        ).subscribe({
            next: res => {
                this.authUser = res;
                if(this.router.url.includes(GLOBALS.routeParameterLogin)) this.router.navigate(['../']);
            },
            error: err => {
                console.log(err);
                this.checkAuth(err.status);
            }
        });
    }

    checkAuth(error: number, originUrl?: string) {
        if (error == 401) { // Unauthorized Access
            if(!this.router.url.includes(GLOBALS.routeParameterSetPassword) && !this.router.url.includes(GLOBALS.routeParameterSeminarRegistration)) 
                this.router.navigate(['/' + GLOBALS.routeParameterLogin, { redirectTo: originUrl ? originUrl : this.router.url }]);
        } else if (error == 403) { // Forbidden Access
            this.router.navigate(['/' + GLOBALS.routeParameterForbidden]);
        }
    }
}