<div>
    <div class="row justify-content-center mx-auto">
        <div *ngIf="!isLoading" class="col-sm-10 col-md-8 col-lg-6 col-xl-5 d-flex justify-content-center">
            <div class="card w-100">
                <div class="card-header">
                    <h5 class="card-title">{{ translatePrefix + '.HEADER' | translate }}</h5>
                </div>
                <div class="card-body">
                    <form name="loginForm">
                        <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                            <div class="d-flex">
                                <i class="fa-solid fa-xmark mt-1 me-2"></i>
                                {{ 'ERRORCODE.' + errorCode | translate }}
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="username" class="mb-1">{{ translatePrefix + '.USERNAME' | translate }}</label>
                            <input type="text" autocomplete="username" class="form-control" id="username" name="username" [(ngModel)]="user.username" required>
                        </div>
                        <div class="form-group">
                            <label for="password" class="mb-1">{{ translatePrefix + '.PASSWORD' | translate }}</label>
                            <div class="password-wrap">
                                <input type="password" autocomplete="current-password" class="form-control" id="password" name="password" [(ngModel)]="user.password" required>
                                <button type="button" class="password-button" (click)="show()">
                                    <i id="lockIcon" class="fa-solid fa-lock"></i>
                                </button>
                                <a [routerLink]="pwForgottenPath">{{ translatePrefix + '.FORGOT' | translate }}</a>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-end my-1">
                        <button type="button" class="btn btn-primary" style="width: 150px;" (click)="login()" [disabled]="validate()"> {{ translatePrefix + '.BUTTON' | translate }} </button>
                    </div> 
                </div>
            </div>
        </div>
        <div *ngIf="!isLoading" class="d-flex w-100 justify-content-center">
            <div class="pt-3">
                <a [routerLink]="registrationPath">
                    {{ translatePrefix + '.REGISTRATION' | translate }} 
                    <i class="fa-solid fa-caret-right ms-1"></i>
                </a>
            </div>
        </div>
        <div *ngIf="isLoading" class="col d-flex justify-content-center mt-4">
            <i class="fa-solid fa-circle-notch fa-spin fa-2xl"></i>
        </div>
    </div>
</div>