import { PageableFilter } from "./pageable-filter.model";

export class DepartmentFilter extends PageableFilter {
    shorthand?: string | null = null;
    name?: string | null = null;

    constructor(page: number, pageSize: number, orderColumn?: string | null, orderDirection?: string | null, shorthand?: string | null, name?: string | null) {
        super(page, pageSize, orderColumn, orderDirection);
        this.shorthand = shorthand;
        this.name = name;
    }
}