import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetQuery } from '../models/get-query.model';
import { Observable, catchError, throwError } from 'rxjs';
import { Credentials } from '../models/credentials.model';
import * as GLOBALS from "./../globals";
import { User } from '../models/user.model';
import { PasswordForgotten } from '../models/password-forgotten.model';
import { UserFilter } from '../models/user-filter.model';
import { UserResult } from '../models/user-result.model';
import { Form } from '../models/form.model';
import { FormFilter } from '../models/form-filter.model';
import { Seminar } from '../models/seminar.model';
import { SeminarFilter } from '../models/seminar-filter.model';
import { SeminarResult } from '../models/seminar-result.model';
import { ConferenceVenue } from '../models/conference-venue.model';
import { ConferenceVenueResult } from '../models/conference-venue-result.model';
import { ConferenceVenueFilter } from '../models/conference-venue-filter.model';    
import { Department } from '../models/department.model';
import { Speaker, SpeakerNames } from '../models/speaker.model';
import { Participant } from '../models/participant.model';
import { ParticipantFilter } from '../models/participant-filter.model';
import { SpeakerFilter } from '../models/speaker-filter.model';
import { DepartmentFilter } from '../models/department-filter.model';
import { Meeting } from '../models/meeting.model';
import { ParticipationFilter } from '../models/participation-filter.model';
import { NextMeeting } from '../models/next-meeting.model';
import { NextMeetingFilter} from '../models/next-meeting-filter.model';
import { KeyDatesFilter } from '../models/keydates-filter.model';
import { KeyDatesResult } from '../models/keydates-result.model';
import { MeetingSingUp } from '../models/meeting-sign-up.model';
import { ParticipantConfirmation } from '../models/participant-confirmation.model';
import { SpeakerResult } from '../models/speaker-result.model';
import { StatisticsFilter } from '../models/statistics-filter.model';


@Injectable()
export class RestService { 
    constructor(private httpClient: HttpClient) { }

    // Auth
    // Login
    public login(credentials: Credentials): Observable<User> {
        let url = GLOBALS.restParameterAccount + GLOBALS.restParameterLogin;
        return this.post(url, credentials);
    }

    // Logout
    public logout(): Observable<any> {
        let url = GLOBALS.restParameterAccount + GLOBALS.restParameterLogout;
        return this.post(url, undefined);
    }

    // Info
    public info() {
        let url = GLOBALS.restParameterAccount + GLOBALS.restParameterInfo;
        return this.get(url);
    }

    // set new Password
    public postPassword(resetData: PasswordForgotten): Observable<any> {
        let url = GLOBALS.restParameterAccount + GLOBALS.restParameterPassword + GLOBALS.restParameterForgotten + GLOBALS.restParameterSet;
        return this.post(url, resetData);
    }

    // set initial Password
    public postInitialPassword(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterAccount + GLOBALS.restParameterPassword + GLOBALS.restParameterInitial + GLOBALS.restParameterSet;
        return this.postWithQuery(url, undefined, getQuery);
    }

    // Request Password change
    public requestPassword(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterAccount + GLOBALS.restParameterPassword + GLOBALS.restParameterForgotten + GLOBALS.restParameterRequest;
        return this.postWithQuery(url, undefined, getQuery);
    }

    // User
    // Create Account
    public createAccount(user: User): Observable<any> {
        let url = GLOBALS.restParameterUser + GLOBALS.restParameterCreate;
        return this.post(url, user);
    }

    // Delete Account
    public deleteAccount(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterUser + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    // Delete Account
    public editAccount(user: User): Observable<any> {
        let url = GLOBALS.restParameterUser + GLOBALS.restParameterEdit;
        return this.post(url, user);
    }

    // get all Users
    public allUsers(search: UserFilter): Observable<UserResult> {
        let url = GLOBALS.restParameterUser + GLOBALS.restParameterAll;
        return this.post(url, search);
    }
    
    // ConferenceVenue
    // Create Conference Venue
    public addConferenceVenue(newConferenceVenue: ConferenceVenue) {
		let url = GLOBALS.restParameterConferenceVenue + GLOBALS.restParameterAdd;
		return this.post(url, newConferenceVenue);
    }

    // Edit Conference Venue
    public editConferenceVenue(newConferenceVenue: ConferenceVenue) {
		let url = GLOBALS.restParameterConferenceVenue + GLOBALS.restParameterEdit;
		return this.post(url, newConferenceVenue);
    }

    // Delete Conference Venue
    public deleteConferenceVenue(getQuery: GetQuery) {
		let url = GLOBALS.restParameterConferenceVenue + GLOBALS.restParameterDelete;
		return this.deleteWithQuery(url, getQuery);
    }

    // Get all Conference Venues / filtered Conference Venue
	public allConferenceVenue(search: ConferenceVenueFilter): Observable<ConferenceVenueResult> {
		let url = GLOBALS.restParameterConferenceVenue + GLOBALS.restParameterAll;
		return this.post(url, search);	
	}

    public getConferenceVenueById(getQuery: GetQuery) {
        let url = GLOBALS.restParameterConferenceVenue + GLOBALS.restParameterGet;
        return this.postWithQuery(url, undefined, getQuery);
    }

    // Form
    // get all Data
    public allForm(search: FormFilter, getQuery: GetQuery): Observable<Form> {
        let url = GLOBALS.restParameterForm + GLOBALS.restParameterAll;
        return this.postWithQuery(url, search, getQuery);
    }

    public addArea(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterArea + GLOBALS.restParameterAdd;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public editArea(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterArea + GLOBALS.restParameterEdit;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public deleteArea(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterArea + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    public addCareer(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterCareer + GLOBALS.restParameterAdd;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public editCareer(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterCareer + GLOBALS.restParameterEdit;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public deleteCareer(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterCareer + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    public addOrganisation(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterOrganisation + GLOBALS.restParameterAdd;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public editOrganisation(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterOrganisation + GLOBALS.restParameterEdit;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public deleteOrganisation(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterOrganisation + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    public addStatus(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterStatus + GLOBALS.restParameterAdd;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public editStatus(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterStatus + GLOBALS.restParameterEdit;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public deleteStatus(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterStatus + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    public addCategory(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterCategory + GLOBALS.restParameterAdd;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public editCategory(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterCategory + GLOBALS.restParameterEdit;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public deleteCategory(getQuery: GetQuery): Observable<any> {
        let url = GLOBALS.restParameterCategory + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    // Seminar
    // add Seminar
    public addSeminar(newSeminar: Seminar) {
        let url = GLOBALS.restParameterSeminar + GLOBALS.restParameterAdd;
        return this.post(url, newSeminar);
    }

    // edit Seminar
    public editSeminar(seminar: Seminar) {
        let url = GLOBALS.restParameterSeminar + GLOBALS.restParameterEdit;
        return this.post(url, seminar);
    }

    // delete Seminar
    public deleteSeminar(getQuery: GetQuery) {
        let url = GLOBALS.restParameterSeminar + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    // get all Seminars
    public allSeminars(search: SeminarFilter): Observable<SeminarResult> {
        let url = GLOBALS.restParameterSeminar + GLOBALS.restParameterAll;
        return this.post(url, search);
    }

    // get all Seminarnames
    public allSeminarnames(): Observable<string[]> {
        let url = GLOBALS.restParameterSeminar + GLOBALS.restParameterNames + GLOBALS.restParameterAll;
        return this.post(url, undefined);
    }

    // get next Seminar meetings
    public allSeminarMeetings(search: NextMeetingFilter): Observable<NextMeeting[]> {
        let url = GLOBALS.restParameterSeminar + GLOBALS.restParameterNextMeeding;
        return this.post(url, search);
    }

    // get Seminar by id
    public getSeminarById(getQuery: GetQuery) {
        let url = GLOBALS.restParameterSeminar + GLOBALS.restParameterGet;
        return this.postWithQuery(url, undefined, getQuery);
    }

    // Meeting
    // add Meeting
    public addMeeting(newMeeting: Meeting) {
        let url = GLOBALS.restParameterMeeting + GLOBALS.restParameterAdd;
        return this.post(url, newMeeting);
    }

    // edit Meeting
    public editMeeting(seminar: Seminar) {
        let url = GLOBALS.restParameterMeeting + GLOBALS.restParameterEdit;
        return this.post(url, seminar);
    }

    // delete Meeting
    public deleteMeeting(getQuery: GetQuery) {
        let url = GLOBALS.restParameterMeeting + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    // get all confirmed Signups / Participantion for a Seminar
    public allAcceptedSingups(search: ParticipationFilter) {
        let url = GLOBALS.restParameterMeeting + GLOBALS.restParameterParticipants;
        return this.post(url, search);
    }

    // get email text for confirmation
    public getMeetingById(getQuery: GetQuery) {
        let url = GLOBALS.restParameterMeeting + GLOBALS.restParameterGet;
        return this.postWithQuery(url, undefined, getQuery);
    }

    // InvoiceInfo
    public setInvoiceInfo(getQuery: GetQuery) {
        let url = GLOBALS.restParameterMeeting + GLOBALS.restParameterInvoiceInfo + GLOBALS.restParameterSet;
        return this.postWithQuery(url, undefined, getQuery);
    }

    // keydates
    public getKeydates(search: KeyDatesFilter): Observable<KeyDatesResult> {
        let url = GLOBALS.restParameterMeeting + GLOBALS.restParameterKeydates;
        return this.post(url, search);
    }

    // statistics
    public getStatisticsInfo(search: StatisticsFilter) {
        let url = GLOBALS.restParameterMeeting + GLOBALS.restParameterStatistics + GLOBALS.restParameterInfo;
        return this.post(url, search);
    }

    public getStatisticsSummary(search: StatisticsFilter) {
        let url = GLOBALS.restParameterMeeting + GLOBALS.restParameterStatistics + GLOBALS.restParameterSummary;
        return this.post(url, search);
    }

    // Department
    // add Department
    public addDepartment(newDepartment: Department) {
        let url = GLOBALS.restParameterDepartment + GLOBALS.restParameterAdd;
        return this.post(url, newDepartment);
    }

    // edit Department
    public editDepartment(department: Department) {
        let url = GLOBALS.restParameterDepartment + GLOBALS.restParameterEdit;
        return this.post(url, department);
    }

    // delete Department
    public deleteDepartment(getQuery: GetQuery) {
        let url = GLOBALS.restParameterDepartment + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    // get all Departments
    public allDepartments(search: DepartmentFilter) {
        let url = GLOBALS.restParameterDepartment + GLOBALS.restParameterAll;
        return this.post(url, search);
    }

    // Speaker
    // add Speaker
    public addSpeaker(newSpeaker: Speaker) {
        let url = GLOBALS.restParameterSpeaker + GLOBALS.restParameterAdd;
        return this.post(url, newSpeaker);
    }

    // edit Speaker
    public editSpeaker(speaker: Speaker) {
        let url = GLOBALS.restParameterSpeaker + GLOBALS.restParameterEdit;
        return this.post(url, speaker);
    }

    // delete Speaker
    public deleteSpeaker(getQuery: GetQuery) {
        let url = GLOBALS.restParameterSpeaker + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    // get all Speakers
    public allSpeakers(search: SpeakerFilter) {
        let url = GLOBALS.restParameterSpeaker + GLOBALS.restParameterAll;
        return this.post(url, search);
    }

    // get all Speakernames
    public getAllSpeakerNames(): Observable<SpeakerNames[]> {
        let url = GLOBALS.restParameterSpeaker + GLOBALS.restParameterNames + GLOBALS.restParameterAll;
        return this.post(url, undefined);
    }

    // get meeting speakers
    public getMeetingSpeakers(getQuery: GetQuery): Observable<SpeakerResult> {
        let url = GLOBALS.restParameterSpeaker + GLOBALS.restParameterMeeting + GLOBALS.restParameterAll;
        return this.postWithQuery(url, undefined, getQuery);
    } 

    // Participant
    // add Participant
    public addParticipant(newParticipant: Participant) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterAdd;
        return this.post(url, newParticipant);
    }

    // edit Participant
    public editParticipant(participant: Participant) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterEdit;
        return this.post(url, participant)
    }

    // delete Participant
    public deleteParticipant(getQuery: GetQuery) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    // get all Participants
    public allParticipants(search: ParticipantFilter) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterAll;
        return this.post(url, search);
    }
    
    // signup Participant to Seminar
    public signUpParticipant(meeting: MeetingSingUp) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup;
        return this.post(url, meeting);
    }

    //get all Signups / Participations
    public allSignups(search: ParticipationFilter) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterAll;
        return this.post(url, search);
    }

    //get all unconfirmed Signups / Participations
    public allUnconfirmedSignups(search: ParticipationFilter) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterUnconfirmed + GLOBALS.restParameterAll;
        return this.post(url, search);
    }

    // delete SignUp / Participation
    public deleteSignups(getQuery: GetQuery) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterDelete;
        return this.deleteWithQuery(url, getQuery);
    }

    public getMeetingSignUps(getQuery: GetQuery): Observable<MeetingSingUp> {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterGet;
        return this.postWithQuery(url, undefined, getQuery);
    }

    public cancellSignUp(signUp: ParticipantConfirmation) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterCancellation;
        return this.post(url, signUp);
    }

    public confirmSignUp(signUp: FormData) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterConfirmation;
        return this.postWithFormData(url, signUp);
    }

    public confirmAndCancellSignups(signups: FormData) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterSendAll;
        return this.postWithFormData(url, signups);
    }

    public acceptSignUp(signUp: MeetingSingUp) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterAccept;
        return this.post(url, signUp);
    }

    public rejectSignUp(getQuery: GetQuery) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterReject;
        return this.deleteWithQuery(url, getQuery);
    }

    public saveSignupChanges(signups: MeetingSingUp[]) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterSignup + GLOBALS.restParameterSave;
        return this.post(url, signups);
    }

    public createCsv(search: ParticipationFilter) {
        let url = GLOBALS.restParameterParticipant + GLOBALS.restParameterCreate + GLOBALS.restParameterCsv;
        return this.postAsText(url, search);
    }

    private get(url: string): Observable<any> {
        const headers = { 'Accept': 'application/json' };
        const options = { headers: headers };

        return this.httpClient.get<any>(url, options).pipe(
            catchError((error: any) => {
                return throwError(() => error);
            })
        );
    }

    private postAsText(url: string, payload: any): Observable<any> { // for CSV data
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
        const options = { headers: headers , responseType: 'text' as any  };

        return this.httpClient.post<any>(url, JSON.stringify(payload), options).pipe(
            catchError((error: any) => {
                return throwError(() => error);
            })
        );
    }

    private postWithFormData(url: string, formData: FormData): Observable<any> {
        /*const headers = { 'Accept': 'multipart/form-data', 'Content-Type': 'multipart/form-data' };
        const options = { headers: headers };*/

        return this.httpClient.post<any>(url, formData/*, options*/).pipe(
            catchError((error: any) => {
                return throwError(() => error);
            })
        );
    }


    private getWithQuery(url: string, getQuery: GetQuery): Observable<any> {
        const headers = { 'Accept': 'application/json' };
        const options = { headers: headers };

        return this.httpClient.get<any>(url + getQuery.toQuery(), options).pipe(
            catchError((error: any) => {
                return throwError(() => error);
            })
        );
    }

    private post(url: string, payload: any): Observable<any> {
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
        const options = { headers: headers };

        return this.httpClient.post<any>(url, JSON.stringify(payload), options).pipe(
            catchError((error: any) => {
                return throwError(() => error);
            })
        );
    }

    private postWithQuery(url: string, payload: any, getQuery: GetQuery): Observable<any> {
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
        const options = { headers: headers };

        return this.httpClient.post<any>(url + getQuery.toQuery(), JSON.stringify(payload), options).pipe(
            catchError((error: any) => {
                return throwError(() => error);
            })
        );
    }

    private deleteWithQuery(url: string, getQuery: GetQuery): Observable<any> {
        const headers = { 'Accept': 'application/json' };
        const options = { headers: headers };

        return this.httpClient.delete<any>(url + getQuery.toQuery(), options).pipe(
            catchError((error: any) => {
                return throwError(() => error);
            })
        );
    }
}