<div id="pdf-container">
    <div id="pdf-attendance-list">
        <div class="fs-4 text-decoration-underline d-flex justify-content-center mb-3">{{ translatePrefix + '.HEADER' | translate }}</div>
        <div class="mb-5">
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.SEMINAR' | translate }} </div>
                <div class="col-5" *ngIf="data"> {{ data[1].name }} </div>
                <div class="col-5 fw-bold"> {{ translatePrefix + '.RLP1' | translate }} </div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.MEETINGDATE' | translate }} </div>
                <div class="col-5" *ngIf="data"> {{ data[0].startDate | date: 'dd.MM.yyyy'}}&nbsp;-&nbsp;{{ data[0].endDate | date: 'dd.MM.yyyy' }} </div>
                <div class="col-5 fw-bold"> {{ translatePrefix + '.RLP2' | translate }} </div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.VENUE' | translate }} </div>
                <div class="col-5" *ngIf="conferenceVenue">{{ conferenceVenue.name }} </div>
                <div class="col-5 fw-bold"> {{ translatePrefix + '.RLP3' | translate }} </div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.IN' | translate }} </div>
                <div class="col-5" *ngIf="conferenceVenue"> {{ conferenceVenue.location }} </div>
                <div class="col-5"></div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.SPEAKER' | translate }} </div>
                <div class="col-5"> 
                    <ng-container *ngFor="let speaker of speakerList">
                        <ng-container *ngIf="speaker.salutationId">
                            {{ speaker.salutationId | salutation }}&nbsp;
                        </ng-container>
                        {{ speaker.firstName }}&nbsp;{{ speaker.lastName }}<br>
                    </ng-container>
                </div>
                <div class="col-5"></div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.FILENUMBER' | translate }} </div>
                <div class="col-5" *ngIf="data"> {{ data[1].fileNumber }} </div>
                <div class="col-5"></div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.PAGEJFP' | translate }} </div>
                <div class="col-5" *ngIf="data"> {{ data[1].pageJfp }} </div>
                <div class="col-5 fw-bold"></div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.PARTICIPANT' | translate }} </div>
                <div class="col-5" *ngIf="data"> {{ data[0].signedParticipants }} </div>
                <div class="col-5 fw-bold">{{ translatePrefix + '.DATE' | translate }}:&nbsp;{{ today | date: 'dd.MM.yyyy' }} </div>
            </div>
        </div>
        <div>{{ translatePrefix + '.NOOVERNIGHT' | translate }}</div>
        <div class="border border-dark my-3" *ngFor="let participation of participations">
            <div class="p-2">
                <div class="row">
                    <div class="col-7 fw-bold">
                        {{ translatePrefix + '.NAME' | translate }}
                    </div>
                    <div class="col-5 fw-bold">
                        {{ translatePrefix + '.DEPARTMENT' | translate }}
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-7">
                        {{ participation.firstName }}&nbsp;{{ participation.lastName }}
                        <ng-container *ngIf="!participation.overnightStay">
                            *
                        </ng-container> 
                    </div>
                    <div class="col-5">
                        {{ participation.departmentShorthand }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 fw-bold">
                        {{ translatePrefix + '.EMAIL' | translate }}
                    </div>
                    <div class="col-5 fw-bold">
                        {{ translatePrefix + '.ADDRESS' | translate }}
                    </div>
                    <div class="col-3 fw-bold">
                        {{ translatePrefix + '.PHONE' | translate }}
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-4">
                        <div class="dotted-border py-3"></div>
                    </div>
                    <div class="col-5">
                        <div class="dotted-border py-3"></div>
                    </div>
                    <div class="col-3">
                        <div class="dotted-border py-3"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-5 fw-bold">
                        {{ translatePrefix + '.SIGNATURE' | translate }}
                    </div>
                    <div class="col-3"></div>
                </div>
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-5">
                        <div class="dotted-border py-3"></div>
                    </div>
                    <div class="col-3"></div>
                </div>
            </div>
        </div>
    </div>
</div>