import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'datediff'
})
export class DateDiffPipe implements PipeTransform {
    constructor() { }

    transform(date1?: Date, date2?: Date) {
        if(date1 && date2) {
            return Math.ceil(Math.ceil(Math.abs(new Date(date1).getTime() - new Date(date2).getTime())) / (1000 * 60 * 60 * 24) ) + 1;
        } else {
            return undefined;
        }
    }
}