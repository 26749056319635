import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Organisation } from 'src/app/models/form.model';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'edit-organisation',
  styleUrl: './edit-organisation.component.scss',
  templateUrl: './edit-organisation.component.html',
})
export class EditOrganisationComponent implements OnInit, OnDestroy {
  translatePrefix: string = 'EDIT.EDIT_ORGANISATION';
  columns: string[] = ['organisationValue'];
  buttons: string[] = ['editDelBtn'];
  organisation?: Organisation[] = [];
  addMethod: string = 'addOrganisation';
  editMethod: string = 'editOrganisation';
  deleteMethod: string = 'deleteOrganisation';
  keys: string[] = ['organisationId', 'organisationFilter'];
  formSubscription?: Subscription;

  constructor(private formService: FormService) { }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }
  
  ngOnInit() { 
    this.formSubscription = this.formService.refresh.subscribe({
      next: (res: boolean) => {
        if(!res) {
          this.organisation = this.formService.data?.organisations;
        }
      }
    });

    this.formService.updateData();
  }
}