<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>
    <div class="mb-3">
        <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)" [isClosed]="true"></app-filter-menu>
    </div>
    <div class="d-flex mb-3">
        <div class="me-2">
            <filter [type]="'quarter'" [search]="" [column]="'quarter'" (filterEvent)="setQuarter($event);"></filter>
        </div>
        <filter [type]="'year'" [search]="filterYear" [column]="'year'" (filterEvent)="setYear($event);"></filter>
    </div>
    <div #tree *ngIf="!isLoadingSummary && !isErrorSummary" class="d-flex justify-content-center mb-4" style="overflow: auto;">
        <app-tree-node *ngIf="summaryData" [name]="summaryData.name" [value]="summaryData.value" [children]="summaryData.children"></app-tree-node>
    </div>
    <div *ngIf="isErrorSummary" class="mb-4 alert alert-danger" role="alert">
        <div class="d-flex">
            <i class="fa-solid fa-xmark mt-1 me-2"></i>
            {{ 'ERRORCODE.' + errorCode | translate }}
        </div>
    </div>
    <div *ngIf="isLoadingSummary && isErrorSummary" class="d-flex justify-content-center my-3">
        <i class="fa-solid fa-circle-notch fa-spin"></i>
    </div>
    <ng-container *ngFor="let item of infoData">
        <ng-container *ngFor="let meeting of item.Meetings">
            <div class="alert alert-success mb-3" role="alert" placement="bottom" ngbTooltip="{{ getTooltipText(meeting) }}">
                {{ item.Name }},&nbsp;{{ meeting.StartDate | date:'dd.MM.yyyy'}}&nbsp;-&nbsp;{{ meeting.EndDate | date:'dd.MM.yyyy'}},&nbsp;{{ translatePrefix + '.MAX' | translate }}&nbsp;{{meeting.CountAllParticipants}},&nbsp;{{ translatePrefix + '.NORMAL' | translate }}&nbsp;{{meeting.CountParticipants}},&nbsp;{{ translatePrefix + '.ONLINE' | translate }}&nbsp;{{meeting.CountOnlineParticipants}}
            </div>
        </ng-container>
    </ng-container>
</div>