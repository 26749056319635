import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import * as GLOBALS from "../../globals";
import { RestService } from 'src/app/services/rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-account',
  styleUrl: './create-account.component.scss',
  templateUrl: './create-account.component.html'
})
export class CreateAccountComponent implements OnInit {
  translatePrefix: string = 'CREATEACCOUNT';
  user: User = new User();
  roles: string[] = [];
  salutaions: [number, string][] = GLOBALS.salutationIds;
  
  constructor(private restService: RestService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    let user: User | undefined = this.authService.authUser;

    if(user) {
      if(user.roleName == GLOBALS.adminRole) {
        this.roles = GLOBALS.userAssignableRoles; // create participant acc and admins
      } else if (user.roleName == GLOBALS.noaccountRole) {
        this.roles = [GLOBALS.userAssignableRoles[0]]; // create admin acc
      }
    }
  }

  create() {
    this.user.isInitialPassword = true;
    
    this.restService.createAccount(this.user).subscribe({
      next: res => {
        if(this.authService.authUser?.roleName == GLOBALS.noaccountRole) {
          this.router.navigate(['/' + GLOBALS.routeParameterLogin]);
        } else {
          this.router.navigate(['../']);
        }
      },
      error: err => {
        console.log(err);
        this.authService.checkAuth(err.status);
      }
    });
  }

  validate(): boolean {
    return !this.user.eMail || !this.user.userName || !this.user.roleName || !this.user.salutationId || !this.user.firstName || !this.user.lastName;
  }
}