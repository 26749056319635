import { Paging } from "./paging.model";

export class PageableFilter extends Paging {
    orderColumn?: string | null;
    orderDirection?: string | null;

    constructor(page: number, pageSize: number, orderColumn?: string | null, orderDirection?: string | null) {
        super(page, pageSize);
        this.orderColumn = orderColumn;
        this.orderDirection = orderDirection;
    }
}