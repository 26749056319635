import { Component, Input } from '@angular/core';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { Meeting } from 'src/app/models/meeting.model';
import { Participation } from 'src/app/models/participation.model';
import { Seminar } from 'src/app/models/seminar.model';

@Component({
  selector: 'app-participant-list-pdf',
  styleUrl: './participant-list-pdf.component.scss',
  templateUrl: './participant-list-pdf.component.html',
})
export class ParticipantListPdfComponent {
  @Input() translatePrefix: string = 'PDF.PARTICIPANTDOCUMENTS';
  @Input() data?: [meeting: Meeting, seminar: Seminar]; // 0 = Meeting; 1 = Seminar
  @Input() participations?: Participation[];
  @Input() conferenceVenue?: ConferenceVenue;
  today: Date = new Date();
  
  constructor() { }
}