import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as GLOBALS from "../../globals";
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-header',
  styleUrl: './header.component.scss',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isMenuOpen: boolean | undefined = false;
  @Output() openMenuEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() hideUi?: boolean;
  homePath: string = '/' + GLOBALS.routeParameterHome;
  registerPath: string = '/' + GLOBALS.routeParameterSeminarRegistration;
  changePassword: string = '/' + GLOBALS.routeParameterRequestPasswordReset;
  role: string = '';
  adminRole: string = GLOBALS.adminRole;
  currentUser?: User;
  participantRole: string = GLOBALS.participantRole;
  translatePrefix: string = 'USERCARD';
  authSubscription?: Subscription;

  constructor(private authService: AuthService) { }

  ngOnDestroy() {
    this.authSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.authSubscription = this.authService.loginEvent.subscribe({
      next: (res: boolean) => {
        if (!res) {
          this.currentUser = this.authService.authUser;
          this.role = this.authService.authUser?.roleName ? this.authService.authUser?.roleName : '';
        }
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  open() {
    this.isMenuOpen = !this.isMenuOpen;
    this.openMenuEvent.emit(this.isMenuOpen);
  }
}