import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NextMeetingFilter } from 'src/app/models/next-meeting-filter.model';
import { NextMeeting } from 'src/app/models/next-meeting.model';
import { AuthService } from 'src/app/services/auth.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../globals";

@Component({
  selector: 'app-seminar-registration',
  styleUrl: './seminar-registration.component.scss',
  templateUrl: './seminar-registration.component.html',
})
export class SeminarRegistrationComponent implements OnInit {
  @Input() translatePrefix: string = 'SEMINARREGISTARTION';
  columns: string[] = ['name', 'location', 'startDate', 'endDate', 'expectedParticipants', 'mainSpeakerName'];
  property2Columns: string[] = ['seminar', 'conferenceVenue', 'meeting', 'meeting', 'meeting', ''];
  buttons: string[] = ['infoBtn', 'registrationBtn'];
  filters: string[] = ['text', 'text', 'date', 'date', 'number', 'text'];
  columnsFilterMenu: string[] = ['name', 'pageJfp', 'yearMonth'];
  filtersFilterMenu: string[] = ['dropdown', 'text', 'date'];
  getAllMethod: string = 'allSeminarMeetings';
  search?: NextMeetingFilter = new NextMeetingFilter();
  dropdownSelect: string[] = [];
  selectedMeeting?: NextMeeting;
    
  constructor(private modalService: NgbModal, private restService: RestService, private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.restService.allSeminarnames().subscribe({
      next: (res: string[]) => {
        this.dropdownSelect = res;
      }, error: err => {
        console.log(err)
        this.authService.checkAuth(err.status);
      }
    });
  }

  openModal(modal: any, ariaLabelledBy: string, size: string, meeting: NextMeeting) {
    this.selectedMeeting = meeting;
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }

  resetFilter() {
    this.search = new NextMeetingFilter();
  }

  setSearch(event: NextMeetingFilter) {
    this.search = new NextMeetingFilter(event.name, event.pageJfp, event.yearMonth);
  }

  storeData(event: NextMeeting) {
    if(this.authService.authUser === undefined) {
      this.router.navigate(['/' + GLOBALS.routeParameterLogin, { meetingId: event.meeting?.meetingId, seminarname: event.seminar?.name }]);
    } else {
      this.router.navigate(['/' + GLOBALS.routeParameterParticipant, { meetingId: event.meeting?.meetingId, seminarname: event.seminar?.name }]); // user is auth
    }
  }
}