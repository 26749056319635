import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, finalize } from 'rxjs';
import { FormFilter } from 'src/app/models/form-filter.model';
import { GetQuery } from 'src/app/models/get-query.model';
import { FormService } from 'src/app/services/form.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'universal-edit',
  styleUrl: './universal-edit.component.scss',
  templateUrl: './universal-edit.component.html',
})
export class UniversalEditComponent implements OnInit, OnDestroy { 
  @Input() translatePrefix?: string;
  @Input() columns: string[] = [];
  @Input() tableContent?: any[] = [];
  @Input() editMethod: string = '';
  @Input() addMethod: string = '';
  @Input() deleteMethod: string = '';
  @Input() varKeys: string[] = [];
  @Input() diffServerErrorMessage?: string = '';
  search?: FormFilter;
  payload?: GetQuery;
  orderDirection?: string;
  errorCode: string = '';
  isLoading?: boolean; 
  isError?: boolean;
  refreshSubscription?: Subscription;
  formSubscription?: Subscription;

  constructor(private formService: FormService, private restService: RestService, 
    private modalService: NgbModal, private refreshService: RefreshService) { }

  ngOnDestroy() {
    this.refreshSubscription?.unsubscribe();
    this.formSubscription?.unsubscribe();

    this.formService.orderDirection = 'asc';
    this.formService.search = new FormFilter();
    this.formService.isError = false;
    this.formService.errorCode = '';

    this.formService.updateData();
  }

  ngOnInit() { 
    this.refreshSubscription = this.refreshService.refresh.subscribe({
      next: (res: boolean) => {
        if(res) this.formService.updateData();
      }
    });

    this.formSubscription = this.formService.refresh.subscribe({
      next: (res: boolean) => {
        this.isLoading = res;
        this.isError = this.formService.isError;
        this.errorCode = this.formService.errorCode;
      }
    });

    this.search = this.formService.search;
    this.orderDirection = this.formService.orderDirection;
  }

  edit(event : any[]) {
    this.isLoading = true;

    let getQuery: GetQuery = new GetQuery();
    getQuery.keyMap.set("id", this.getProperty(event[1], this.varKeys[0]));
    getQuery.keyMap.set("value", event[0]);

    (this.restService as any)[this.editMethod](getQuery).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.isError = false;
        this.errorCode = '';
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = String(error.status);
        console.log(error);
      }
    );
  }

  add(event: any) {
    this.isLoading = true;

    let getQuery: GetQuery = new GetQuery();
    getQuery.keyMap.set("value", event);

    (this.restService as any)[this.addMethod](getQuery).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.formService.updateData();
        this.isError = false;
        this.errorCode =  '';
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = String(error.status);
        console.log(error);
      }
    );
  }

  filter(event: any) {
    if(event instanceof FormFilter) {
      this.search = this.formService.search = event;
    } else {
      this.orderDirection = this.formService.orderDirection = event;
    }
    this.formService.updateData();
  }

  setPayload(event: any) {
    this.payload = new GetQuery();
    this.payload.keyMap.set("id", this.getProperty(event, this.varKeys[0]));
  }

  getProperty(obj: any, property: string) {
    if(!obj) {
      return null;
    }
    return obj[property];
  }

  openModal(modal: any, ariaLabelledBy: string, size: string) {
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size });
  }
}