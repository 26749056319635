<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ translatePrefix + '.HEADER' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form name="createMeeting" *ngIf="meeting">
            <div class="mb-4 alert alert-info" role="alert">
                {{ 'FORMS.REQUIRED' | translate }}
            </div>
            <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                <div class="d-flex">
                    <i class="fa-solid fa-xmark mt-1 me-2"></i>
                    {{ 'ERRORCODE.' + errorCode | translate }}
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="shorthand" class="mb-1">{{ translatePrefix + '.SHORTHAND' | translate }}</label>
                    <input type="text" class="form-control" id="shorthand" name="shorthand" [(ngModel)]="meeting.shorthand" [disabled]="isDisabled">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="series" class="mb-1">{{ translatePrefix + '.SERIES' | translate }}</label>
                    <input class="form-control"  id="series" name="series" [(ngModel)]="meeting.series" [disabled]="isDisabled">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="overnightStays" class="mb-1">{{ translatePrefix + '.OVERNIGHTSTAYS' | translate }}</label>
                    <input type="number" min="0" class="form-control" id="overnightStays" name="overnightStays" [(ngModel)]="meeting.overnightStays"  [ngClass]="{'required-input': !meeting.overnightStays}" [disabled]="isDisabled" required>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="expectedParticipants" class="mb-1">{{ translatePrefix + '.EXPECTEDPARTICIPANTS' | translate }}</label>
                    <input type="number" min="0" class="form-control" id="expectedParticipants" name="expectedParticipants" [(ngModel)]="meeting.expectedParticipants" [ngClass]="{'required-input': !meeting.expectedParticipants}" [disabled]="isDisabled" required>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="startDate" class="mb-1">{{ translatePrefix + '.STARTDATE' | translate }}</label>
                    <input type="datetime-local" class="form-control" id="startDate" name="startDate" [(ngModel)]="meeting.startDate" [ngClass]="{'required-input': !meeting.startDate}" [disabled]="isDisabled" required>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="endDate" class="mb-1">{{ translatePrefix + '.ENDDATE' | translate }}</label>
                    <input type="datetime-local" class="form-control"  id="endDate" name="endDate" [(ngModel)]="meeting.endDate" [ngClass]="{'required-input': !meeting.endDate}" [disabled]="isDisabled" required>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="externalReportingDate" class="mb-1">{{ translatePrefix + '.EXTERNALREPORTINGDATE' | translate }}</label>
                    <input type="date" class="form-control" id="externalReportingDate" name="externalReportingDate" [(ngModel)]="meeting.externalReportingDate" [disabled]="isDisabled">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="internalReportingDate" class="mb-1">{{ translatePrefix + '.INTERNALREPORTINGDATE' | translate }}</label>
                    <input type="date" class="form-control"  id="internalReportingDate" name="internalReportingDate" [(ngModel)]="meeting.internalReportingDate" [disabled]="isDisabled">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-3 mb-3">
                    <label for="invoiceDate" class="mb-1">{{ translatePrefix + '.INVOICEDATE' | translate }}</label>
                    <input type="date" class="form-control"  id="invoiceDate" name="invoiceDate" [(ngModel)]="meeting.invoiceDate" [ngClass]="{'required-input': !meeting.invoiceDate}" [disabled]="isDisabled" required>
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="dueDate" class="mb-1">{{ translatePrefix + '.DUEDATE' | translate }}</label>
                    <input type="date" class="form-control" id="dueDate" name="dueDate" [(ngModel)]="meeting.dueDate" [ngClass]="{'required-input': !meeting.dueDate}" [disabled]="isDisabled" required>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="documentDispatchDate" class="mb-1">{{ translatePrefix + '.DOCUMENTDISPATCHDATE' | translate }}</label>
                    <input type="date" class="form-control" id="documentDispatchDate" name="documentDispatchDate" [(ngModel)]="meeting.documentDispatchDate" [disabled]="isDisabled">
                </div>
            </div>
            <div class="form-group mb-3">
                <label for="tinyMedia" class="mb-1">{{ translatePrefix + '.MEDIA' | translate }}</label>
                <editor [init]="tinyConfig" [(ngModel)]="meeting.media" name="tinyMedia" [disabled]="isDisabled"></editor>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-4 mb-3">
                    <label for="conference" class="mb-1">{{ translatePrefix + '.CONFERENCE_VENUE' | translate }}</label>
                    <select class="form-select" id="conference" name="conference" [(ngModel)]="meeting.conferenceVenueId" [ngClass]="{'required-input': !meeting.conferenceVenueId}" [disabled]="isDisabled">
                        <ng-container *ngIf="conferenceList && conferenceList.length != 0">
                            <option [value]="''"></option>
                            <option *ngFor="let conference of conferenceList" class="form-option" [value]="conference.conferenceVenueId">{{ conference.name }}</option>
                        </ng-container>
                        <option  *ngIf="conferenceList && conferenceList.length == 0" class="form-option">{{ translatePrefix + '.NOVENUE' | translate }}</option>
                        <option *ngIf="isErrorConference" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                    </select>
                </div>
                <div class="form-group col-sm-4 mb-3">
                    <label for="user" class="mb-1">{{ translatePrefix + '.USER' | translate }}</label>
                    <select class="form-select" id="userId" name="userId" [(ngModel)]="meeting.userId" [ngClass]="{'required-input': !meeting.userId}" [disabled]="isDisabled">
                        <ng-container *ngIf="userList && userList.length != 0">
                            <option [value]="''"></option>
                            <option *ngFor="let user of userList" class="form-option" [ngValue]="user.uniqueIdentifier">{{ user.firstName + ' ' + user.lastName }}</option>
                        </ng-container>
                        <option  *ngIf="userList && userList.length == 0" class="form-option">{{ translatePrefix + '.NOUSER' | translate }}</option>
                        <option *ngIf="isErrorUser" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                    </select>
                </div>
                <div class="form-group col-sm-4 mb-3">
                    <label for="user" class="mb-1">{{ translatePrefix + '.SIGN' | translate }}</label>
                    <select class="form-select" id="userSignId" name="userSignId" [(ngModel)]="meeting.userSignId" [ngClass]="{'required-input': !meeting.userSignId}" [disabled]="isDisabled">
                        <ng-container *ngIf="userList && userList.length != 0">
                            <option [value]="''"></option>
                            <option *ngFor="let user of userList" class="form-option" [ngValue]="user.uniqueIdentifier">{{ user.firstName + ' ' + user.lastName }}</option>
                        </ng-container>
                        <option  *ngIf="userList && userList.length == 0" class="form-option">{{ translatePrefix + '.NOUSER' | translate }}</option>
                        <option *ngIf="isErrorUser" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group col-sm-12 mb-3">
                <ng-container *ngIf="meeting.speakerIds">
                    <ng-container *ngFor="let meeting of meetingSpeakers; index as index">
                        <div class="mb-3">
                            <label for="speaker_{{index}}" class="mb-1">{{ index + 1 + '. ' }}{{ translatePrefix + '.SPEAKER' | translate }}</label>
                            <select class="form-select" id="speaker_{{index}}" name="speaker_{{index}}" [ngModel]="meeting" (ngModelChange)="addSpeaker(index, $event)" [ngClass]="{'required-input': index == 0 && meeting.speakerId.length == 0}" [disabled]="isDisabled">
                                <ng-container *ngIf="!isErrorSpeakerNames">
                                    <option [ngValue]="undefined" selected>{{ translatePrefix + '.NOSPEAKER' | translate }}</option>
                                    <ng-container *ngFor="let speaker of speakerNamesList">
                                        <option *ngIf="showOption(index, speaker)" class="form-option" [ngValue]="speaker">{{ speaker.fullName }}</option></ng-container>
                                </ng-container>
                                <option *ngIf="isErrorSpeakerNames" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                            </select> 
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="d-flex w-100 justify-content-end">
            <button *ngIf="!isDisabled" class="btn btn-secondary me-1" (click)="submit()" [disabled]="validate() || isLoading">
                {{ translatePrefix + '.BUTTONS.SAVE' | translate }}
                <i *ngIf="isLoading" class="fa-solid fa-circle-notch fa-spin"></i>
            </button>
            <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-container>