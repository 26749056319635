import { Component, Input } from '@angular/core';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { Meeting } from 'src/app/models/meeting.model';
import { Participation } from 'src/app/models/participation.model';
import { Seminar } from 'src/app/models/seminar.model';

@Component({
  selector: 'app-participant-documents-pdf',
  styleUrl: './participant-documents-pdf.component.scss',
  templateUrl: './participant-documents-pdf.component.html',
})
export class ParticipantDocumentsPdfComponent {
  @Input() translatePrefix: string = 'PDF.PARTICIPANTDOCUMENTS';
  @Input() seminar?: Seminar;
  @Input() meeting?: Meeting;
  @Input() participations?: Participation[];
  @Input() conferenceVenue?: ConferenceVenue;
  today: Date = new Date();
    
  constructor() { }
}