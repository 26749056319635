import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Participant } from 'src/app/models/participant.model';
import * as GLOBALS from "../../../globals";
import { RestService } from 'src/app/services/rest.service';
import { finalize } from 'rxjs';
import { RefreshService } from 'src/app/services/refresh.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-create-participant-modal',
  styleUrl: './create-participant-modal.component.scss',
  templateUrl: './create-participant-modal.component.html',
})
export class CreateParticipantModalComponent implements OnInit {
  @Input() translatePrefix: string = '';
  @Input() modal?: NgbActiveModal;
  @Input() participant?: Participant;
  salutaions: [number, string][] = GLOBALS.salutationIds;
  isLoading: boolean = false;
  restMethod: string = '';
  isError: boolean = false;
  errorCode: string = '';
    
  constructor(private restService: RestService, private refreshService: RefreshService, private authService: AuthService, private formService: FormService) { }

  ngOnInit() {
    this.restMethod = this.participant == undefined ? 'addParticipant' : 'editParticipant';
    this.participant = this.participant == undefined ? new Participant() : new Participant(this.participant.participantEmail, this.participant.participantId, this.participant.salutationId, 
      this.participant.academicTitle, this.participant.firstName, this.participant.lastName, this.participant.hasSignUp);
  }

  validate() {
    if(!this.participant) return true;
    return !this.participant.salutationId || !this.participant.firstName || !this.participant.lastName;
  }

  submit() {
    this.isLoading = true;

    (this.restService as any)[this.restMethod](this.participant).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.isError = false;
        this.errorCode = '';
        this.refreshService.emit(true);
        this.modal?.dismiss();
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = String(error.status);
        this.authService.checkAuth(error.status);
      }
    );
  }

  setProperty(obj: any, property: string, value: any): void {
    obj[property] = value;
  }
}