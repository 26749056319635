export class User {
    uniqueIdentifier?: string = "";
    salutationId?: number;
    firstName?: string | null = null;
    lastName?: string | null = null;
    directDialing?: string = "";
    phone?: string | null = null;
    eMail?: string = "";
    userName?: string = "";
    roleName?: string = "";
    lastLogin?: Date | null = null;
    creation?: Date = new Date();
    isInitialPassword?: boolean = false;
    position?: string | null = null;
    shorthand?: string | null = null;
    street?: string | null  = null;
    zip?: string | null = null;
    location?: string | null = null;
    organisationId?: number | null = null;

    constructor(uniqueIdentifier?: string, salutationId?: number, firstName?: string | null, lastName?: string | null, directDialing?: string, phone?: string | null,
        eMail?: string, userName?: string, roleName?: string, lastLogin?: Date | null, creation?: Date, isInitialPassword?: boolean, position?: string | null, 
        shorthand?: string | null, street?: string | null, zip?: string | null, location?: string | null, organisationId?: number | null) {
        
        this.uniqueIdentifier = uniqueIdentifier;
        this.salutationId = salutationId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.directDialing = directDialing;
        this.phone = phone;
        this.eMail = eMail;
        this.userName = userName;
        this.roleName = roleName;
        this.lastLogin = lastLogin;
        this.creation = creation;
        this.isInitialPassword = isInitialPassword;
        this.position = position;
        this.shorthand = shorthand;
        this.street = street;
        this.zip = zip;
        this.location = location;
        this.organisationId = organisationId;
    }
}