<header>
  <app-header [isMenuOpen]="triggerAnimation" [hideUi]="hideUi" (openMenuEvent)="open($event)"></app-header>
</header>
<main>
  <nav *ngIf="triggerAnimation && !hideUi" class="menu" [@slideMenu]="triggerAnimation" id="nav" name="nav">
    <app-nav-bar (openMenuEvent)="open($event)"></app-nav-bar> 
  </nav>
  <div class="mt-5 container h-auto">
    <router-outlet></router-outlet>
  </div>
</main>
<div *ngIf="triggerAnimation && !hideUi" class="overlay" id="overlay" [@fade]="triggerAnimation" (click)="triggerAnimation = !triggerAnimation"></div>
<div *ngIf="isLoadingPermission" class="loading-permission-overlay position-relativ">
  <div class="center">
    <i class="fa-solid fa-circle-notch fa-spin fa-2xl"></i>
  </div>
</div>