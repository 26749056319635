import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { Speaker } from 'src/app/models/speaker.model';
import { AuthService } from 'src/app/services/auth.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../../globals";

@Component({
  selector: 'app-create-speaker-modal',
  styleUrl: './create-speaker-modal.component.scss',
  templateUrl: './create-speaker-modal.component.html'
})
export class CreateSpeakerModalComponent implements OnInit {
  @Input() translatePrefix?: string;
  @Input() modal?: NgbActiveModal;
  @Input() speaker?: Speaker;
  restMethod: string = '';
  isError: boolean = false;
  isLoading: boolean = false;
  errorCode: string = '';
  salutaions: [number, string][] = GLOBALS.salutationIds;
  
  constructor(private restService: RestService, private authService: AuthService, private refreshService: RefreshService) { }

  ngOnInit() {
    this.restMethod = this.speaker == undefined ? 'addSpeaker' : 'editSpeaker';
    this.speaker =  this.speaker == undefined ? new Speaker() : new Speaker(this.speaker.speakerId, this.speaker.academicTitle, this.speaker.salutationId, 
      this.speaker.firstName, this.speaker.lastName, this.speaker.serviceAuthority, this.speaker.workStreet, this.speaker.workZip, this.speaker.workLocation, 
      this.speaker.workPhone, this.speaker.workFax, this.speaker.workMail, this.speaker.workWebsite, this.speaker.privateStreet, this.speaker.privateZip, 
      this.speaker.privateLocation, this.speaker.privatePhone, this.speaker.privateFax, this.speaker.bankAccountHolder, this.speaker.bankBlz, this.speaker.bankIban, this.speaker.bankBic);
  }
  
  submit() {
    this.isLoading = true;

    (this.restService as any)[this.restMethod](this.speaker).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.isError = false;
        this.errorCode = '';
        this.refreshService.emit(true);
        this.modal?.dismiss();
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = String(error.status);
        this.authService.checkAuth(error.status);
      }
    );
  }

  validate() {
    if(!this.speaker) return true;
    return !this.speaker.firstName || !this.speaker.lastName || !this.speaker.serviceAuthority || !this.speaker.salutationId;
  }
}