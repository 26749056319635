import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormFilter } from 'src/app/models/form-filter.model';

@Component({
  selector: 'app-universal-edit-table',
  styleUrl: './universal-edit-table.component.scss',
  templateUrl: './universal-edit-table.component.html',
})
export class UniversalEditTableComponent { 
  @Input() translatePrefix?: string;
  @Input() columns: string[] = [];
  @Input() tableContent?: any[] = [];
  @Input() isLoading?: boolean;
  @Input() orderDirection?: string;
  @Input() isError?: boolean;
  @Input() errorCode: string = '';
  @Input() search?: FormFilter;
  @Input() varKeys: string[] = [];
  newItem: string = '';

  @Output() addEvent: EventEmitter<any> = new EventEmitter();
  @Output() editEvent: EventEmitter<any> = new EventEmitter();
  @Output() delEvent: EventEmitter<any> = new EventEmitter();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  emitAdd(event: string) {
    this.addEvent.emit(event);
    this.newItem = '';
  }

  emitEdit(event: any[]) {
    this.editEvent.emit(event);
  }

  emitDelete(event: any) {
    this.delEvent.emit(event);
  }

  getProperty(obj: any, property: string) {
    if(!obj) {
      return null;
    }
    return obj[property];
  }

  setProperty(obj: any, property: string, value: any) {
    obj[property] = value;
  }

  toggleSort(direction: string) {
    if (this.orderDirection != direction) {
      this.orderDirection = direction;
      this.emitFilter(this.orderDirection);
    }
  }

  emitFilter(event: any) {
    this.filterEvent.emit(event);
  }
}