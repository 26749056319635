<div>
  <h6 class="py-1" [ngClass]="{'has-children': children?.length !== 0}">
    <div class="d-flex flex-column align-items-center">
      <div class="fw-bold"> {{name}} </div>
      <div> {{value}} </div>
    </div>
  </h6>
  <div *ngIf="children && children?.length !== 0" class="children">
    <ng-container *ngFor="let node of children">
      <app-tree-node [name]="node.name" [value]="node.value" [children]="node.children"></app-tree-node>
    </ng-container>
  </div>
</div>