import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { ConferenceVenueFilter } from 'src/app/models/conference-venue-filter.model';
import { ConferenceVenueResult } from 'src/app/models/conference-venue-result.model';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { Meeting } from 'src/app/models/meeting.model';
import { UserFilter } from 'src/app/models/user-filter.model';
import { UserResult } from 'src/app/models/user-result.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../globals";
import { SpeakerNames } from 'src/app/models/speaker.model';

@Component({
  selector: 'app-create-meeting-modal',
  styleUrl: './create-meeting-modal.component.scss',
  templateUrl: './create-meeting-modal.component.html',
})
export class CreateMeetingModalComponent implements OnInit {
  @Input() translatePrefix: string = '';
  @Input() modal?: NgbActiveModal;
  @Input() seminar?: string;
  @Input() meeting?: Meeting | null;
  @Input() isDisabled: boolean = false;
  restMethod: string = '';
  isLoading: boolean = false;
  isError: boolean = false;
  errorCode: string = '';
  isErrorConference: boolean = false;
  isErrorUser: boolean = false;
  userList: User[] = [];
  conferenceList: ConferenceVenue[] = [];
  speakerNamesList: SpeakerNames[] = [];
  meetingSpeakers: SpeakerNames[] = [];
  isErrorSpeakerNames: boolean = false;
  tinyConfig = GLOBALS.tinyConfig;
    
  constructor(private restService: RestService, private refreshService: RefreshService, private authService: AuthService) { }

  ngOnInit() {
    this.meetingSpeakers.push(new SpeakerNames());

    this.restService.allConferenceVenue(new ConferenceVenueFilter(1, 25, null, null)).subscribe({
      next: (res: ConferenceVenueResult) => {
        this.conferenceList = res.result;
        this.isErrorConference = false;
        this.errorCode = '';
      }, error: err => {
        this.isErrorConference = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });

    let userSearch = new UserFilter(1, 25, null, null);
    userSearch.roleName = GLOBALS.adminRole;
    
    this.restService.allUsers(userSearch).subscribe({
      next: (res: UserResult) => {
        this.userList = res.result;
        this.isErrorUser = false;
        this.errorCode = '';
      }, error: err => {
        this.isErrorUser = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });

    this.restMethod = this.meeting == undefined ? 'addMeeting' : 'editMeeting';
    if(this.meeting == undefined) {
      this.meeting = new Meeting();
      this.meeting.speakerIds = [];
    } else {
      this.meeting = new Meeting(this.meeting.meetingId, this.meeting.seminarId, this.meeting.shorthand, this.meeting.series, this.meeting.startDate, this.meeting.endDate,
        this.meeting.overnightStays, this.meeting.externalReportingDate, this.meeting.internalReportingDate, this.meeting.documentDispatchDate, this.meeting.invoiceDate, 
        this.meeting.dueDate, this.meeting.signedParticipants, this.meeting.expectedParticipants, this.meeting.conferenceVenueId, this.meeting.media, this.meeting.userId, 
        this.meeting.userSignId, this.meeting.speakerIds, this.meeting.invitation, this.meeting.invoiceInfo);
    }
      
    if(this.restMethod === 'addMeeting') this.meeting!.seminarId = this.seminar;

    this.restService.getAllSpeakerNames().subscribe({
      next: (res: SpeakerNames[]) => {
        this.speakerNamesList = res.filter((item: SpeakerNames) => item.isHidden === false);
        this.isErrorSpeakerNames = false;
        this.errorCode = '';

        if(this.meeting!.speakerIds?.length != 0) {
          this.meetingSpeakers.pop();
          for(let speakerId of this.meeting!.speakerIds!) {
            for(let speaker of this.speakerNamesList) {
              if(speaker.speakerId === speakerId.toLocaleLowerCase()) {
                this.meetingSpeakers.push(speaker);
                break;
              }
            }
          }
          this.meetingSpeakers.push(new SpeakerNames());
        } 
      }, error: err => {
        this.isErrorSpeakerNames = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });
  }

  submit() {
    this.isLoading = true;

    (this.restService as any)[this.restMethod](this.meeting).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.isError = false;
        this.errorCode = '';
        this.refreshService.emit(true);
        this.modal?.dismiss();
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = String(error.status);
        this.authService.checkAuth(error.status);
      }
    );
  }

  validate() {
    if(!this.meeting) return true;

    return !this.meeting.startDate || !this.meeting.endDate || !this.meeting.overnightStays || !this.meeting.expectedParticipants || !this.meeting.userId || !this.meeting.userSignId || !this.meeting.conferenceVenueId 
      || !this.meeting.invoiceDate || !this.meeting.dueDate || this.meeting.speakerIds?.length === 0;
  }

  addSpeaker(index: number, event?: SpeakerNames) {
    if(event) {
      this.meetingSpeakers[index] = event;
      this.meeting!.speakerIds![index] = event.speakerId;

      if (this.meetingSpeakers.length == (index + 1)) this.meetingSpeakers.push(new SpeakerNames());
    } else {
      if (this.meetingSpeakers.length != (index + 1))
        this.meetingSpeakers.splice(index, 1);
      this.meeting!.speakerIds!.splice(index, 1);
    }
  }

  showOption(index: number, event: SpeakerNames): boolean {
    let found = this.meetingSpeakers.indexOf(event);

    if(found < 0 || found == index)
      return true;

    return false;
  }
}