import { Meeting } from "./meeting.model";

export class Seminar {
    seminarId?: string = '';
    areaId?: number = -1;  
    name?: string = ''; 
    categoryId?: number = -1;   
    fileNumber?: string = '';  
    fileIdentifier?: string | null = null;  
    pageJfp?: number | null = null; 
    participationFee?: number;   
    areaOfAuthorization?: string = '';
    chapter?: string = '';
    title?: string = '';  
    shortDescription?: string = ''; 
    targetAudience?: string | null = null;
    gain?: string | null = null;   
    seminarContent?: string = ''; 
    notice?: string | null = null; 
    expandsOnSid?: string | null = null;    
    year?: number = -1;
    meetings?: Meeting[] = [];

    constructor(seminarId?: string, areaId?: number, name?: string, categoryId?: number, fileNumber?: string, fileIdentifier?: string | null, pageJfp?: number | null,
        participationFee?: number, areaOfAuthorization?: string, chapter?: string, title?: string, shortDescription?: string,  targetAudience?:string | null,
        gain?: string | null, seminarContent?: string, notice?: string | null, expandsOnSid?: string | null, year?: number, meetings?: Meeting[]) {

        this.seminarId = seminarId;
        this.areaId = areaId;
        this.name = name;
        this.categoryId = categoryId;
        this.fileNumber = fileNumber;
        this.fileIdentifier = fileIdentifier;
        this.pageJfp = pageJfp;
        this.participationFee = participationFee;
        this.areaOfAuthorization = areaOfAuthorization
        this.chapter = chapter;
        this.title = title;
        this.shortDescription = shortDescription;
        this.targetAudience = targetAudience;
        this.gain = gain;
        this.seminarContent = seminarContent;
        this.notice = notice;
        this.expandsOnSid = expandsOnSid;
        this.year = year;
        this.meetings = meetings
    }
}