import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject, Subscription, debounceTime } from "rxjs";
import * as GLOBALS from "../../globals";
import { FormService } from "src/app/services/form.service";
import { Area, Category, Organisation } from "src/app/models/form.model";

@Component({
    selector: 'filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
}) export class FilterComponent implements OnInit, OnDestroy {
    @Input() type?: string;
    @Input() resetButton: boolean = false;
    @Input() search?: any;
    @Input() column: string = "";
    @Input() dropdownSelect: any[] = [];
    @Input() dropdownTranslates: string[] = [];
    @Input() emptyOption: boolean = true;
    inputControl: Subject<any> = new Subject();
    yearsMin: number = GLOBALS.yearsMin;
    yearsMax: number | null = null;
    quarterFilter: (string | string[])[][] = GLOBALS.quarterFilter;
    formSubscription?: Subscription;
    areas?: Area[] = [];
    categories?: Category[] = [];
    organisations?: Organisation[] = [];
    @Input() filterForModuleCert: boolean = false;

    @Output() filterEvent: EventEmitter<any> = new EventEmitter()

    constructor(private formService: FormService) { }

    ngOnInit() { 
      this.inputControl.pipe(
        debounceTime(1000)
      ).subscribe((event : any) => {
        if(event) {
          this.emitFilter(event);
        } else {
          this.resetFilter();
        }
      });

      if(this.formService.data) {
        if (this.filterForModuleCert) {
          this.areas = this.formService.data?.areas.filter(a => a.areaValue === 'Basistraining' || a.areaValue === 'Vertiefungstraining');
        } else {
          this.areas = this.formService.data?.areas;
        }

        this.categories = this.formService.data?.categories;
        this.organisations = this.formService.data?.organisations;
      } else {
        this.formService.updateData();
  
        this.formSubscription = this.formService.refresh.subscribe({
          next: (res: boolean) => {
            if(!res) {
              if (this.filterForModuleCert) {
                this.areas = this.formService.data?.areas.filter(a => a.areaValue === 'Basistraining' || a.areaValue === 'Vertiefungstraining');
              } else {
                this.areas = this.formService.data?.areas;
              }
              this.categories = this.formService.data?.categories;
              this.organisations = this.formService.data?.organisations;
            }
          }
        });
      }

      this.yearsMax = new Date().getFullYear() + 2;
  } 
  ngOnDestroy() {
    this.inputControl.complete();
    this.formSubscription?.unsubscribe();
  }

  emitFilter(event: any) {
    this.filterEvent.emit(event);
  }
  resetFilter() {
    this.filterEvent.emit(null);
  }
  delayedInput(event: any) {
    this.inputControl.next(event);
  }
}