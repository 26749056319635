import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  styleUrl: './pagination.component.scss',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent { 
  @Input() pages?: any[];
  @Input() currentPage?: number;
  @Input() pageSize?: number;
  @Input() totalCount?: number;
  @Input() isLoading?: boolean;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  @Output() resultsChanged: EventEmitter<number> = new EventEmitter();

  constructor() { }

  changePage(page: number) {
    this.pageChanged.emit(page);
  }

  updateResult(results: any) {
    this.resultsChanged.emit(results.target?.value ? results.target.value : 15);
  }
}