import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, finalize } from 'rxjs';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { AuthService } from 'src/app/services/auth.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../../globals";

@Component({
  selector: 'app-create-conference-venue-modal',
  styleUrl: './create-conference-venue.component.scss',
  templateUrl: './create-conference-venue.component.html'
})
export class CreateConferenceVenueModalComponent implements OnInit, OnDestroy{
  @Input() translatePrefix?: string;
  @Input() modal?: NgbActiveModal;
  @Input() conferenceVenue?: ConferenceVenue;
  isLoadingForm: boolean = false;
  isErrorForm: boolean = false;
  restMethod: string = '';
  isError: boolean = false;
  isLoading: boolean = false;
  errorCode: string = '';
  formSubscription?: Subscription;
  salutaions: [number, string][] = GLOBALS.salutationIds;
  tinyConfig = GLOBALS.tinyConfig;

  constructor(private restService: RestService, private authService: AuthService, private refreshService: RefreshService) { }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }
  
  ngOnInit() {
    this.restMethod = this.conferenceVenue == undefined ? 'addConferenceVenue' : 'editConferenceVenue';
    this.conferenceVenue = this.conferenceVenue == undefined ? new ConferenceVenue() : new ConferenceVenue(
      this.conferenceVenue.conferenceVenueId, 
      this.conferenceVenue.name, 
      this.conferenceVenue.street, 
      this.conferenceVenue.zip, 
      this.conferenceVenue.location, 
      this.conferenceVenue.phone, 
      this.conferenceVenue.fax, 
      this.conferenceVenue.mail, 
      this.conferenceVenue.contactSalutationId, 
      this.conferenceVenue.contactFirstName,
      this.conferenceVenue.contactLastName, 
      this.conferenceVenue.bankAccountHolder, 
      this.conferenceVenue.bankBlz, 
      this.conferenceVenue.bankIban, 
      this.conferenceVenue.bankBic, 
      this.conferenceVenue.media, 
      this.conferenceVenue.notice,
      this.conferenceVenue.website, 
    );
  }

  submit() {
    this.isLoading = true;
    
    (this.restService as any)[this.restMethod](this.conferenceVenue).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.isError = false;
        this.errorCode = '';
        this.refreshService.emit(true);
        this.modal?.dismiss();
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = String(error.status);
        this.authService.checkAuth(error.status);
      }
    );
  }
  
  validate() {
    if(!this.conferenceVenue) return true;
    return !this.conferenceVenue.name || !this.conferenceVenue.street || !this.conferenceVenue.zip || !this.conferenceVenue.location;
  }
}