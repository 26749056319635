<div>
    <div class="row justify-content-center mx-auto">
        <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 d-flex justify-content-center">
            <div class="card w-100">
                <div class="card-header">
                    <h5 class="card-title">{{ translatePrefix + '.HEADER' | translate }}</h5>
                </div>
                <div class="card-body">
                    <form name="loginForm">
                        <div class="form-group mb-3">
                            <label for="salutaion" class="mb-1">{{ translatePrefix + '.SALUTATION' | translate }}</label>
                            <select class="form-select" id="salutaion" name="salutaion" [(ngModel)]="user.salutationId">
                                <ng-container *ngFor="let salutaion of salutaions">
                                    <option class="form-option" [value]="salutaion[0]">{{ translatePrefix + '.SALUTATIONS.' + salutaion[1] | translate }}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="form-group mb-3">
                            <label for="firstName" class="mb-1">{{ translatePrefix + '.FIRSTNAME' | translate }}</label>
                            <input type="text" class="form-control" id="firstName" name="firstName" [(ngModel)]="user.firstName" required>
                        </div>
                        <div class="form-group mb-3">
                            <label for="lastName" class="mb-1">{{ translatePrefix + '.LASTNAME' | translate }}</label>
                            <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="user.lastName" required>
                        </div>
                        <div class="form-group mb-3">
                            <label for="email" class="mb-1">{{ translatePrefix + '.EMAIL' | translate }}</label>
                            <input type="email" class="form-control" id="email" name="email" [(ngModel)]="user.eMail" required>
                        </div>
                        <div class="form-group mb-3">
                            <label for="username" class="mb-1">{{ translatePrefix + '.USERNAME' | translate }}</label>
                            <input type="text" class="form-control" id="username" name="username" [(ngModel)]="user.userName" required>
                        </div>
                        <div class="form-group">
                            <label for="role" class="mb-1">{{ translatePrefix + '.ROLE.TEXT' | translate }}</label>
                            <select class="form-select" id="role" name="role" [(ngModel)]="user.roleName">
                                <ng-container *ngFor="let userrole of roles">
                                    <option class="form-option" [value]="userrole">{{ translatePrefix + '.ROLE.OPTIONS.' + userrole | uppercase | translate }}</option>
                                </ng-container>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-end my-1">
                        <button type="submit" class="btn btn-primary" style="width: 100px;" (click)="create()" [disabled]="validate()">{{ translatePrefix + '.BUTTON' | translate }}</button>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>