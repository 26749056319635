import { Component } from '@angular/core';
import { GetQuery } from 'src/app/models/get-query.model';
import { AuthService } from 'src/app/services/auth.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-set-initial-password',
  styleUrl: './set-initial-password.component.scss',
  templateUrl: './set-initial-password.component.html'
})
export class SetInitialPasswordComponent {
  translatePrefix: string = 'SETINITIALPASSWORD';
  isSuccess: boolean = false;
  isError: boolean = false;
  errorCode: string = '';
  repetitionPassword: string = '';
  newPassword: string = '';

  constructor(private restService: RestService, private authService: AuthService) { }

  set() {
    let getQuery: GetQuery = new GetQuery();
    getQuery.keyMap.set("newPassword", this.newPassword);

    this.restService.postInitialPassword(getQuery).subscribe({
      next: res => {
        this.isSuccess = true;
        this.isError = false; 
        this.errorCode = '';
        
        this.authService.logout();
      }, error: err => {
        console.log(err);
        this.isError = true; 
        this.errorCode = err.error != "Password requirements not met" ? String(err.status) : "WRONGPWREQ";
        this.authService.checkAuth(err.status);
      }
    });
  }

  validate(): boolean {
    return !this.newPassword || !this.repetitionPassword || (this.newPassword != this.repetitionPassword);
  }

  show(id: string) {
    let element = document.getElementById('password' + id);
    let icon = document.getElementById('lockIcon' + id);

    if (element && icon) {
      let type = element.getAttribute('type');

      element.setAttribute('type', type == 'password' ? 'text' : 'password');
      icon.setAttribute('class', type == 'password' ? 'fa-solid fa-lock-open' : 'fa-solid fa-lock');
    }
  }
}