import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PasswordForgotten } from 'src/app/models/password-forgotten.model';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../globals";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-set-password',
  styleUrl: './set-password.component.scss',
  templateUrl: './set-password.component.html'
})
export class SetPasswordComponent implements OnInit, OnDestroy {
  translatePrefix: string = 'SETPASSWORD';
  resetData: PasswordForgotten = new PasswordForgotten();
  loginPath: string = '/' + GLOBALS.routeParameterLogin;
  repetitionPassword: string = '';
  isSuccess: boolean = false;
  isError: boolean = false;
  errorCode: string = '';
  routeSubscription?: Subscription;

  constructor(private restService: RestService, private route: ActivatedRoute) { }
  
  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe( params => {
      if(params['requestId']) {
        this.resetData.requestId = params['requestId'];
      }
    });
  }

  ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
  }

  set() {
    this.restService.postPassword(this.resetData).subscribe({
      next: res => { 
        this.isSuccess = true;
        this.isError = false; 
        this.errorCode = '';
      },
      error: err => {
        console.log(err);
        this.isError = true; 
        this.errorCode = err.error != "Password requirements not met" ? String(err.status) : "WRONGPWREQ";
      }
    });
  }

  show(id: string) {
    let element = document.getElementById('password' + id);
    let icon = document.getElementById('lockIcon' + id);

    if (element && icon) {
      let type = element.getAttribute('type');

      element.setAttribute('type', type == 'password' ? 'text' : 'password');
      icon.setAttribute('class', type == 'password' ? 'fa-solid fa-lock-open' : 'fa-solid fa-lock');
    }
  }

  validate() {
    return !this.resetData.eMail || !this.repetitionPassword || !this.resetData.newPassword || !this.resetData.newPassword || !this.resetData.requestId
      || !this.repetitionPassword || (this.resetData.newPassword != this.repetitionPassword);
  }
}