<ng-template #createSeminar let-modal>
    <app-create-seminar-modal [translatePrefix]=" translatePrefix + '.MODAL.SEMINAR'" [modal]="modal" [seminar]="selectedSeminar"></app-create-seminar-modal>
</ng-template>

<ng-template #deleteItem let-modal>
    <app-generic-modal [translatePrefix]="deleteMethod === 'deleteSeminar' ? 'DELETE.SEMINAR' : 'DELETE.MEETING'" [modal]="modal" [payload]="payload" [method]="deleteMethod" [diffServerErrorMessage]="deleteMethod === 'deleteSeminar' ? 'SEMINARINUSE' : 'MEETINGINUSE'"></app-generic-modal>
</ng-template>

<ng-template #createMeeting let-modal>
    <app-create-meeting-modal [translatePrefix]=" translatePrefix + '.MODAL.MEETING'" [modal]="modal" [seminar]="selectedSeminar?.seminarId" [meeting]="selectedMeeting"></app-create-meeting-modal>
</ng-template>

<app-participant-list-pdf [data]="selectedItemToExport" [participations]="participationList" [conferenceVenue]="conferenceVenue"></app-participant-list-pdf>
<app-subject-overview-pdf [seminar]="selectedItemToExport ? selectedItemToExport[1] : undefined"></app-subject-overview-pdf>
<app-participant-certification-pdf [participations]="participationList" [seminar]="selectedItemToExport ? selectedItemToExport[1] : undefined" [meeting]="selectedItemToExport ? selectedItemToExport[0] : undefined"></app-participant-certification-pdf>
<app-attendence-list-pdf [speakerList]="speakerList" [data]="selectedItemToExport" [participations]="participationList" [conferenceVenue]="conferenceVenue"></app-attendence-list-pdf>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>
    <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)"></app-filter-menu>
    <button type="submit" class="btn btn-primary my-3" style="width: 100px;" (click)="openModal(createSeminar, 'create-seminar-modal', 'xl')" placement="bottom" ngbTooltip="{{ translatePrefix + '.ADD' | translate }}">
        <i class="fa-solid fa-chalkboard"></i>&nbsp;
        <i class="fa-solid fa-plus"></i>
    </button>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" [subTableButtons]="subTableButtons"
        [getAllMethod]="getAllMethod" [search]="search" (deleteEvent)="setSeminarPayload($event); openModal(deleteItem, 'delete-seminar-modal', '')" 
        (editEvent)="openModal(createSeminar, 'create-seminar-modal', 'xl', $event)" (addEvent)="openModal(createMeeting, 'create-meeting-modal', 'xl', $event)"
        (subTableEditEvent)="openModal(createMeeting, 'create-meeting-modal', 'xl', undefined, $event)" (subTableDelEvent)="setMeetingPayload($event); openModal(deleteItem, 'delete-meeting-modal', '')"
        (subTableAddEvent)=" openDetails($event)" (exportEvent)="print($event)" [maxTextLength]="30"></app-universal-table>
</div>