import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-page',
  styleUrl: './error-page.component.scss',
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent {
  @Input() translatePrefix: string = '';
    
  constructor() { }
}