import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, finalize } from 'rxjs';
import { GetQuery } from 'src/app/models/get-query.model';
import { Participation } from 'src/app/models/participation.model';
import { AuthService } from 'src/app/services/auth.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../globals";
import { Department } from 'src/app/models/department.model';
import { Career, Status } from 'src/app/models/form.model';
import { DepartmentResult } from 'src/app/models/department-result.model';
import { DepartmentFilter } from 'src/app/models/department-filter.model';
import { FormService } from 'src/app/services/form.service';
import { MeetingSingUp } from 'src/app/models/meeting-sign-up.model';

@Component({
  selector: 'app-edit-application',
  styleUrl: './edit-application.component.scss',
  templateUrl: './edit-application.component.html',
})
export class EditApplicationModalComponent implements OnInit, OnDestroy {
  @Input() translatePrefix: string = '';
  @Input() modal?: NgbActiveModal;
  @Input() participation?: { item1: Participation | null, item2: Participation };
  @Input() isAccepted: boolean = false;
  @Input() hasAdminFields: boolean = false;
  isLoading: boolean = false;
  isError: boolean = false;
  errorCode: string = '';
  salutaions: [number, string][] = GLOBALS.salutationIds;
  departments?: Department[] = [];
  careers?: Career[] = [];
  statuses?: Status[] = [];
  isLoadingDepartments: boolean = false;
  isLoadingForm: boolean = false;
  formSubscription?: Subscription;
  isErrorForm: boolean = false;
  isErrorDepartments: boolean = false;
  participationList: any = [];
  invoiceStatus: [number, string][] = GLOBALS.invoiceStatus;
    
  constructor(private authService: AuthService, private restService: RestService, private refreshService: RefreshService, private formService: FormService) { }
  
  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  ngOnInit() {
    if(this.formService.data) {
      this.careers = this.formService.data.careers;
      this.statuses = this.formService.data.statuses;
    } else {
      this.formService.updateData();
      this.isLoadingForm = true;

      this.formSubscription = this.formService.refresh.subscribe({
        next: (res: boolean) => {
          this.isLoadingForm = res;
          if(!this.isLoadingForm) {
            this.careers = this.formService.data?.careers;
            this.statuses = this.formService.data?.statuses;
            this.isErrorForm = this.formService.isError;
          }
        }
      });
    }

    this.isLoadingDepartments = true;
    this.restService.allDepartments(new DepartmentFilter(1, 25, null, null)).pipe(
      finalize(() => {
        this.isLoadingDepartments = false;
      })
    ).subscribe({
      next: res => {
        this.departments = (res as DepartmentResult).result;
        this.isErrorDepartments = false;
      }, error: err => {
        console.log(err);
        this.isErrorDepartments = true;
        this.authService.checkAuth(err);
      }
    });

    this.participationList.push(this.participation!['item1'] === null ? null : this.copyProperty(this.participation!['item1']));
    this.participationList.push(this.copyProperty(this.participation!['item2']));
  }

  submit() {
    this.isLoading = true;

    let signUp = new MeetingSingUp(this.participationList[1].meetingId, this.participationList[1].participantId,this.participationList[1].info, 
      this.participationList[1].date, this.participationList[1].participating, this.participationList[1].careerId, this.participationList[1].statusId, 
      this.participationList[1].position, this.participationList[1].partTime,this.participationList[1].overnightStay, this.participationList[1].departmentId, 
      this.participationList[1].reportingOfficeUserId, this.participationList[1].confirmationDate, this.participationList[1].cancellationDate, this.participationList[1].invoiceStatus, 
      this.participationList[1].invoiceIdentifier, null, true);
    
    this.restService.signUpParticipant(signUp).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe({
      next: res => {
        this.errorCode = '';
        this.refreshService.emit(true);
        this.modal?.dismiss();
        this.isError = false;
      }, error: err => {
        this.isError = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });
  }

  reject() {
    this.isLoading = true;
    let getQuery = new GetQuery();

    getQuery.keyMap.set('meetingGuid', this.participationList[1].meetingId??'');
    getQuery.keyMap.set('participantId', this.participationList[1].participantId??'');

    this.restService.rejectSignUp(getQuery).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe({
      next: res => {
        this.errorCode = '';
        this.refreshService.emit(true);
        this.formService.updateData();
        this.modal?.dismiss();
        this.isError = false;
      }, error: err => {
        this.isError = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });
  }

  accept() {
    this.isLoading = true;

    let signUp = new MeetingSingUp(this.participationList[1].meetingId, this.participationList[1].participantId,this.participationList[1].info, 
      this.participationList[1].date, this.participationList[1].participating, this.participationList[1].careerId, this.participationList[1].statusId, 
      this.participationList[1].position, this.participationList[1].partTime,this.participationList[1].overnightStay, this.participationList[1].departmentId, 
      this.participationList[1].reportingOfficeUserId, this.participationList[1].confirmationDate, this.participationList[1].cancellationDate, this.participationList[1].invoiceStatus, 
      this.participationList[1].invoiceIdentifier, null, true);

    this.restService.acceptSignUp(signUp).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe({
      next: res => {
        this.errorCode = '';
        this.refreshService.emit(true);
        this.formService.updateData();
        this.modal?.dismiss();
        this.isError = false;
      }, error: err => {
        this.isError = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });
  }

  setProperty(obj: any, property: string, value: any): void {
    obj[property] = value;
  }

  copyProperty(obj: Participation): Participation {
    return new Participation(obj.meetingId, obj.participantId, obj.participantEmail, obj.seminarName, obj.salutationId, obj.academicTitle,obj.firstName, obj.lastName, obj.info, obj.date, obj.startDate, 
      obj.endDate, obj.participating, obj.participatingStatus, obj.departmentId, obj.street, obj.zip, obj.location, obj.careerId, obj.statusId, obj.organisationId, obj.partTime, obj.overnightStay, obj.position, 
      obj.editUserFirstName, obj.editUserLastName, obj.editUserSalutationId, obj.editUserEmail, obj.editUserDirectDialing, obj.confirmationDate, obj.cancellationDate, obj.invoiceStatus, obj.invoiceIdentifier,
      obj.reportingOfficeUserId, obj.reason, obj.reportingOffice, obj.signiture, obj.participationFee, obj.departmentName, obj.departmentShorthand, obj.departmentStreet, obj.departmentZip, obj.departmentLocation, 
      obj.invoiceDate, obj.dueDate, obj.areaOfAuthorization);
  }

  validate() {
    return !this.participationList[1].position || !this.participationList[1].departmentId || !this.participationList[1].careerId || !this.participationList[1].statusId;
  }
}