import { PageableFilter } from "./pageable-filter.model";

export class ParticipantFilter extends PageableFilter {
    meetingId?: string | null = null;
    firstName?: string | null = null;
    lastName?: string | null = null;
    eMail?: string | null = null;

    constructor(page: number, pageSize: number, orderColumn?: string | null, orderDirection?: string | null, firstName?: string | null, lastName?: string | null, eMail?: string | null, meetingId?: string | null) {
        super(page, pageSize, orderColumn, orderDirection);
        this.firstName = firstName;
        this.lastName = lastName;
        this.eMail = eMail;
        this.meetingId = meetingId;
    }
}