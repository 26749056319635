import { PageableFilter } from "./pageable-filter.model";

export class SpeakerFilter extends PageableFilter {
    academicTitle?: string | null = null;
    firstName?: string | null = null;
    lastName?: string | null = null;
    workLocation?: string | null = null;
    serviceAuthority?: string | null = null;
    year?: number | null = null;

    constructor(page: number, pageSize: number, orderColumn?: string | null, orderDirection?: string | null, academicTitle?: string | null, firstName?: string | null, 
        lastName?: string | null, workLocation?: string | null, serviceAuthority?: string | null, year?: number | null) {
        super(page, pageSize, orderColumn, orderDirection);
        this.academicTitle = academicTitle;
        this.firstName = firstName;
        this.lastName = lastName;
        this.workLocation = workLocation;
        this.serviceAuthority = serviceAuthority;
        this.year = year;
    }
}