import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meeting } from 'src/app/models/meeting.model';
import { ParticipantFilter } from 'src/app/models/participant-filter.model';
import { Participation } from 'src/app/models/participation.model';
import { SeminarFilter } from 'src/app/models/seminar-filter.model';
import { Seminar } from 'src/app/models/seminar.model';
import { PdfService } from 'src/app/services/pdf.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../globals";
import { ParticipationResult } from 'src/app/models/participation-result.model';
import { ParticipationCertification } from 'src/app/models/participation-certification.model';
import { Subscription } from 'rxjs';
import { Area } from 'src/app/models/form.model';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-module-certification',
  styleUrl: './module-certification.component.scss',
  templateUrl: './module-certification.component.html',
})
export class ModuleCertificationComponent implements OnInit, OnDestroy {
  translatePrefix: string = 'FORMS.MODULECERTIFICATION';
  buttons: string[] = [];
  filters: string[] = [];
  columnsFilterMenu: string[] = ['year', 'areaId'];
  filtersFilterMenu: string[] = ['year', 'area'];
  getAllMethod: string = 'allSeminars';
  search?: SeminarFilter;
  columns: string[] = ['fileNumber', 'fileIdentifier', 'name', 'pageJfp'];
  subTableButtons: string[] = []
  fieldTypes: string[] = ['text', 'text', 'text', 'text'];
  list: Seminar[] = [];
  exportList: any[] = [];
  participationCert: Map<string, Map<string, ParticipationCertification[]>> = new Map<string, Map<string, ParticipationCertification[]>>();
  isBase: boolean = true;
  formSubscription?: Subscription;
  areas?: Area[] = [];

  constructor(private restService: RestService, private pdfService: PdfService, private formService: FormService) { }
  
  ngOnDestroy(): void {
   this.formSubscription?.unsubscribe();
  }

  ngOnInit() {
    if(this.formService.data) {
    this.areas = this.formService.data?.areas.filter((a) => a.areaValue === 'Basistraining' || a.areaValue === 'Vertiefungstraining');

    } else {
      this.formService.updateData();

      this.formSubscription = this.formService.refresh.subscribe({
        next: (res: boolean) => {
          if(!res) {
            this.areas = this.formService.data?.areas.filter((a) => a.areaValue === 'Basistraining' || a.areaValue === 'Vertiefungstraining');
          }
        }
      });
    }

    this.search = new SeminarFilter(1, 25);
    this.search.areaId = this.areas?.filter(a => a.areaValue === 'Basistraining')[0].areaId;
    this.search.year = new Date().getFullYear();
  }

  setSearch(event: SeminarFilter) {
    this.search = new SeminarFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.fileNumber, event.fileIdentifier, event.name, event.pageJfp, event.year, event.areaId);
  }

  resetFilter() {
    this.search = new SeminarFilter(1, 25);
    this.search.areaId = this.areas?.filter(a => a.areaValue === 'Basistraining')[0].areaId;
    this.search.year = new Date().getFullYear();
  }

  setList(event: Seminar[]) {
    this.list = event;
  }

  async export() { 
    let area = this.areas?.filter(a => a.areaId === this.search?.areaId);

    if(area && area?.length != 0) {
      this.isBase = area[0].areaValue === 'Basistraining' ? true : false;
    } else {
      return;
    }
    this.participationCert = new Map<string, Map<string, ParticipationCertification[]>>();


    this.exportList = this.getExportData();
    if(this.exportList.length === 0) {
      return;
    }

    let result: Participation[] = await this.exportItem() as Participation[];

    result = result.filter((item: Participation) => item.participating === true);

    //Data patching
    for(let c = 0; c < result.length; c++) {
      //Construct Participation Certification object
      let sm: [Seminar, Meeting] = this.getSeminarMeeting(result[c].meetingId!)!;
      let pc: ParticipationCertification = new ParticipationCertification(result[c], sm[0], sm[1]);

      if(!this.participationCert.has(result[c].participantId ?? "")) {
        let cert = new Map<string, ParticipationCertification[]>();
        cert.set(result[c].departmentId ?? "", new Array<ParticipationCertification>(pc));

        this.participationCert.set(result[c].participantId ?? "", cert);
      } else {
        let participant = this.participationCert.get(result[c].participantId ?? "")

        if(participant?.has(result[c].departmentId ?? "")) {
          participant!.set(result[c].departmentId ?? "", participant?.get(result[c].departmentId ?? "")!.concat(pc))
        } else {
          participant!.set(result[c].departmentId ?? "", new Array<ParticipationCertification>(pc));
        }
      } 
    }

    this.pdfService.print('Teilnehmerbescheiniungen', 'pdf-certification', undefined, false, true, false, false, false);
  }

  private async exportItem() {
    if(this.exportList.length === 0) {
      return [];
    }

    let participationsList: any[] = [];

    participationsList = participationsList.concat(await this.postAllAcceptedSingups(this.exportList[0][1].meetingId));
    this.exportList.splice(0, 1);
    participationsList = participationsList.concat(await this.exportItem());

    return participationsList;
  }

  private postAllAcceptedSingups(meetingId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let search = new ParticipantFilter(1, 25, null, null);
      search.meetingId = meetingId;

      this.restService.allAcceptedSingups(search).subscribe({
        next: (res: ParticipationResult) => {
          resolve(res.result);
        }, error: err => {
          reject(err);
        }
      });
    });
  }

  private getExportData(): any[] {
    let meetingIdList: any[] = [];

    for(let i = 0; i < this.list.length; i++) {
      if(this.list[i].meetings) {
        for(let y = 0; y < this.list[i].meetings!.length; y++) {
          if(this.list[i].meetings![y].selected === true && this.list[i].meetings![y].signedParticipants! > 0) {
            console.log(this.list[i].meetings![y].signedParticipants)
            meetingIdList.push([this.list[i], this.list[i].meetings![y]]); 
          }
        }
      } else {
        break;
      }
    }
    return meetingIdList;
  }

  private getSeminarMeeting(meetingId: string): [Seminar, Meeting] | undefined {
    for(let s = 0; s < this.list.length; s++) {
      for(let m = 0; m < this.list[s].meetings!.length; m++) {
        if(this.list[s].meetings![m].meetingId == meetingId)
          return [this.list[s], this.list[s].meetings![m]];
      }
    }

    return undefined;
  }
}