import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-menu',
  styleUrl: './filter-menu.component.scss',
  templateUrl: './filter-menu.component.html',
})
export class FilterMenuComponent {
  @Input() translatePrefix?: string;
  @Input() search: any;
  @Input() columns: string[] = [];
  @Input() filters: string[] = [];
  @Input() dropdownSelect: any[] = [];
  @Input() isClosed: boolean = false;
  @Input() dropdownTranslates: string[] = [];
  @Input() emptyOption: boolean = true;
  @Input() filterForModuleCert: boolean = false;
  
  @Output() resetEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  emitReset() {
    this.resetEvent.emit(true);
  }

  emitFilter() {
    this.filterEvent.emit(this.search);
  }

  getProperty(obj: any, property: string) {
    if(!obj) {
      return null;
    }
    return obj[property];
  }

  setProperty(obj: any, property: string, value: any) {
    obj[property] = value;
  }
}