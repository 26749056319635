<ng-template #deleteParticipant let-modal>
    <app-generic-modal [translatePrefix]="'DELETE.PARTICIPANT'" [modal]="modal" [payload]="payload" [method]="deleteMethod"></app-generic-modal>
</ng-template>

<ng-template #acceptParticipation let-modal>
    <app-participation-accept-modal [translatePrefix]="translate" [modal]="modal" [participation]="selectedParticipation" [restMethod]="restMethod" [conferenceVenue]="conferenceVenue" 
        [meeting]="meeting" [sendAllParticipations]="list" [isSendAll]="sendAllParticipations"></app-participation-accept-modal>
</ng-template>

<ng-template #editParticipation let-modal>
    <app-edit-application [translatePrefix]="translatePrefix + '.MODAL.EDIT'" [modal]="modal" [participation]="selectedParticipation" [isAccepted]="true" [hasAdminFields]="true"></app-edit-application>
</ng-template>

<ng-template #invoiceInfo let-modal>
    <app-invoice-info-modal [translatePrefix]="translatePrefix + '.MODAL.INVOICE'" [modal]="modal" [meeting]="meeting" (invoiceSet)="setMeetingInvoice($event)"></app-invoice-info-modal>
</ng-template>

<app-participant-certification-pdf [participations]="selectedParticipationList" [seminar]="seminar" [meeting]="meeting"></app-participant-certification-pdf>
<app-participant-documents-pdf [participations]="list" [seminar]="seminar" [meeting]="meeting" [conferenceVenue]="conferenceVenue"></app-participant-documents-pdf>
<app-invitation-pdf [participation]="selectedParticipation" [seminar]="seminar" [meeting]="meeting" [conferenceVenue]="conferenceVenue"></app-invitation-pdf>
<app-conference-venue-notice-pdf [conferenceVenue]="conferenceVenue" ></app-conference-venue-notice-pdf>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }} &nbsp;-&nbsp; {{ seminar?.name }}</h4>
        </div>
    </header>
    <div class="mb-3 pb-2 border-bottom">
        <ng-container *ngIf="!isLoadingMeeting && !isLoadingSeminar && !isLoadingConferenceVenue">
            <div class="row gx-2" *ngIf="meeting">
                <div class="col-md-3 col-sm-6 col-6 mb-3"><b> {{ translatePrefix + '.STARTDATE' | translate }}: </b></div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"> {{ meeting.startDate | date:'dd.MM.yyyy, hh:mm' | replaceEmpty }} </div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"><b>{{ translatePrefix + '.ENDDATE' | translate }}: </b></div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"> {{ meeting.endDate | date:'dd.MM.yyyy, hh:mm' | replaceEmpty }} </div>
            </div>
            <div class="row gx-2" *ngIf="seminar">
                <div class="col-md-3 col-sm-6 col-6 mb-3"><b> {{ translatePrefix + '.SHORTDESCRIPTION' | translate }}: </b></div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"> {{ seminar.shortDescription | replaceEmpty }} </div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"><b> {{ translatePrefix + '.AREA' | translate }}: </b></div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"> {{ seminar.areaOfAuthorization | replaceEmpty }} </div>
            </div>
            <div class="row gx-2" *ngIf="seminar">
                <div class="col-md-3 col-sm-6 col-6 mb-3"><b> {{ translatePrefix + '.CHAPTER' | translate }}: </b></div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"> {{ seminar.chapter | replaceEmpty }} </div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"><b> {{ translatePrefix + '.TITLE' | translate }}: </b></div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"> {{ seminar.title | replaceEmpty }} </div>
            </div>
            <div class="row gx-2" *ngIf="meeting">
                <div class="col-md-3 col-sm-6 col-6 mb-3"><b> {{ translatePrefix + '.INVOICEDATE' | translate }}: </b></div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"> {{ meeting.dueDate | date:'dd.MM.yyyy' | replaceEmpty }} </div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"><b> {{ translatePrefix + '.DUEDATE' | translate }}: </b></div>
                <div class="col-md-3 col-sm-6 col-6 mb-3"> {{ meeting.invoiceDate | date:'dd.MM.yyyy' | replaceEmpty }} </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoadingMeeting || isLoadingSeminar || isLoadingConferenceVenue">
            <div class="text-center w-100">
                <i class="fa-solid fa-circle-notch fa-spin"></i>
            </div>
        </ng-container>
    </div>
    <div *ngIf="meeting && meeting.invoiceInfo" class="alert alert-success mb-3" role="alert">
        {{ meeting.invoiceInfo }}
    </div>
    <div class="d-flex mb-3">
        <button type="submit" class="btn btn-primary me-2" style="width: 100px;" (click)="saveList()" [disabled]="isLoadingChanges || list.length === 0" placement="bottom" ngbTooltip="{{ translatePrefix + '.SAVE' | translate }}">
            <i class="fa-regular fa-floppy-disk"></i>
            <i *ngIf="isLoadingChanges" class="fa-solid fa-circle-notch fa-spin ms-1"></i>
        </button>
        <button type="submit" class="btn btn-primary me-2" style="width: 100px;" (click)="navigateToParticipants()" [disabled]="isLoadingMeeting || isLoadingSeminar || isLoadingConferenceVenue" placement="bottom" ngbTooltip="{{ translatePrefix + '.ADDPARTICIPANT' | translate }}">
            <i class="fa-solid fa-user-graduate"></i>
            <i class="fa-solid fa-plus"></i>
        </button>
        <button class="btn btn-primary menu-button me-2" style="width: 100px;" placement="bottom" ngbTooltip="{{ translatePrefix + '.SEMINAR' | translate }}" [routerLink]="seminarPath"> 
            <i class="fa-solid fa-chalkboard"></i>
        </button>
        <button class="btn btn-primary menu-button me-2" style="width: 100px;" placement="bottom" ngbTooltip="{{ translatePrefix + '.CSV' | translate }}" [disabled]="isLoadingCsv" (click)="downloadCsv()"> 
            <i class="fa-solid fa-file-csv"></i>
        </button>
        <button class="btn btn-primary menu-button me-2" style="width: 100px;" placement="bottom" ngbTooltip="{{ translatePrefix + '.INFO' | translate }}" [disabled]="isLoadingCsv" (click)="openModal(invoiceInfo, 'invoice-info-modal', 'xl')"> 
            <i class="fa-solid fa-info"></i>
            <i class="fa-solid fa-plus"></i>
        </button>
        <button class="btn btn-primary menu-button" style="width: 100px;" placement="bottom" ngbTooltip="{{ translatePrefix + '.SENDALL' | translate }}" [disabled]="list.length === 0" (click)="sendAll(acceptParticipation, 'send-all-modal', 'xl')"> 
            <i class="fa-solid fa-check"></i> &
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div *ngIf="changesSaved && !isErrorChanges" class="mb-4 alert alert-success" role="alert">
        {{ translatePrefix + '.SAVED' | translate }}
    </div>
    <div *ngIf="isErrorChanges" class="mb-4 alert alert-danger" role="alert">
        <div class="d-flex">
            <i class="fa-solid fa-xmark mt-1 me-2"></i>
            {{ 'ERRORCODE.' + errorCode | translate }}
        </div>
    </div>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" [getAllMethod]="getAllMethod" 
        [search]="search" (deleteEvent)="setPayload($event); openModal(deleteParticipant, 'delete-participant-modal', '')" [fieldTypes]="fieldTypes" (tableContent)="setList($event)" 
        (mailAcceptEvent)="setAcceptInputs(); openModal(acceptParticipation, 'accept-participation-modal', 'xl', $event)" (mailRejectEvent)="setRejectInputs(); openModal(acceptParticipation, 'reject-participation-modal', 'xl', $event)"
        (editEvent)="openModal(editParticipation, 'edit-participation-modal', 'xl', { item1: null, item2: $event })" [maxTextLength]="30" (exportEvent)="print($event)" [registeredParticipantPage]="true"></app-universal-table>
</div>