import { Component } from '@angular/core';

@Component({
  selector: 'app-forbidden-access',
  styleUrl: './forbidden-access.component.scss',
  templateUrl: './forbidden-access.component.html',
})
export class ForbiddenAccessComponent {
  translatePrefix: string = 'ERRORPAGE.FORBIDDEN';
  constructor() { }
}