export class Participant {
    participantEmail?: string = '';
    participantId?: string = '';
    salutationId?: number = 255;
    academicTitle?: string | null = null;
    firstName?: string = '';
    lastName?: string = '';
    hasSignUp?: number | null = 0; 

    constructor(participantEmail?: string, participantId?: string, salutationId?: number, academicTitle?: string | null, firstName?: string, lastName?: string, hasSignUp?: number | null) {
        this.participantEmail = participantEmail;
        this.participantId = participantId;
        this.salutationId = salutationId;
        this.academicTitle = academicTitle;
        this.firstName = firstName;
        this.lastName = lastName;
        this.hasSignUp = hasSignUp ? hasSignUp : 0;
    }
}