<div ngbAccordion>
    <div ngbAccordionItem [collapsed]="isClosed">
        <h2 ngbAccordionHeader>
            <button ngbAccordionButton>{{ translatePrefix + '.SEARCH' | translate }}</button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div class="row">
                        <ng-container *ngFor="let type of filters; index as index">
                            <div class="col-md-3 mb-3" [ngClass]="{'col-md-4': filters.length === 3, 'col-md-6': filters.length === 2 }">
                                <div class="mb-1">{{ translatePrefix + '.' + columns[index] | uppercase | translate }}</div>
                                <filter [type]="filters[index]" [search]="getProperty(search, columns[index])" [column]="columns[index]" [dropdownSelect]="dropdownSelect" 
                                    (filterEvent)="setProperty(search, columns[index], $event);  emitFilter();" [dropdownTranslates]="dropdownTranslates" [emptyOption]="emptyOption"
                                    [filterForModuleCert]="filterForModuleCert"></filter>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </div>
            <div class="d-flex justify-content-end border-top" ngbAccordionBody>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary" (click)="emitReset()">{{ translatePrefix + '.BUTTONS.RESET' | translate }}</button>
                </div> 
            </div>
        </div>
    </div>
</div>