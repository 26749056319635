import { Pipe, PipeTransform } from "@angular/core";

import * as GLOBALS from "../globals";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'salutation'
})
export class SalutationPipe implements PipeTransform {
    salutations: [number, string][] = GLOBALS.salutationIds;

    constructor(private translate: TranslateService) { }

    transform(id: any) {
        for(let salutation of this.salutations) {
            if(id === 255 || id === undefined) {
                return '';
            }
            if(salutation[0] === id) {
                return this.translate.instant(salutation[1]);
            }
        }
        return '';
    }
}