<div id="pdf-container">
    <div id="pdf-list">
        <div class="mb-5" style="font-size: x-large;"> 
            <b>{{ translatePrefix + '.HEADER' | translate }}&nbsp;{{ year ? year : '' }}</b> 
        </div>
        <ng-container *ngFor="let item of list">
            <div class="mb-4">
                <ng-container *ngFor="let names of propertyNames; index as index">
                    <ng-container *ngIf="names.length === 1">
                        <ng-container *ngIf="property2.length !== 0">
                            <div [ngClass]="{'fw-bold': index === 0}">
                                <ng-container *ngIf="translates.length !== 0 && translates[index] !== ''">
                                    {{ translatePrefix + '.' + translates[index] | uppercase | translate }}&nbsp;
                                </ng-container>
                                {{ types[index] === 'date' ? (getProperty(getProperty(item, property2[index]), propertyNames[index][0]) | date:'dd.MM.yyyy') :
                                (types[index] === 'datetime' ? (getProperty(getProperty(item, property2[index]), propertyNames[index][0]) | date:'dd.MM.yyyy, HH:mm') : 
                                (getProperty(getProperty(item, property2[index]), propertyNames[index][0]))) }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="property2.length === 0">
                            <div [ngClass]="{'fw-bold': index === 0}">
                                <ng-container *ngIf="translates.length !== 0 && translates[index] !== ''">
                                    {{ translatePrefix + '.' + translates[index] | uppercase | translate }}&nbsp;
                                </ng-container>
                                {{ types[index] === 'date' ? (getProperty(item, propertyNames[index][0]) | date:'dd.MM.yyyy') :
                                (types[index] === 'datetime' ? (getProperty(item, propertyNames[index][0]) | date:'dd.MM.yyyy, HH:mm') : 
                                (getProperty(item, propertyNames[index][0]))) }}
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="names.length > 1">
                        <ng-container *ngFor="let name of names; index as y">
                            <ng-container *ngIf="translates.length !== 0 && translates[index] !== '' && y === 0">
                                {{ translatePrefix + '.' + translates[index] | uppercase | translate }}&nbsp;
                            </ng-container>
                            <ng-container *ngIf="property2.length !== 0">
                                {{ types[index] === 'date' ? (getProperty(getProperty(item, property2[index]), propertyNames[index][y]) | date:'dd.MM.yyyy') :
                                (types[index] === 'datetime' ? (getProperty(getProperty(item, property2[index]), propertyNames[index][y]) | date:'dd.MM.yyyy, HH:mm') : 
                                (getProperty(getProperty(item, property2[index]), propertyNames[index][y]))) }}
                            </ng-container>
                            <ng-container *ngIf="property2.length === 0">
                                {{ types[index] === 'date' ? (getProperty(item, propertyNames[index][y]) | date:'dd.MM.yyyy') :
                                (types[index] === 'datetime' ? (getProperty(item, propertyNames[index][y]) | date:'dd.MM.yyyy, HH:mm') : 
                                (getProperty(item, propertyNames[index][y]))) }}
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="subHeading.length !== 0 && subHeading[index] && subHeading[index] !== ''">
                        <div class="my-2 fw-bold">{{ translatePrefix + '.' + subHeading[index] | translate }}</div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>