import { Component, EventEmitter } from '@angular/core';
import { PageableFilter } from '../models/pageable-filter.model';

@Component({
  template: ''
})
export class SimpleFilterTableComponent<T> {
  list: T[] = [];
  pages: number[] = [];
  isLoading: boolean = false;
  paging: PageableFilter = new PageableFilter(1, 10); 
  filterChanges: EventEmitter<any> = new EventEmitter<any>();

  forceChangePage(page: number) {
    this.isLoading = true;
    this.list = [];
    this.paging.page = page;
    this.filterChanges.emit();
  }

  getProperty(obj: any, property: string) {
    if(!obj) {
      return null;
    }
    return obj[property];
  }

  setProperty(obj: any, property: string, value: any): void {
    obj[property] = value;
  }

  emitFilterChange(): void {
    this.forceChangePage(1);
  }

  changeResultsAmount(results: number) {
    this.paging.pageSize = results;
    this.forceChangePage(1);
  }

  changePage(page: number) {
    if (page === 0 || page === this.pages.length + 1) {
      return;
    }
    
    this.forceChangePage(page);
  }

  toggleSort(column: string, direction: string): void {
    if (this.paging.orderColumn !== column || this.paging.orderDirection != direction) {
      this.paging.orderColumn = column;
      this.paging.orderDirection = direction;
      this.forceChangePage(1);
    }
  }
}