export class ConferenceVenue {
    conferenceVenueId?: string = '';
    name?: string | null = null;
    street?: string | null = null;
    zip?: string | null = null;
    location?: string | null = null;
    phone?: string;
    fax?: string;
    mail?: string;
    contactSalutationId?: number;
    contactFirstName?: string;
    contactLastName?: string;
    bankAccountHolder?: string;
    bankBlz?: string;
    bankIban?: string;
    bankBic?: string;
    media?: string;
    notice?: string;
    website?: string;

    constructor(conferenceVenueId?: string, name?: string | null, street?: string | null, zip?: string | null, location?: string | null, phone?: string, 
        fax?: string, mail?: string, contactSalutationId?: number, contactFirstName?: string, contactLastName?: string, bankAccountHolder?: string, 
        bankBlz?: string, bankIban?: string, bankBic?: string, media?: string, notice?: string, website?: string) {

        this.conferenceVenueId = conferenceVenueId;
        this.name = name;
        this.street = street;
        this.zip = zip;
        this.location = location;
        this.phone = phone;
        this.fax = fax;
        this.mail = mail;
        this.contactSalutationId = contactSalutationId;
        this.contactFirstName = contactFirstName;
        this.contactLastName = contactLastName;
        this.bankAccountHolder = bankAccountHolder;
        this.bankBlz = bankBlz;
        this.bankIban = bankIban;
        this.bankBic = bankBic;
        this.media = media;
        this.notice = notice;
        this.website = website;
    }
}