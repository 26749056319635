<div id="pdf-container">
    <div id="pdf-participant-list">
        <div class="fs-4 text-decoration-underline d-flex justify-content-center mb-3">{{ translatePrefix + '.PARTICIPANTLIST' | translate }}</div>
        <div class="mb-4">
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.SEMINAR' | translate }} </div>
                <div class="col-5" *ngIf="data"> {{ data[1].name }} </div>
                <div class="col-5 fw-bold"> {{ translatePrefix + '.RLP1' | translate }} </div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.MEETINGDATE' | translate }} </div>
                <div class="col-5" *ngIf="data"> {{ data[0].startDate | date: 'dd.MM.yyyy'}}&nbsp;-&nbsp;{{ data[0].endDate | date: 'dd.MM.yyyy' }} </div>
                <div class="col-5"></div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.VENUE' | translate }} </div>
                <div class="col-5" *ngIf="conferenceVenue">{{ conferenceVenue.name }} </div>
                <div class="col-5 fw-bold"> {{ translatePrefix + '.RLP2' | translate }} </div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.IN' | translate }} </div>
                <div class="col-5" *ngIf="conferenceVenue"> {{ conferenceVenue.location }} </div>
                <div class="col-5 fw-bold"> {{ translatePrefix + '.RLP3' | translate }} </div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.FILENUMBER' | translate }} </div>
                <div class="col-5" *ngIf="data"> {{ data[1].fileNumber }} </div>
                <div class="col-5"></div>
            </div>
            <div class="row">
                <div class="col-2 fw-bold"> {{ translatePrefix + '.PAGEJFP' | translate }} </div>
                <div class="col-5" *ngIf="data"> {{ data[1].pageJfp }} </div>
                <div class="col-5 fw-bold"> {{ translatePrefix + '.DATE' | translate }}:&nbsp;{{ today | date: 'dd.MM.yyyy' }} </div>
            </div>
        </div>
        <div>
            <div class="row mb-2 fw-bold">
                <div class="col-5"> {{ translatePrefix + '.NAME' | translate }} </div>
                <div class="col-7"> {{ translatePrefix + '.DEPARTMENT' | translate }} </div>
            </div>
            <div *ngFor="let participation of participations" class="row border-bottom border-dark">
                <div class="col-5"> 
                    {{ participation.firstName }}&nbsp;{{ participation.lastName }} 
                    <ng-container *ngIf="!participation.overnightStay">
                        *
                    </ng-container> 
                </div>
                <div class="col-7">{{  participation?.departmentName }}</div>
            </div>
            <div>{{ translatePrefix + '.NOOVERNIGHT' | translate }}</div>
        </div>
    </div>
</div>
