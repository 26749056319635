<ng-template #deleteParticipant let-modal>
    <app-generic-modal [translatePrefix]="deleteMethod === 'deleteParticipant' ? 'DELETE.PARTICIPANT' : 'ADD.PARTICIPANT'" [modal]="modal" [payload]="payload" [method]="deleteMethod"></app-generic-modal>
</ng-template>

<ng-template #createParticipant let-modal>
    <app-create-participant-modal [translatePrefix]=" translatePrefix + '.MODAL.ADD'" [modal]="modal" [participant]="selectedParticipant"></app-create-participant-modal>
</ng-template>

<ng-template #registerParticipant let-modal>
    <app-register-participant-modal [translatePrefix]=" translatePrefix + '.MODAL.REGISTER'" [modal]="modal" [participant]="selectedParticipant" [meetingId]="meetingId"></app-register-participant-modal>
</ng-template>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>
    <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)"></app-filter-menu>
    <button type="submit" class="btn btn-primary my-3" style="width: 100px;" (click)="openModal(createParticipant, 'create-participant-modal', 'xl')" placement="bottom" ngbTooltip="{{ translatePrefix + '.ADD' | translate }}">
        <i class="fa-solid fa-user-graduate"></i>&nbsp;
        <i class="fa-solid fa-plus fa-2xs"></i>
    </button>
    <div *ngIf="seminarSelected === ''" class="alert alert-secondary" role="alert">
        {{ translatePrefix + '.NOSEMINAR' | translate }}&nbsp;<a [routerLink]="registerPath">{{ translatePrefix + '.SEMINARSEARCH' | translate}}</a>
    </div>
    <div *ngIf="seminarSelected !== ''" class="alert alert-secondary" role="alert">
        <div class="row">
            <div class="col-xl-10 col-md-12 col-sm-12 col-12">{{ translatePrefix + '.SEMINAR' | translate }}&nbsp;{{ seminarSelected }}</div>
            <div class="col text-start text-xl-end text-md-start text-sm-start align-items-center">
                <i class="fa-solid fa-chalkboard text-primary me-1"></i>
                <a [routerLink]="registerPath">{{ translatePrefix + '.SEMINARSEARCH' | translate}}</a>
            </div>
        </div>
    </div>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" 
        [getAllMethod]="getAllMethod" [search]="search" (deleteEvent)="setPayload($event); openModal(deleteParticipant, 'delete-participant-modal', '')"
        (editEvent)="openModal(createParticipant, 'edit-participant-modal', 'xl', $event)" (addEvent)="openModal(registerParticipant, 'register-participant-modal', 'xl', $event)"></app-universal-table>
</div>