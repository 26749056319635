import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GetQuery } from 'src/app/models/get-query.model';
import { ParticipationFilter } from 'src/app/models/participation-filter.model';
import { Participation } from 'src/app/models/participation.model';
import { FormService } from 'src/app/services/form.service';
import * as GLOBALS from "../../globals";

@Component({
  selector: 'app-participant-application',
  styleUrl: './participant-application.component.scss',
  templateUrl: './participant-application.component.html',
})
export class ParticipantApplicationComponent implements OnInit, OnDestroy {
  @Input() translatePrefix: string = 'FORMS.PARTICIPANT';
  columns: string[] = ['firstName', 'lastName', 'seminarName','startDate', 'endDate', 'date', 'info'];
  buttons: string[] = ['infoBtn'/*, 'exportBtn'*/];
  buttonsAccepted: string[] = ['editBtn', 'delBtn'];
  fieldTypes: string[] = ['text', 'text', 'text', 'date', 'date', 'date', 'text'];
  filters: string[] = ['text', 'text', 'text', '', '', '', 'text'];
  columnsFilterMenu: string[] = ['firstName', 'lastName', 'seminarName', 'info'];
  filtersFilterMenu: string[] = ['text', 'text', 'text', 'text'];
  getAllMethod: string = 'allSignups'; 
  getAllUnconfirmedMethod: string = 'allUnconfirmedSignups'; 
  deleteMethod: string = 'deleteSignups'; 
  property2Columns: string[] = ['item2', 'item2', 'item2', 'item2', 'item2', 'item2', 'item2'];
  payload?: GetQuery;
  search?: ParticipationFilter = new ParticipationFilter (1, 50);
  searchAccepted?: ParticipationFilter = new ParticipationFilter (1, 15, 'lastName', 'asc');
  selectedParticipation?: { item1: Participation | null, item2: Participation};
  formSubscription?: Subscription;
  totalRemaining?: number;
  isAccepted: boolean = false;
  seminarSearchPath: string = '/' + GLOBALS.routeParameterSeminarRegistration;

  constructor(private modalService: NgbModal, private formService: FormService) { }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.formSubscription = this.formService.refresh.subscribe({
      next: (res: boolean) => {
        if(!res) {
          this.totalRemaining = this.formService.data?.unconfirmedSignUps;
        }
      }
    });
    
    this.formService.updateData();
  }

  openModal(modal: any, ariaLabelledBy: string, size: string, participation?: any, isAccepted?: boolean) {
    if(isAccepted) {
      this.selectedParticipation = { item1: null, item2: participation };
      this.isAccepted = isAccepted;
    } else {
      this.selectedParticipation = participation;
      this.isAccepted = false;
    }
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }

  resetFilter() {
    this.search = new ParticipationFilter(1, 50);
  }

  setSearch(event: ParticipationFilter) {
    this.search = new ParticipationFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.firstName, event.lastName, event.seminarName);
  }

  resetAcceptedFilter() {
    this.searchAccepted = new ParticipationFilter(1, 15, 'lastName', 'asc');
  }

  setSearchAccepted(event: ParticipationFilter) {
    this.searchAccepted = new ParticipationFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.firstName, event.lastName, event.seminarName);
  }

  setPayload(event: Participation) {
    this.payload = new GetQuery();
    this.payload.keyMap.set('meetingGuid', event.meetingId ?? '');
    this.payload.keyMap.set('participantId', event.participantId ?? '');
  }
}