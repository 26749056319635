import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetQuery } from 'src/app/models/get-query.model';
import { Meeting } from 'src/app/models/meeting.model';
import { SeminarFilter } from 'src/app/models/seminar-filter.model';
import { Seminar } from 'src/app/models/seminar.model';
import * as GLOBALS from "../../globals";
import { PdfService } from 'src/app/services/pdf.service';
import { RestService } from 'src/app/services/rest.service';
import { Participation } from 'src/app/models/participation.model';
import { ConferenceVenueResult } from 'src/app/models/conference-venue-result.model';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { ParticipantFilter } from 'src/app/models/participant-filter.model';
import { Speaker } from 'src/app/models/speaker.model';
import { SpeakerResult } from 'src/app/models/speaker-result.model';

@Component({
  selector: 'app-seminar',
  styleUrl: './seminar.component.scss',
  templateUrl: './seminar.component.html'
})
export class SeminarComponent {
  translatePrefix: string = 'FORMS.SEMINAR';
  columns: string[] = ['fileNumber', 'fileIdentifier', 'name', 'pageJfp'];
  buttons: string[] = ['addMeetingBtn','editBtn', 'delBtn'];
  subTableButtons: string[] = ['infoBtn', 'exportBtn', 'editSubTableBtn', 'deleteSubTableBtn'];
  filters: string[] = ['text', 'text', 'text', 'number'];
  columnsFilterMenu: string[] = ['fileNumber', 'fileIdentifier', 'name', 'year'];
  filtersFilterMenu: string[] = ['text', 'text', 'text', 'year'];
  getAllMethod: string = 'allSeminars';
  deleteMethod: string = '';
  payload?: GetQuery;
  selectedSeminar?: Seminar;
  selectedMeeting?: Meeting;
  selectedItemToExport?: [Meeting, Seminar];
  search?: SeminarFilter = new SeminarFilter(1, 25, 'fileNumber', 'asc');
  participationList?: Participation[];
  speakerList: Speaker[] = [];
  conferenceVenue?: ConferenceVenue;
  
  constructor(private modalService: NgbModal, private router: Router, private pdfService: PdfService, private restService: RestService) { }

  openModal(modal: any, ariaLabelledBy: string, size: string, seminar?: Seminar, meeting?: Meeting) {
    this.selectedSeminar = seminar;
    this.selectedMeeting = meeting;
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }

  resetFilter() {
    this.search = new SeminarFilter(1, 25, 'fileNumber', null);
  }

  setSearch(event: SeminarFilter) {
    this.search = new SeminarFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.fileNumber, event.fileIdentifier, event.name, event.pageJfp, event.year);
  }

  setSeminarPayload(event: Seminar) {
    this.deleteMethod = 'deleteSeminar';
    this.payload = new GetQuery();
    this.payload.keyMap.set('uniqueIdentifier', event.seminarId??'');
  }

  setMeetingPayload(event: Meeting) {
    this.deleteMethod = 'deleteMeeting';
    this.payload = new GetQuery();
    this.payload.keyMap.set('uniqueIdentifier', event.meetingId??'');
  }

  openDetails(data: any[]) {
    this.router.navigate(['/' + GLOBALS.routeParameterRegisteredParticipant, { conferenceVenueId: data[0].conferenceVenueId, meetingid: data[0].meetingId, seminarId: data[1].seminarId }]);
  }

  print(item: [Meeting, Seminar]) {
    this.selectedItemToExport = item;

    this.pdfService.print('Themenübersicht', 'pdf-subject-overview', undefined, false, true);

    let search = new ParticipantFilter(1, 25, null, null);
    search.meetingId = item[0].meetingId;
    
    this.restService.allAcceptedSingups(search).subscribe({
      next: res => {
        this.participationList = res.result;
        if(this.participationList?.length !== 0) this.pdfService.print('Teilnehmerbescheiniungen', 'pdf-certification', undefined, false, true);    

        let payload = new GetQuery();
        payload.keyMap.set('conferenceVenueId', item[0].conferenceVenueId ?? '');

        if (this.participationList?.length !== 0) {
          this.restService.getConferenceVenueById(payload).subscribe({
            next: res => {
              this.conferenceVenue = (res as ConferenceVenueResult).result[0];
              this.pdfService.print('Teilnehmerliste', 'pdf-participant-list', undefined, false);

              payload = new GetQuery();
              payload.keyMap.set('meetingId', item[0].meetingId ?? '');
          
              this.restService.getMeetingSpeakers(payload).subscribe({
                next: res => {
                  this.speakerList = (res as SpeakerResult).result;
                  this.pdfService.print('Anwesenheitsliste', 'pdf-attendance-list', undefined, false);
                }      
              });
            }
          });
        }
      }
    });
  }
}