<div id="pdf-container">
    <div id="pdf-subject-overview" *ngIf="seminar">
        <div class="row">
            <div class="fs-4 text-decoration-underline d-flex justify-content-center"> {{ translatePrefix + '.HEADER' | translate }} </div>
        </div>
        <div class="text-center mt-1">
            <b>&quot;{{ seminar.name }}&quot;</b>
        </div>
        <div class="row">
            <div class="mt-5" [innerHTML]="seminar.seminarContent"></div>
        </div>
    </div>
</div>