import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user.model';
import * as GLOBALS from "../../../globals";
import { RestService } from 'src/app/services/rest.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription, finalize } from 'rxjs';
import { FormService } from 'src/app/services/form.service';
import { Organisation } from 'src/app/models/form.model';

@Component({
  selector: 'app-create-reporting-office-modal',
  styleUrl: './create-reporting-office-modal.component.scss',
  templateUrl: './create-reporting-office-modal.component.html',
})
export class CreateReportingOfficeModalComponent implements OnInit, OnDestroy {
  @Input() translatePrefix: string = '';
  @Input() user?: User;
  @Input() modal?: NgbActiveModal;
  salutaions: [number, string][] = GLOBALS.salutationIds;
  isLoading: boolean = false;
  isError: boolean = false;
  restMethod: string = '';
  errorCode: string = '';
  organisationsList?: Organisation[]
  isLoadingForm: boolean = false;
  formSubscription?: Subscription;
  
  constructor(private formService: FormService, private restService: RestService, private refreshService: RefreshService, private authService: AuthService) { }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.restMethod = this.user == undefined ? 'createAccount' : 'editAccount';
    this.user = this.user == undefined ? new User() : new User(this.user.uniqueIdentifier, this.user.salutationId, this.user.firstName, this.user.lastName, 
      this.user.directDialing, this.user.phone, this.user.eMail, this.user.userName, this.user.roleName, this.user.lastLogin, this.user.creation, this.user.isInitialPassword, 
      this.user.position, this.user.shorthand, this.user.street, this.user.zip, this.user.location, this.user.organisationId);
      
    this.user.roleName = GLOBALS.participantRole;

    if(this.formService.data) {
      this.organisationsList = this.formService.data.organisations;
    } else {
      this.formService.updateData();
      this.isLoadingForm = true;

      this.formSubscription = this.formService.refresh.subscribe({
        next: (res: boolean) => {
          this.isLoadingForm = res;
          if(!this.isLoadingForm) {
            this.organisationsList = this.formService.data?.organisations;
          }
        }
      });
    }
  }

  submit() {
    this.isLoading = true;
    this.user!.lastName = this.user?.firstName;

    (this.restService as any)[this.restMethod](this.user).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.isError = false;
        this.errorCode = '';
        this.refreshService.emit(true);
        this.modal?.dismiss();
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = String(error.status) === '409' ? String(error.error).toLocaleUpperCase() : String(error.status);
        this.authService.checkAuth(error.status);
      }
    );
  }

  validate() {
    if(!this.user) return true;
    return !this.user.userName || !this.user.eMail || !this.user.firstName || !this.user.salutationId || !this.user.organisationId; 
  }
}