import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { Department } from 'src/app/models/department.model';
import { AuthService } from 'src/app/services/auth.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-create-department-venue-modal',
  styleUrl: './create-department.component.scss',
  templateUrl: './create-department.component.html'
})
export class CreateDepartmentModalComponent {
  @Input() translatePrefix?: string;
  @Input() modal?: NgbActiveModal;
  @Input() department?: Department;
  restMethod: string = '';
  isError: boolean = false;
  isLoading: boolean = false;
  errorCode: string = '';

  constructor(private restService: RestService, private refreshService: RefreshService, private authService: AuthService) { }
  
  ngOnInit() { 
    this.restMethod = this.department == undefined ? 'addDepartment' : 'editDepartment';
    this.department = this.department == undefined ? new Department() : new Department(this.department.departmentId, this.department.shorthand, 
      this.department.name, this.department.street, this.department.zip, this.department.location);
  }

  submit() {
    this.isLoading = true;

    (this.restService as any)[this.restMethod](this.department).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.isError = false;
        this.errorCode = '';
        this.refreshService.emit(true);
        this.modal?.dismiss();
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = String(error.status);
        this.authService.checkAuth(error.status);
      }
    );
  }

  validate() { 
    if(!this.department) return true;
    return !this.department.name || !this.department.shorthand || !this.department.zip || !this.department.street || !this.department.location;
  }
}