import { Component, Input } from '@angular/core';
import { Seminar } from 'src/app/models/seminar.model';

@Component({
  selector: 'app-subject-overview-pdf',
  styleUrl: './subject-overview-pdf.component.scss',
  templateUrl: './subject-overview-pdf.component.html',
})
export class SubjectOverviewPdfComponent {
  @Input() translatePrefix: string = 'PDF.SUBJECTOVERVIEW';
  @Input() seminar?: Seminar;
    
  constructor() { }
}