import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyDatesFilter } from 'src/app/models/keydates-filter.model';
import { NextMeeting } from 'src/app/models/next-meeting.model';
import * as GLOBALS from "../../globals";
import { Router } from '@angular/router';
import { PdfService } from 'src/app/services/pdf.service';
import { RestService } from 'src/app/services/rest.service';
import { KeyDatesResult } from 'src/app/models/keydates-result.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-keydates',
  styleUrl: './keydates.component.scss',
  templateUrl: './keydates.component.html',
})
export class KeydatesComponent implements OnInit {
  @Input() translatePrefix: string = 'FORMS.KEYDATES';
  columns: string[] = ['name', 'startDate', 'endDate', 'documentDispatchDate', 'internalReportingDate'];
  property2Columns: string[] = ['seminar', 'meeting', 'meeting', 'meeting', 'meeting'];
  buttons: string[] = ['eyeInfoBtn'];
  filters: string[] = ['text', 'date', 'date', 'date', 'date'];
  columnsFilterMenu: string[] = ['dispatchDateFrom', 'dispatchDateTo', 'internalDateFrom', 'internalDateTo', 'year'];
  filtersFilterMenu: string[] = ['date', 'date', 'date', 'date', 'year'];
  getAllMethod: string = 'getKeydates';
  search?: KeyDatesFilter;
  selectedMeeting?: NextMeeting;
  isError: boolean = false;
  errorCode: string = '';

  // print
  keydatesList: NextMeeting[] = [];
  printProperty2: string[] = ['seminar', 'meeting', 'meeting', 'meeting', 'meeting', 'meeting'];
  printPropertyNames: string[][] = [['name'], ['startDate'], ['endDate'], ['invoiceDate'], ['documentDispatchDate'], ['internalReportingDate']];
  isLoadingPrint: boolean = false;
  printTypes: string[] = ['text', 'datetime', 'datetime', 'date', 'date', 'date'];
  translates: string[] = ['', 'startDate', 'endDate', 'invoiceDate', 'documentDispatchDate','internalReportingDate'];
    
  constructor(private modalService: NgbModal, private router: Router, private pdfService: PdfService, private restService: RestService, private authService: AuthService) { }

  ngOnInit() {
    this.search = new KeyDatesFilter(1, 25, null, null, new Date().toISOString().split('T')[0], null, null, null, new Date().getFullYear());
  }

  resetFilter() {
    this.search = new KeyDatesFilter(1, 25);
    this.search.year = new Date().getFullYear();
  }

  setSearch(event: KeyDatesFilter) {
    this.search = new KeyDatesFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.dispatchDateFrom, event.dispatchDateTo, event.internalDateFrom, event.dispatchDateTo, event.year);
  }

  openModal(modal: any, ariaLabelledBy: string, size: string, meeting?: NextMeeting) {
    this.selectedMeeting = meeting;
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }

  print() {
    this.isLoadingPrint = true;

    this.restService.getKeydates(this.search!).subscribe({
      next: res => {
        this.keydatesList = (res as KeyDatesResult).result;
        this.errorCode = '';
        this.isError = false;
        this.pdfService.print('Stichtage_' + this.search?.year, 'pdf-list', undefined, true, true);
        this.isLoadingPrint = false;
      }, error: err => {
        console.log(err);
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
        this.isError = true;
      }
    });
  }
}