<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ translatePrefix + '.HEADER' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form name="createConferenceVenueForm" *ngIf="conferenceVenue">
            <div class="mb-4 alert alert-info" role="alert">
                {{ 'FORMS.REQUIRED' | translate }}
            </div>
            <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                <div class="d-flex">
                    <i class="fa-solid fa-xmark mt-1 me-2"></i>
                    {{ 'ERRORCODE.' + errorCode | translate }}
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="name" class="mb-1">{{ translatePrefix + '.ESTABLISHMENT' | translate }}</label>
                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="conferenceVenue.name" [ngClass]="{'required-input': !conferenceVenue.name}" required>
                </div>
                <div class="form-group col-sm-6">
                    <label for="website" class="mb-1">{{ translatePrefix + '.LINK' | translate }}</label>
                    <input type="text" class="form-control" id="website" name="website" [(ngModel)]="conferenceVenue.website">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="street" class="mb-1">{{ translatePrefix + '.STREET' | translate }}</label>
                    <input type="text" class="form-control" id="street" name="street" [(ngModel)]="conferenceVenue.street" [ngClass]="{'required-input': !conferenceVenue.street}" required>
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="postalCode" class="mb-1">{{ translatePrefix + '.POSTALCODE' | translate }}</label>
                    <input type="text" class="form-control me-2" id="postalCode" name="postalCode" [(ngModel)]="conferenceVenue.zip" [ngClass]="{'required-input': !conferenceVenue.zip}" required>
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="place" class="mb-1">{{ translatePrefix + '.PLACE' | translate }}</label>
                    <input type="text" class="form-control" id="place" name="place" [(ngModel)]="conferenceVenue.location" [ngClass]="{'required-input': !conferenceVenue.location}" required>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="email" class="mb-1">{{ translatePrefix + '.EMAIL' | translate }}</label>
                    <input type="email" class="form-control" id="email" name="email" [(ngModel)]="conferenceVenue.mail">
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="phone" class="mb-1">{{ translatePrefix + '.PHONE' | translate }}</label>
                    <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="conferenceVenue.phone">
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="fax" class="mb-1">{{ translatePrefix + '.FAX' | translate }}</label>
                    <input type="text" class="form-control" id="fax" name="fax" [(ngModel)]="conferenceVenue.fax">
                </div>
            </div>
            <div class="form-group mb-3">
                <label for="tinyMedia" class="mb-1">{{ translatePrefix + '.MEDIA' | translate }}</label>
                <editor [init]="tinyConfig" [(ngModel)]="conferenceVenue.media" name="tinyMedia"></editor>
            </div>
            <div class="form-group mb-3">
                <label for="Tinycomment" class="mb-1">{{ translatePrefix + '.COMMENT' | translate }}</label>
                <editor [init]="tinyConfig" [(ngModel)]="conferenceVenue.notice" name="Tinycomment"></editor>
            </div>
            <h5 class="border-bottom pb-3 my-4">{{ translatePrefix + '.SUBHEADER2' | translate }}</h5>
            <div class="row gx-2">
                <div class="form-group col-sm-2 mb-3">
                    <label for="salutaion" class="mb-1">{{ translatePrefix + '.SALUTATION' | translate }}</label>
                    <select class="form-select" id="salutaion" name="salutaion" [(ngModel)]="conferenceVenue.contactSalutationId">
                        <ng-container *ngFor="let salutaion of salutaions">
                            <option class="form-option" [value]="salutaion[0]">{{ translatePrefix + '.SALUTATIONS.' + salutaion[1] | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="form-group col-sm-5 mb-3">
                    <label for="firstName" class="mb-1">{{ translatePrefix + '.FIRSTNAME' | translate }}</label>
                    <input type="text" class="form-control" id="firstName" name="firstName" [(ngModel)]="conferenceVenue.contactFirstName">
                </div>
                <div class="form-group col-sm-5 mb-3">
                    <label for="lastName" class="mb-1">{{ translatePrefix + '.LASTNAME' | translate }}</label>
                    <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="conferenceVenue.contactLastName">
                </div>
            </div>
            <h5 class="border-bottom pb-3 my-4">{{ translatePrefix + '.SUBHEADER3' | translate }}</h5>
            <div class="form-group mb-3">
                <label for="accountOwner" class="mb-1">{{ translatePrefix + '.ACCOUNT_OWNER' | translate }}</label>
                <input type="text" class="form-control" id="accountOwner" name="accountOwner" [(ngModel)]="conferenceVenue.bankAccountHolder">
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-3 mb-3">
                    <label for="bic" class="mb-1">{{ translatePrefix + '.BIC' | translate }}</label>
                    <input type="text" class="form-control" id="bic" name="bic" [(ngModel)]="conferenceVenue.bankBic">
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="blz" class="mb-1">{{ translatePrefix + '.BLZ' | translate }}</label>
                    <input type="text" class="form-control" id="blz" name="blz" [(ngModel)]="conferenceVenue.bankBlz">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="iban" class="mb-1">{{ translatePrefix + '.IBAN' | translate }}</label>
                    <input type="text" class="form-control" id="iban" name="iban" [(ngModel)]="conferenceVenue.bankIban">
                </div>
            </div>
            <!--<h5 class="border-bottom pb-3 my-4">{{ translatePrefix + '.SUBHEADER4' | translate }}</h5>
            <div class="d-flex mb-3">
                <div class="form-group me-3 w-50">
                    <label for="price1day" class="mb-1">{{ translatePrefix + '.PRICE1_DAY' | translate }}</label>
                    <input type="text" class="form-control" id="price1day" name="price1day">
                </div>
                <div class="form-group w-50">
                    <label for="price1" class="mb-1">{{ translatePrefix + '.PRICE1' | translate }}</label>
                    <input type="text" class="form-control" id="price1" name="price1">
                </div>
            </div>
            <div class="d-flex mb-3">
                <div class="form-group me-3 w-50">
                    <label for="price2day" class="mb-1">{{ translatePrefix + '.PRICE2_DAY' | translate }}</label>
                    <input type="text" class="form-control" id="price2day" name="price2day">
                </div>
                <div class="form-group w-50">
                    <label for="price2" class="mb-1">{{ translatePrefix + '.PRICE2' | translate }}</label>
                    <input type="text" class="form-control" id="price2" name="price2">
                </div>
            </div>
            <div class="d-flex">
                <div class="form-group me-3 w-50">
                    <label for="price3day" class="mb-1">{{ translatePrefix + '.PRICE3_DAY' | translate }}</label>
                    <input type="text" class="form-control" id="price3day" name="price3day">
                </div>
                <div class="form-group w-50">
                    <label for="price3" class="mb-1">{{ translatePrefix + '.PRICE3' | translate }}</label>
                    <input type="text" class="form-control" id="price3" name="price3">
                </div>
            </div>-->
        </form>
    </div>
    <div class="modal-footer">
        <div class="d-flex w-100 justify-content-end">
            <button class="btn btn-primary me-1" (click)="submit()" [disabled]="validate()">{{ translatePrefix + '.BUTTONS.SAVE' | translate }}</button>
            <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-container>