<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ translatePrefix + '.HEADER' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form name="createDepartmentForm" *ngIf="department">
            <div class="mb-4 alert alert-info" role="alert">
                {{ 'FORMS.REQUIRED' | translate }}
            </div>
            <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                <div class="d-flex">
                    <i class="fa-solid fa-xmark mt-1 me-2"></i>
                    {{ 'ERRORCODE.' + errorCode | translate }}
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-3 mb-3">
                    <label for="abbreviation" class="mb-1">{{ translatePrefix + '.ABBREVIATION' | translate }}</label>
                    <input type="text" class="form-control" id="abbreviation" name="abbreviation" [(ngModel)]="department.shorthand" [ngClass]="{'required-input': !department.shorthand}" required>
                </div>
                <div class="form-group col-sm-9 mb-3">
                    <label for="designation" class="mb-1">{{ translatePrefix + '.DESIGNATION' | translate }}</label>
                    <input type="text" class="form-control" id="designation" name="designation" [(ngModel)]="department.name" [ngClass]="{'required-input': !department.name}" required>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-3 mb-3">
                    <label for="postalCode" class="mb-1">{{ translatePrefix + '.POSTALCODE' | translate }}</label>
                    <input type="text" class="form-control" id="postalCode" name="postalCode" [(ngModel)]="department.zip" [ngClass]="{'required-input': !department.zip}" required>
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="place" class="mb-1">{{ translatePrefix + '.PLACE' | translate }}</label>
                    <input type="text" class="form-control" id="place" name="place" [(ngModel)]="department.location" [ngClass]="{'required-input': !department.location}" required>
                </div>
                <div class="form-group col-sm-6 mb-31">
                    <label for="street" class="mb-1">{{ translatePrefix + '.STREET' | translate }}</label>
                    <input type="text" class="form-control" id="street" name="street" [(ngModel)]="department.street" [ngClass]="{'required-input': !department.street}" required>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="d-flex w-100 justify-content-end">
            <button class="btn btn-primary me-1" (click)="submit()" [disabled]="validate()">{{ translatePrefix + '.BUTTONS.SAVE' | translate }}</button>
            <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-container>