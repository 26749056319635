<ng-template #createMeeting let-modal>
    <app-create-meeting-modal [isDisabled]="true" [translatePrefix]=" translatePrefix + '.MODAL.MEETING'" [modal]="modal" [seminar]="selectedMeeting?.seminar?.seminarId" [meeting]="selectedMeeting?.meeting"></app-create-meeting-modal>
</ng-template>

<app-list-pdf [list]="keydatesList" [propertyNames]="printPropertyNames" [property2]="printProperty2" [translatePrefix]="'PDF.LIST.KEYDATES'" [translates]="translates" [types]="printTypes"></app-list-pdf>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header> 
    <div class="mb-3">
        <app-filter-menu [search]="search" [translatePrefix]="translatePrefix + '.FILTERMENU'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)"></app-filter-menu>
    </div>
    <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
        <div class="d-flex">
            <i class="fa-solid fa-xmark mt-1 me-2"></i>
            {{ 'ERRORCODE.' + errorCode | translate }}
        </div>
    </div>
    <div class="d-flex mb-3">
        <button type="submit" class="btn btn-primary me-2" style="width: 100px;" (click)="print()" [disabled]="isLoadingPrint" placement="bottom" ngbTooltip="{{ translatePrefix + '.EXPORT' | translate }}">
            <i class="fa-solid fa-file-export"></i>
        </button>
    </div>
    <app-universal-table [noFilterInTable]="true" [noSorting]="true" [translatePrefix]="translatePrefix + '.TABLE'" [property2Columns]="property2Columns" [columns]="columns" [buttons]="buttons" [filters]="filters" 
        [getAllMethod]="getAllMethod" [search]="search" (editEvent)="openModal(createMeeting, 'create-meeting-modal', 'xl', $event)"></app-universal-table>
</div>