<div>
    <div class="table-responsive">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th *ngFor="let col of columns; index as index">
                        <div class="d-flex">
                            {{ translatePrefix + '.COL.' + col | uppercase | translate }}
                            <div class="ms-3 filter-wrap">
                                <div class="filter-button-up">
                                    <i class="fa-solid fa-caret-up row g-0" [ngClass]="{'text-red opacity-50': orderDirection == 'asc'}" (click)="toggleSort('asc')"></i>
                                </div>
                                <div class="filter-button-down">
                                    <i class="fa-solid fa-caret-down row g-0" [ngClass]="{'text-red opacity-50': orderDirection == 'desc'}" (click)="toggleSort('desc')"></i>
                                </div>
                            </div>
                            <div ngbDropdown class="ms-3">
                                <i ngbDropdownToggle id="filterDropdown" class="fa-solid fa-filter pt-1" [ngClass]="{'text-red opacity-50': getProperty(search, varKeys[index + 1]) != null}"></i>
                                <div ngbDropdownMenu aria-labelledby="filterDropdown" style="min-width: 200px;">
                                    <div class="mx-3 mb-1">
                                        <label for="{{'filter_' + col}}" class="mb-1"> {{ translatePrefix + '.COL.' + col | uppercase | translate }} </label>
                                        <filter [type]="'text'" [resetButton]="true" [search]="getProperty(search, varKeys[index + 1])" [column]="col" (filterEvent)="setProperty(search, varKeys[index + 1], $event); emitFilter(search)"></filter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </th>
                    <th style="width: 5%;"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of tableContent; index as index">
                    <ng-container *ngFor="let col of columns; last as last">
                        <td class="position-relative">
                            <div class="d-flex">
                                <input name="{{index}}_input" id="{{index}}_input" class="form-control" [ngModel]="getProperty(item, col)" (ngModelChange)="setProperty(item, col, $event)">
                                <button type="button" id="{{index}}_ack" name="{{index}}_ack" class="btn btn-primary" (click)="emitEdit([getProperty(item, col), item])" placement="right" ngbTooltip="{{ 'TABLE.SAVE' | translate }}" container="body">
                                    <i class="fa-solid fa-check"></i>
                                </button>
                            </div>
                        </td>
                        <td *ngIf="last">
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-primary" [ngClass]="{'me-1': !last}" (click)="emitDelete(item)" placement="right" ngbTooltip="{{ 'TABLE.DEL' | translate }}" container="body">
                                    <i class="fa-solid fa-trash-can"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                </tr>
                <tr *ngIf="isLoading && !isError">
                    <td colspan="100">
                        <div class="text-center w-100">
                            <i class="fa-solid fa-circle-notch fa-spin"></i>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="!isLoading && isError">
                    <td colspan="100" class="table-danger">
                        <div class="text-center w-100">
                            {{ 'ERRORCODE.' + errorCode | translate }}
                        </div>
                    </td>
                </tr>
                <tr *ngIf="tableContent && tableContent!.length == 0 && !isLoading && !isError">
                    <td colspan="100">
                        <div class="text-center w-100">
                            {{ translatePrefix + '.NORESULT' | translate }}
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div class="d-flex">
                            <input type="text" class="form-control" [(ngModel)]="newItem">
                            <button class="btn btn-primary" [disabled]="newItem == undefined || newItem == ''" (click)="emitAdd(newItem)">
                                <i class="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>