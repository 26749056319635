import { Component, Input } from '@angular/core';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { Meeting } from 'src/app/models/meeting.model';
import { Participation } from 'src/app/models/participation.model';
import { Seminar } from 'src/app/models/seminar.model';
import { Speaker } from 'src/app/models/speaker.model';

@Component({
  selector: 'app-attendence-list-pdf',
  styleUrl: './attendance-list-pdf.component.scss',
  templateUrl: './attendance-list-pdf.component.html',
})
export class AttendanceListPdfComponent {
  @Input() translatePrefix: string = 'PDF.ATTENDANCELIST';
  @Input() data?: [meeting: Meeting, seminar: Seminar]; // 0 = Meeting; 1 = Seminar
  @Input() participations?: Participation[];
  @Input() conferenceVenue?: ConferenceVenue;
  @Input() speakerList: Speaker[] = [];
  today: Date = new Date();
  
  constructor() { }
}