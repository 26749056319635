<app-participant-certification-pdf [participationCert]="participationCert" [moduleCertification]="true" [isBase]="isBase"></app-participant-certification-pdf>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>
    <div class="mb-3">
        <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" 
            (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)" [emptyOption]="false" [filterForModuleCert]="true"></app-filter-menu>
    </div>
    <div class="d-flex mb-3">
        <button type="submit" class="btn btn-primary me-2" style="width: 100px;" (click)="export()" [disabled]="list.length === 0" placement="bottom" ngbTooltip="{{ translatePrefix + '.EXPORT' | translate }}">
            <i class="fa-solid fa-file-export"></i>
        </button>
    </div>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" 
        [subTableButtons]="subTableButtons" [getAllMethod]="getAllMethod" [search]="search" [fieldTypes]="fieldTypes" [meetingSelection]="true" (tableContent)="setList($event)"></app-universal-table>
</div>