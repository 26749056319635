import { Component, Input } from '@angular/core';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';

@Component({
  selector: 'app-conference-venue-notice-pdf',
  styleUrl: './conference-venue-notice-pdf.component.scss',
  templateUrl: './conference-venue-notice-pdf.component.html',
})
export class ConferenceVenueNoticePdfComponent {
  @Input() translatePrefix: string = '';
  @Input() conferenceVenue?: ConferenceVenue;
    
  constructor() { }
}