import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import * as GLOBALS from "./../globals";
import { AuthService } from "./auth.service";
import { Observable, Subject, Subscription } from "rxjs";


@Injectable()
export class UiControlService implements OnDestroy { 
    hideUi: boolean = true;
    authSubscription?: Subscription;

    private uiEventSubject: Subject<boolean> = new Subject();
    uiEvent: Observable<boolean> = this.uiEventSubject.asObservable();

    constructor(private router: Router, private authService: AuthService) { 
        this.authSubscription = this.authService.loginEvent.subscribe((event: boolean) => {
            if(!event) {
              this.checkVisibility();
            }
        });
    }

    ngOnDestroy() {
        this.authSubscription?.unsubscribe();
    }

    checkVisibility() {
        this.hideUi = this.router.url.includes(GLOBALS.routeParameterLogin) 
            || this.router.url.includes(GLOBALS.routeParameterRequestPasswordReset) 
            || this.router.url.includes(GLOBALS.routeParameterSetPassword)
            || (this.router.url.includes(GLOBALS.routeParameterCreateAccount) 
            || (this.authService.authUser ? this.authService.authUser.roleName == GLOBALS.noaccountRole : true));

        this.uiEventSubject.next(this.hideUi);
        return this.hideUi;
    }
}