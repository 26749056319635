<ng-template #createDepartment let-modal>
    <app-create-department-venue-modal [translatePrefix]=" translatePrefix + '.MODAL'" [modal]="modal" [department]="selectedDepartment"></app-create-department-venue-modal>
</ng-template>

<ng-template #deleteDepartment let-modal>
    <app-generic-modal [translatePrefix]="'DELETE.DEPARTMENT'" [modal]="modal" [payload]="payload" [method]="deleteMethod" [diffServerErrorMessage]="'DEPARTMENTINUSE'"></app-generic-modal>
</ng-template>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>
    <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)"></app-filter-menu>
    <button type="submit" class="btn btn-primary my-3" style="width: 100px;" (click)="openModal(createDepartment, 'create-consultant-modal', 'xl')" placement="bottom" ngbTooltip="{{ translatePrefix + '.ADD' | translate }}">
        <i class="fa-solid fa-building"></i>&nbsp;
        <i class="fa-solid fa-plus"></i>
    </button>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" 
        [getAllMethod]="getAllMethod" [search]="search" (deleteEvent)="setPayload($event); openModal(deleteDepartment, 'delete-department-modal', '')" (editEvent)="openModal(createDepartment, 'create-department-modal', 'xl', $event)"></app-universal-table>
</div>