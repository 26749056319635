<nav class="d-flex" *ngIf="!isLoading">
    <ul class="pagination">
      <li class="page-item">
        <a class="page-link"> &laquo; </a>
      </li>
      <ng-container *ngIf="currentPage !== undefined && pages !== undefined">
        <ng-container *ngFor="let page of pages; let i = index">
            <li *ngIf="i + 1 === 2 && currentPage > 5" class="page-item" disabled>
              <a class="page-link">...</a>
            </li>
            <li *ngIf="i + 1 === pages.length - 1 && currentPage < pages.length - 4" class="page-item"disabled>
              <a class="page-link">...</a>
            </li>
            <li *ngIf="i + 1 === 1 || i + 1 === pages.length || i + 1 > currentPage - 4 && i + 1 < currentPage + 4" class="page-item" [ngClass]="{'active': currentPage === i + 1}" (click)="changePage(i+1)">
              <a class="page-link"> {{ i+1 }} </a>
            </li>
        </ng-container>
      </ng-container>
      <li class="page-item">
        <a class="page-link"> &raquo; </a>
      </li>
    </ul>
    <div class="ms-2" style="width: 5rem;">
      <select class="form-select" (change)="updateResult($event)" [ngModel]="pageSize" name="pagination" id="pagination">
        <option selected="selected" value="15">15</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>
    </div>
</nav>