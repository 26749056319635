<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ translatePrefix + '.HEADER' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form name="createSeminarForm" *ngIf="seminar">
            <div class="mb-4 alert alert-info" role="alert">
                {{ 'FORMS.REQUIRED' | translate }}
            </div>
            <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                <div class="d-flex">
                    <i class="fa-solid fa-xmark mt-1 me-2"></i>
                    {{ 'ERRORCODE.' + errorCode | translate }}
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="name" class="mb-1">{{ translatePrefix + '.NAME' | translate }}</label>
                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="seminar.name" [ngClass]="{'required-input': !seminar.name}" required>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="year" class="mb-1">{{ translatePrefix + '.YEAR' | translate }}</label>
                    <select class="form-select" id="buildon" name="buildon" [(ngModel)]="seminar.year" [ngClass]="{'required-input': !seminar.year}" required>
                        <option *ngFor="let year of years" class="form-option" [value]="year">{{ year }}</option>
                    </select>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="areaid" class="mb-1">{{ translatePrefix + '.AREAID' | translate }}</label>
                    <select class="form-select mb-1" id="areaid" name="areaid" [(ngModel)]="seminar.areaId" [ngClass]="{'required-input': !seminar.areaId}" required>
                        <ng-container *ngIf="!isLoadingForm && !isErrorForm">
                            <option *ngFor="let area of areas" class="form-option" [value]="area.areaId">{{ area.areaValue }}</option>
                        </ng-container>
                        <ng-container *ngIf="isLoadingForm || isErrorForm">
                            <option *ngIf="isLoadingForm" class="form-option">{{ translatePrefix + '.NORESULT' | translate }}</option>
                            <option *ngIf="isErrorForm" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="category" class="mb-1">{{ translatePrefix + '.CATEGORY' | translate }}</label>
                    <select class="form-select" id="category" name="category" [(ngModel)]="seminar.categoryId" [ngClass]="{'required-input': !seminar.categoryId}" required>
                        <ng-container *ngIf="!isLoadingForm && !isErrorForm">
                            <option *ngFor="let category of categories" class="form-option" [value]="category.categoryId">{{ category.categoryValue }}</option>
                        </ng-container>
                        <ng-container *ngIf="isLoadingForm || isErrorForm">
                            <option *ngIf="isLoadingForm" class="form-option"><i class="fa-solid fa-circle-notch fa-spin"></i></option>
                            <option *ngIf="isErrorForm" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                        </ng-container>
                        <option *ngIf="categories && categories.length == 0" class="form-option">{{ translatePrefix + '.NOCATEGORIES' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="fileNum" class="mb-1">{{ translatePrefix + '.FILENUMBER' | translate }}</label>
                    <input type="text" class="form-control" id="fileNum" name="fileNum" [(ngModel)]="seminar.fileNumber" [ngClass]="{'required-input': !seminar.fileNumber}" required>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="identifier" class="mb-1">{{ translatePrefix + '.IDENTIFIER' | translate }}</label>
                    <input type="text" class="form-control" id="identifier" name="identifier" [(ngModel)]="seminar.fileIdentifier">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="expandson" class="mb-1">{{ translatePrefix + '.EXPANDSONSID' | translate }}</label>
                    <select class="form-select" id="expandson" name="expandson" [(ngModel)]="seminar.expandsOnSid">
                        <option [value]="''"></option>
                        <ng-container *ngIf="seminarList && seminarList.length != 0">
                            <option *ngFor="let expansion of seminarList" class="form-option" [value]="expansion.seminarId">{{ expansion.name }}</option>
                        </ng-container>
                        <option *ngIf="seminarList && seminarList.length == 0" class="form-option">{{ translatePrefix + '.NOEXPANSION' | translate }}</option>
                        <option *ngIf="isErrorSeminar" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                    </select>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="page" class="mb-1">{{ translatePrefix + '.PAGE' | translate }}</label>
                    <input type="number" class="form-control" id="page" name="page" [(ngModel)]="seminar.pageJfp">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="participationFee" class="mb-1">{{ translatePrefix + '.PARTICIPATIONFEE' | translate }}</label>
                    <input type="number" class="form-control" id="participationFee" name="participationFee" [(ngModel)]="seminar.participationFee" [ngClass]="{'required-input': !seminar.participationFee}" required>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="authorizationField" class="mb-1">{{ translatePrefix + '.AREAOFAUTHORIZATION' | translate }}</label>
                    <input class="form-control"  id="authorizationField" name="authorizationField" [(ngModel)]="seminar.areaOfAuthorization" [ngClass]="{'required-input': !seminar.areaOfAuthorization}" required>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="chapter" class="mb-1">{{ translatePrefix + '.CHAPTER' | translate }}</label>
                    <input type="text" class="form-control" id="chapter" name="chapter" [(ngModel)]="seminar.chapter" [ngClass]="{'required-input': !seminar.chapter}" required>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="title" class="mb-1">{{ translatePrefix + '.TITLE' | translate }}</label>
                    <input type="text" class="form-control" id="title" name="title" [(ngModel)]="seminar.title" [ngClass]="{'required-input': !seminar.title}" required>
                </div>
            </div>
            <div class="form-group mb-3">
                <label for="shortDescription" class="mb-1">{{ translatePrefix + '.SHORTDESCRIPTION' | translate }}</label>
                <input type="text" class="form-control" id="shortDescription" [(ngModel)]="seminar.shortDescription" [ngClass]="{'required-input': !seminar.shortDescription}" name="shortDescription" required>
            </div>
            <div class="form-group mb-3">
                <label for="tinyTargetAudience" class="mb-1">{{ translatePrefix + '.TARGETAUDIENCE' | translate }}</label>
                <editor [init]="tinyConfig" [(ngModel)]="seminar.targetAudience" name="tinyTargetAudience"></editor>
            </div>
            <div class="form-group mb-3">
                <label for="tinyUse" class="mb-1">{{ translatePrefix + '.USE' | translate }}</label>
                <editor [init]="tinyConfig" [(ngModel)]="seminar.gain" name="tinyUse"></editor>
            </div>
            <div class="form-group mb-3">
                <label for="tinyContent" class="mb-1">{{ translatePrefix + '.SEMINARCONTENT' | translate }}</label>
                <editor [init]="tinyConfig" [(ngModel)]="seminar.seminarContent" name="tinyContent" [ngClass]="{'required-input-tiny': !seminar.seminarContent}" required></editor>
            </div>
            <div class="form-group mb-3">
                <label for="tinyComment" class="mb-1">{{ translatePrefix + '.NOTICE' | translate }}</label>
                <editor [init]="tinyConfig" [(ngModel)]="seminar.notice" name="tinyComment"></editor>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="d-flex w-100 justify-content-end">
            <button class="btn btn-secondary me-1" (click)="submit()" [disabled]="validate() || isLoading">
                {{ translatePrefix + '.BUTTONS.SAVE' | translate }}
                <i *ngIf="isLoading" class="fa-solid fa-circle-notch fa-spin"></i>
            </button>
            <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-container>