export class Participation {
    meetingId?: string = '';
    participantId?: string = '';
    participantEmail?: string = '';
    seminarName?: string = '';
    salutationId?: number = 255;
    academicTitle?: string | null = null;
    firstName?: string = '';
    lastName?: string = '';
    info?: string = '';
    date?: Date = new Date();
    startDate?: Date = new Date();
    endDate?: Date = new Date();
    participating?: boolean = false;
    participatingStatus: number = 0;
    departmentId?: string = '';
    street?: string = ''; // of reporting office
    zip?: string = ''; // of reporting office
    location?: string = ''; // of reporting office
    careerId?: number = -1;
    statusId?: number  = -1;
    organisationId?: number = -1;
    partTime?: boolean = false;
    overnightStay?: boolean = false;
    position?: number = -1;
    editUserFirstName?: string = '';
    editUserLastName?: string = '';
    editUserSalutationId?: number = 255;
    editUserEmail?: string = '';
    editUserDirectDialing?: string = '';
    confirmationDate?: Date;
    cancellationDate?: Date = new Date();
    invoiceStatus?: number = -1;
    invoiceIdentifier?: string = '';
    reportingOfficeUserId?: string | null = null;
    reason?: string | null = null;
    reportingOffice?: string | null = null;
    signiture?: string | null = null;
    participationFee?: number = 0;
    departmentName?: string | null = null;
    departmentShorthand?: string | null = null;
    departmentStreet?: string | null = null;
    departmentZip?: string | null = null;
    departmentLocation?: string | null = null;
    invoiceDate?: Date = new Date();
    dueDate?: Date = new Date();
    areaOfAuthorization?: string = '';

    constructor(meetingId?: string,participantId?: string, participantEmail?: string, seminarName?: string, salutationId?: number, academicTitle?: string | null, firstName?: string, lastName?: string, info?: string, 
        date?: Date, startDate?: Date, endDate?: Date, participating?: boolean, participatingStatus?: number, departmentId?: string, street?: string, zip?: string, location?: string, 
        careerId?: number, statusId?: number, organisationId?: number, partTime?: boolean, overnightStay?: boolean, position?: number, editUserFirstName?: string, editUserLastName?: string, 
        editUserSalutationId?: number, editUserEmail?: string, editUserDirectDialing?: string, confirmationDate?: Date, cancellationDate?: Date, invoiceStatus?: number, invoiceIdentifier?: string, 
        reportingOfficeUserId?: string | null, reason?: string | null, reportingOffice?: string | null, signiture?: string | null, participationFee?: number, departmentName?: string | null, departmentShorthand?: string | null,
        departmentStreet?: string | null, departmentZip?: string | null, departmentLocation?: string | null, invoiceDate?: Date, dueDate?: Date, areaOfAuthorization?: string) {

        this.meetingId = meetingId;
        this.participantId = participantId;
        this.participantEmail = participantEmail;
        this.seminarName = seminarName;
        this.salutationId = salutationId;
        this.academicTitle = academicTitle;
        this.firstName = firstName;
        this.lastName = lastName;
        this.info = info;
        this.date = date;
        this.startDate = startDate;
        this.endDate = endDate;
        this.participating = participating === undefined ? false : participating;
        this.participatingStatus = participatingStatus === undefined ? 0 : participatingStatus;
        this.departmentId = departmentId;
        this.street = street;
        this.zip = zip;
        this.location = location;
        this.careerId = careerId;
        this.statusId = statusId;
        this.organisationId = organisationId;
        this.partTime = partTime === undefined ? false : partTime;
        this.overnightStay = overnightStay === undefined ? false : overnightStay;
        this.position = position;
        this.editUserFirstName = editUserFirstName;
        this.editUserLastName = editUserLastName;
        this.editUserSalutationId = editUserSalutationId;
        this.editUserEmail = editUserEmail;
        this.editUserDirectDialing = editUserDirectDialing;
        this.confirmationDate = confirmationDate;
        this.cancellationDate = cancellationDate;
        this.invoiceStatus = invoiceStatus;
        this.invoiceIdentifier = invoiceIdentifier;
        this.reportingOfficeUserId = reportingOfficeUserId;
        this.reason = reason;
        this.reportingOffice = reportingOffice;
        this.signiture = signiture;
        this.participationFee = participationFee;
        this.departmentName = departmentName;
        this.departmentShorthand = departmentShorthand;
        this.departmentStreet = departmentStreet;
        this.departmentZip = departmentZip;
        this.departmentLocation = departmentLocation;
        this.invoiceDate = invoiceDate;
        this.dueDate = dueDate;
        this.areaOfAuthorization = areaOfAuthorization;
    }
}