import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Category, Form } from 'src/app/models/form.model';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'edit-category',
  styleUrl: './edit-category.component.scss',
  templateUrl: './edit-category.component.html',
})
export class EditCategoryComponent implements OnInit, OnDestroy { 
  translatePrefix: string = 'EDIT.EDIT_CATEGORY';  
  columns: string[] = ['categoryValue'];
  buttons: string[] = ['editDelBtn'];
  categories?: Category[] = [];
  addMethod: string = 'addCategory';
  editMethod: string = 'editCategory';
  deleteMethod: string = 'deleteCategory';
  keys: string[] = ['categoryId', 'categoryFilter'];
  formSubscription?: Subscription;
  
  constructor(private formService: FormService) { }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  ngOnInit() { 
    this.formSubscription = this.formService.refresh.subscribe({
      next: (res: boolean) => {
        if(!res) {
          this.categories = this.formService.data?.categories;
        }
      }
    });

    this.formService.updateData();
  }
}