<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ !participationList[0] ? (isAccepted ? (translatePrefix + '.HEADER3' | translate) : (translatePrefix + '.HEADER' | translate)) : (translatePrefix + '.HEADER2' | translate) }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">                    
        <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
            <div class="d-flex">
                <i class="fa-solid fa-xmark mt-1 me-2"></i>
                {{ 'ERRORCODE.' + errorCode | translate }}
            </div>
        </div> 
        <div class="row">
            <ng-container *ngFor="let participation of participationList; first as first; index as index">
                <div *ngIf="participation" [ngClass]="{'col': !participationList[0], 'col-lg-6 p-3': participationList[0], 'border-start': participationList[0] && index === 1}">
                    <div class="row gx-2">
                        <div class="form-group col-sm-6 mb-3">
                            <label for="salutaion" class="mb-1">{{ translatePrefix + '.SALUTATION' | translate }}</label>
                            <select class="form-select" id="salutaion" name="salutaion" [ngModel]="participation.salutationId" [disabled]="true">
                                <ng-container *ngFor="let salutaion of salutaions">
                                    <option class="form-option" [value]="salutaion[0]">{{ translatePrefix + '.SALUTATIONS.' + salutaion[1] | translate }}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="form-group col-sm-6 mb-3">
                            <label for="academicTitle" class="mb-1">{{ translatePrefix + '.TITLE' | translate }}</label>
                            <input type="text" class="form-control" id="academicTitle" name="academicTitle" [ngModel]="participation.academicTitle" [disabled]="true">
                        </div>
                    </div>
                    <div class="row gx-2">
                        <div class="form-group col-sm-6 mb-3">
                            <label for="firstname" class="mb-1">{{ translatePrefix + '.FIRSTNAME' | translate }}</label>
                            <input type="text" class="form-control" id="firstname" name="firstname" [ngModel]="participation.firstName" [disabled]="true">
                        </div>
                        <div class="form-group col-sm-6 mb-3">
                            <label for="lastname" class="mb-1">{{ translatePrefix + '.LASTNAME' | translate }}</label>
                            <input type="text" class="form-control" id="lastname" name="lastname" [ngModel]="participation.lastName" [disabled]="true">
                        </div>
                    </div>
                    <div class="row gx-2">
                        <div class="form-group col-sm-12 mb-3">
                            <label for="email" class="mb-1">{{ translatePrefix + '.EMAIL' | translate }}</label>
                            <input type="email" class="form-control" id="email" name="email" autocomplete="email" [ngModel]="participation.participantEmail" [disabled]="true">
                        </div>
                    </div>
                    <div class="row gx-2">
                        <div class="form-group col-sm-6 mb-3">
                            <label for="position" class="mb-1">{{ translatePrefix + '.POSITION' | translate }}</label>
                            <input type="number" class="form-control" id="position" name="position" [(ngModel)]="participation.position" [disabled]="index === 0" [ngClass]="{'required-input': !participation.position}" required>
                        </div>
                        <div class="form-group col-sm-6 mb-3">
                            <label for="department" class="mb-1">{{ translatePrefix + '.DEPARTMENT' | translate }}</label>
                            <select class="form-select" id="department" name="department" [(ngModel)]="participation.departmentId" [disabled]="index === 0" [ngClass]="{'required-input': !participation.departmentId}" required>
                                <option [value]="''"></option>
                                <ng-container *ngIf="departments && departments.length != 0">
                                    <option *ngFor="let department of departments" class="form-option" [value]="department.departmentId">{{ department.name }}</option>
                                </ng-container>
                                <option *ngIf="departments && departments.length == 0" class="form-option">{{ translatePrefix + '.NODEPARTMENT' | translate }}</option>
                                <option *ngIf="isErrorDepartments" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row gx-2">
                        <div class="form-group col-sm-6 mb-3">
                            <label for="careerId" class="mb-1">{{ translatePrefix + '.CAREER' | translate }}</label>
                            <select class="form-select" id="careerId" name="careerId" [(ngModel)]="participation.careerId" [disabled]="index === 0" [ngClass]="{'required-input': !participation.careerId}" required>
                                <ng-container *ngIf="!isLoadingForm && !isErrorForm">
                                    <option [value]="''"></option>
                                    <option *ngFor="let career of careers" class="form-option" [value]="career.careerId">{{ career.careerValue }}</option>
                                </ng-container>
                                <ng-container *ngIf="isLoadingForm || isErrorForm">
                                    <option *ngIf="isLoadingForm" class="form-option">{{ translatePrefix + '.NOCAREER' | translate }}</option>
                                    <option *ngIf="isErrorForm" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="form-group col-sm-6 mb-3">
                            <label for="statusId" class="mb-1">{{ translatePrefix + '.STATUS' | translate }}</label>
                            <select class="form-select" id="statusId" name="statusId" [(ngModel)]="participation.statusId" [disabled]="index === 0" [ngClass]="{'required-input': !participation.statusId}" required>
                                <ng-container *ngIf="!isLoadingForm && !isErrorForm">
                                    <option [value]="''"></option>
                                    <option *ngFor="let status of statuses" class="form-option" [value]="status.statusId">{{ status.statusValue }}</option>
                                </ng-container>
                                <ng-container *ngIf="isLoadingForm || isErrorForm">
                                    <option *ngIf="isLoadingForm" class="form-option">{{ translatePrefix + '.NOSTATUS' | translate }}</option>
                                    <option *ngIf="isErrorForm" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <div class="row gx-2">
                        <div class="form-group col-sm-6 mb-3">
                            <div class="form-check-label mb-1">{{ translatePrefix + '.PARTTIME' | translate }}</div>
                            <div class="d-flex">
                                <div class="form-check me-3">
                                    <label for="parttime_yes" class="form-check-label mb-1">{{ translatePrefix + '.YES' | translate }}</label>
                                    <input type="radio" class="form-check-input" id="parttime_yes" name="parttime_yes" [checked]="participation.partTime" (click)="setProperty(participation, 'partTime', true)" [disabled]="index === 0" required>
                                </div>
                                <div class="form-check">
                                    <label for="parttime_no" class="form-check-label mb-1">{{ translatePrefix + '.NO' | translate }}</label>
                                    <input type="radio" class="form-check-input" id="parttime_no" name="parttime_no" [checked]="!participation.partTime" (click)="setProperty(participation, 'partTime', false)" [disabled]="index === 0" required>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-sm-6 mb-3">
                            <label class="form-check-label mb-1">{{ translatePrefix + '.OVERNIGHTSTAY' | translate }}</label>
                            <div class="d-flex">
                                <div class="form-check me-3">
                                    <label for="overnight_yes" class="form-check-label mb-1">{{ translatePrefix + '.YES' | translate }}</label>
                                    <input type="radio" class="form-check-input" id="overnight_yes" name="overnight_yes" [checked]="participation.overnightStay" (click)="setProperty(participation, 'overnightStay', true)" [disabled]="index === 0" required>
                                </div>
                                <div class="form-check">
                                    <label for="overnight_no" class="form-check-label mb-1">{{ translatePrefix + '.NO' | translate }}</label>
                                    <input type="radio" class="form-check-input" id="overnight_no" name="overnight_no" [checked]="!participation.overnightStay" (click)="setProperty(participation, 'overnightStay', false)" [disabled]="index === 0" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="info" class="mb-1">{{ translatePrefix + '.INFO' | translate }}</label>
                        <textarea class="form-control" id="info" name="info" rows="4" [(ngModel)]="participation.info" [disabled]="index === 0"></textarea>
                    </div>
                    <ng-container *ngIf="hasAdminFields">
                        <div class="row gx-2">
                            <div class="form-group col-sm-6 mb-3">
                                <label for="confirmationDate" class="mb-1">{{ translatePrefix + '.INVOICESTATUS' | translate }}</label>
                                <select class="form-select" [(ngModel)]="participation.invoiceStatus">
                                    <option *ngFor="let status of invoiceStatus" class="form-option" [ngValue]="status[0]">{{ status[1] }}</option>
                                </select>
                            </div>
                            <div class="form-group col-sm-6 mb-3">
                                <label for="invoiceIdentifier" class="mb-1">{{ translatePrefix + '.IDENTIFIER' | translate }}</label>
                                <input type="text" class="form-control" id="invoiceIdentifier" name="invoiceIdentifier" [(ngModel)]="participation.invoiceIdentifier">
                            </div>
                        </div>
                        <div class="row gx-2">
                            <div class="form-group col-sm-6 mb-3">
                                <label for="confirmationDate" class="mb-1">{{ translatePrefix + '.CONFIRMDATE' | translate }}</label>
                                <input type="text" class="form-control" id="confirmationDate" name="confirmationDate" [ngModel]="participation.confirmationDate | date:'dd.MM.yyyy, hh:mm'" [disabled]="true">
                            </div>
                            <div class="form-group col-sm-6 mb-3">
                                <label for="cancellationDate" class="mb-1">{{ translatePrefix + '.CANCELDATE' | translate }}</label>
                                <input type="text" class="form-control" id="cancellationDate" name="cancellationDate" [ngModel]="participation.cancellationDate | date:'dd.MM.yyyy, hh:mm'" [disabled]="true">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="!isAccepted">
            <button class="btn btn-secondary me-1" (click)="reject()">{{ translatePrefix + '.BUTTONS.REJECT' | translate }}</button>
            <button class="btn btn-secondary me-1" (click)="accept()" [disabled]="validate()">{{ translatePrefix + '.BUTTONS.ACCEPT' | translate }}</button>
        </ng-container>
        <ng-container *ngIf="isAccepted">
            <button class="btn btn-secondary me-1" (click)="submit()" [disabled]="validate()">{{ translatePrefix + '.BUTTONS.SAVE' | translate }}</button>
        </ng-container>
        <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
    </div>
</ng-container>