<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title" id="generic-modal-title">{{ 'GENERICMODAL.' + translatePrefix + '.TITLE' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!isLoading">
            <p *ngIf="errorCode === ''">{{ 'GENERICMODAL.' + translatePrefix + '.BODYTEXT' | translate }}</p>
            <p *ngIf="errorCode !== ''">{{ 'ERRORCODE.' + errorCode | uppercase | translate }}</p>
        </ng-container>
        <div *ngIf="isLoading" class="text-center m-3">
            <i class="fa-solid fa-circle-notch fa-spin fa-xl"></i>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-secondary" (click)="submit()" [disabled]="isLoading || isError"> {{ 'GENERICMODAL.' + translatePrefix + '.DELETE' | translate }} </button>
        <button type="button" class="btn btn-primary" (click)="modal.dismiss()"> {{ 'GENERICMODAL.' + translatePrefix + '.CANCEL' | translate }} </button>
    </div>
</ng-container>