import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Credentials } from 'src/app/models/credentials.model';
import { AuthService } from 'src/app/services/auth.service';
import * as GLOBALS from "../../globals";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  styleUrl: './login.component.scss',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  translatePrefix: string = 'LOGIN';
  user: Credentials = new Credentials();
  pwForgottenPath: string = '/' + GLOBALS.routeParameterRequestPasswordReset;
  registrationPath: string = '/' + GLOBALS.routeParameterSeminarRegistration;
  isError: boolean = false;
  errorCode: string = '';
  redirectRoute: string = '';
  isLoading: boolean = false;
  authSubscription?: Subscription;
  routeSubscription?: Subscription;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) { }

  @HostListener('document:keyup.enter')
  handleKeyboardEvent(event: KeyboardEvent) { 
    if (!this.validate()) this.login();
  }

  ngOnDestroy() {
    this.authSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.authSubscription = this.authService.loginEvent.subscribe(
      (res: boolean) => {
        this.isLoading = res; // login completion
        this.isError = this.authService.isError;
        this.errorCode = this.authService.errorCode;
      }
    );
  }

  login() {
    let participantParams = undefined;

    this.routeSubscription = this.route.params.subscribe( params => {
      if(params['redirectTo']) {
        let containsPath: boolean = false;

        for(let route of this.router.config) { // checks for malicious redirectTo
          if( '/' + route.path == params['redirectTo'] && !containsPath) {
            containsPath = true;
            break;
          }
        }

        let path = params['redirectTo'] == '/' + GLOBALS.routeParameterSetInitialPassword ? '../' : params['redirectTo'];
        this.redirectRoute = containsPath ? path : '../';
      }
      if(params['meetingId'] || params['seminarname']) {
        participantParams = params 
      }
    });

    this.authService.login(this.user, this.redirectRoute, participantParams);
  }

  show() {
    let element = document.getElementById('password');
    let icon = document.getElementById('lockIcon');

    if (element && icon) {
      let type = element.getAttribute('type');

      element.setAttribute('type', type == 'password' ? 'text' : 'password');
      icon.setAttribute('class', type == 'password' ? 'fa-solid fa-lock-open' : 'fa-solid fa-lock');
    }
  }

  validate(): boolean {
    return !this.user.username || !this.user.password;
  }
}