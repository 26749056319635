<div>
    <div class="row justify-content-center mx-auto">
        <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 d-flex justify-content-center">
            <div class="card w-100">
                <div class="card-header">
                    <h5 class="card-title">{{ translatePrefix + '.HEADER' | translate }}</h5>
                </div>
                <div class="card-body">
                    <form name="setInintialPasswordForm">
                        <div *ngIf="isSuccess" class="mb-4 alert alert-success" role="alert">
                            <div class="d-flex">
                                <i class="fa-solid fa-check mt-1 me-2"></i>
                                {{ translatePrefix + '.SENDSUCCESS' | translate }}&nbsp;
                            </div>
                        </div>
                        <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                            <div class="d-flex">
                                <i class="fa-solid fa-xmark mt-1 me-2"></i>
                                {{ 'ERRORCODE.' + errorCode | translate }}
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="password1" class="mb-1">{{ translatePrefix + '.NEWPW1' | translate }}*</label>
                            <div class="password-wrap">
                                <input type="password" class="form-control" id="password1" name="password1" [(ngModel)]="newPassword" required>
                                <button type="button" class="password-button" (click)="show('1')">
                                    <i id="lockIcon1" class="fa-solid fa-lock"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="password2" class="mb-1">{{ translatePrefix + '.NEWPW2' | translate }}*</label>
                            <div class="password-wrap">
                                <input type="password" class="form-control" id="password2" name="password2" [(ngModel)]="repetitionPassword" required>
                                <button type="button" class="password-button" (click)="show('2')">
                                    <i id="lockIcon2" class="fa-solid fa-lock"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-end my-1">
                        <button type="submit" class="btn btn-primary" style="width: 150px;" (click)="set()" [disabled]="validate()">{{ translatePrefix + '.BUTTONS.SET' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>