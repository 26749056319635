import { PageableFilter } from "./pageable-filter.model";

export class SeminarFilter extends PageableFilter {
    fileNumber?: string | null = null;
    fileIdentifier?: string | null = null;
    name?: string | null = null;
    pageJfp?: number | null = null;
    year?: number | null = null;
    areaId?: number | null = null;

    constructor(page: number, pageSize: number, orderColumn?: string | null, orderDirection?: string | null, fileNumber?: string | null, fileIdentifier?: string | null, name?: string | null, pageJfp?: number | null, year?: number | null, areaId?: number | null) {
        super(page, pageSize, orderColumn, orderDirection);
        this.fileNumber = fileNumber;
        this.fileIdentifier = fileIdentifier;
        this.name = name;
        this.pageJfp = pageJfp;
        this.year = year;
        this.areaId = areaId;
    }
}