import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NextMeeting } from 'src/app/models/next-meeting.model';

@Component({
  selector: 'app-meeting-info-modal',
  styleUrl: './meeting-info-modal.component.scss',
  templateUrl: './meeting-info-modal.component.html',
})
export class MeetingInfoModalComponent {
  @Input() translatePrefix: string = '';
  @Input() modal?: NgbActiveModal;
  @Input() meeting?: NextMeeting;
    
  constructor() { }
}