import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetQuery } from 'src/app/models/get-query.model';
import { Meeting } from 'src/app/models/meeting.model';
import { ParticipantFilter } from 'src/app/models/participant-filter.model';
import { Participation } from 'src/app/models/participation.model';
import { Seminar } from 'src/app/models/seminar.model';
import * as GLOBALS from "../../globals";
import { RestService } from 'src/app/services/rest.service';
import { Subscription, finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { MeetingResult } from 'src/app/models/meeting-result.model';
import { ConferenceVenueResult } from 'src/app/models/conference-venue-result.model';
import { MeetingSingUp } from 'src/app/models/meeting-sign-up.model';
import { PdfService } from 'src/app/services/pdf.service';
import { SeminarResult } from 'src/app/models/seminar-result.model';

@Component({
  selector: 'app-registered-participant',
  styleUrl: './registered-participant.component.scss',
  templateUrl: './registered-participant.component.html',
})
export class RegisteredParticipantComponent implements OnInit, OnDestroy {
  @Input() translatePrefix: string = 'FORMS.REGISTEREDPARTICIPANT';
  columns: string[] = ['participating', 'position', 'firstName', 'lastName', 'info', 'invoiceStatus', 'date', 'confirmationDate', 'cancellationDate', 'participatingStatus'];
  buttons: string[] = ['mailAck', 'mailNack', 'exportBtn', 'editBtn', 'delBtn'];
  filters: string[] = ['', '', 'text', 'text', '', ''];
  fieldTypes: string[] = ['bool', 'text', 'text', 'text', 'text', 'invoiceStatusDropdown', 'date', 'date', 'date', 'status'];
  columnsFilterMenu: string[] = ['firstName', 'lastName'];
  filtersFilterMenu: string[] = ['text', 'text'];
  getAllMethod: string = 'allAcceptedSingups';
  deleteMethod: string = 'deleteSignups';
  payload?: GetQuery;
  search: ParticipantFilter = new ParticipantFilter(1, 200, 'lastName', 'asc');
  seminarPath: string = '/' + GLOBALS.routeParameterSeminar;
  list: Participation[] = [];
  selectedParticipation?: any;
  selectedParticipationList?: any[];
  restMethod: string = '';
  translate: string = '';
  conferenceVenue?: ConferenceVenue;
  meeting?: Meeting;
  seminar?: Seminar;
  isLoadingChanges: boolean = false;
  isErrorChanges: boolean = false;
  errorCode: string = '';
  changesSaved: boolean = false;
  routeSubscription?: Subscription;
  isLoadingSeminar: boolean = false;
  isErrorSeminar: boolean = false;  
  isLoadingMeeting: boolean = false;
  isErrorMeeting: boolean = false;
  isLoadingConferenceVenue: boolean = false;
  isErrorConferenceVenue: boolean = false;
  isLoadingCsv: boolean = false;
  sendAllParticipations: boolean = false;
    
  constructor(private modalService: NgbModal, private route: ActivatedRoute, private restService: RestService, 
    private authService: AuthService, private router: Router, private pdfService: PdfService) { 

  }

  ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.isLoadingMeeting = false;
    this.isLoadingSeminar = false;
    this.isLoadingConferenceVenue = false;

    let payload;

    this.routeSubscription = this.route.params.subscribe((params: any) => {
      if(params['meetingid']) {
        this.search.meetingId = params['meetingid'];
        payload = new GetQuery();
        payload.keyMap.set('meetingId', params['meetingid'] ?? '');

        this.restService.getMeetingById(payload).pipe(
          finalize(() => {
            this.isLoadingMeeting = false;
          })
        ).subscribe({
          next: res => {
            this.isLoadingMeeting = false;
            this.isErrorMeeting = false;
            this.meeting = (res as MeetingResult).result[0];
          },
          error: err => {
            console.log(err);
            this.isErrorMeeting = true;
            this.authService.checkAuth(err.status);
          }
        });
      }
      if(params['conferenceVenueId']) {
        payload = new GetQuery();
        payload.keyMap.set('conferenceVenueId', params['conferenceVenueId'] ?? '');
    
        this.restService.getConferenceVenueById(payload).pipe(
          finalize(() => {
            this.isLoadingConferenceVenue = false;
          })
        ).subscribe({
          next: res => {
            this.isLoadingConferenceVenue = false;
            this.isErrorConferenceVenue = false;
            this.conferenceVenue = (res as ConferenceVenueResult).result[0];
          },
          error: err => {
            console.log(err);
            this.isErrorConferenceVenue = true;
            this.authService.checkAuth(err.status);
          }
        });
      }
      if(params['seminarId']) {
        payload = new GetQuery();
        payload.keyMap.set('seminarId', params['seminarId'] ?? '');

          this.restService.getSeminarById(payload).pipe(
            finalize(() => {
              this.isLoadingSeminar = false;
            })
          ).subscribe({
            next: res => {
              this.isLoadingSeminar = false;
              this.isErrorSeminar = false;
              this.seminar = (res as SeminarResult).result[0];
            },
            error: err => {
              console.log(err);
              this.isErrorSeminar = true;
              this.authService.checkAuth(err.status);
            }
          });
        }
      } 
    );
  }

  openModal(modal: any, ariaLabelledBy: string, size: string, participation?: any) {
    this.selectedParticipation = participation;
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }
  
  setSearch(event: ParticipantFilter) { 
    this.search = new ParticipantFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.firstName, event.lastName, event.eMail, event.meetingId);
  }

  setPayload(event: Participation) { 
    this.payload = new GetQuery();
    this.payload.keyMap.set('meetingGuid', event.meetingId ?? '');
    this.payload.keyMap.set('participantId', event.participantId ?? '');
  }

  setList(event: Participation[]) {
    this.changesSaved = false;
    this.list = event;
  }

  saveList() {
    if(this.list.length === 0) return;

    this.isLoadingChanges = true;
    let signUpsList: MeetingSingUp[] = [];
    for(let participation of this.list) {
      signUpsList.push(new MeetingSingUp(participation.meetingId, participation.participantId, participation.info, participation.date, 
        participation.participating, participation.careerId, participation.statusId, participation.position, participation.partTime, participation.overnightStay,
        participation.departmentId, null, participation.confirmationDate, participation.cancellationDate, participation.invoiceStatus, participation.invoiceIdentifier, null, true)
      );
    }

    this.restService.saveSignupChanges(signUpsList).pipe(
      finalize(() => {
        this.isLoadingChanges = false;
      })
    ).subscribe({
      next: res => {
        this.isErrorChanges = false;
        this.changesSaved = true;
      }, error: err => {
        console.log(err);
        this.isErrorChanges = true;
        this.changesSaved = false;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    })
  }

  setRejectInputs() {
    this.sendAllParticipations = false;
    this.restMethod = 'cancellSignUp';
    this.translate = this.translatePrefix + '.MODAL.REJECT';
  }

  setAcceptInputs() {
    this.sendAllParticipations = false;
    this.restMethod = 'confirmSignUp';
    this.translate = this.translatePrefix + '.MODAL.ACCEPT';
  }

  navigateToParticipants() {
    this.router.navigate(['/' + GLOBALS.routeParameterParticipant, { meetingId: this.meeting?.meetingId, seminarname: this.seminar?.name }]);
  }

  print(participation: [ Participation, Participation | undefined ] ) {
    this.selectedParticipationList = [];
    this.selectedParticipationList[0] = participation[0];
    this.selectedParticipation = participation[0];

    this.pdfService.print('Teilnehmerbescheinigung_' + this.selectedParticipation.lastName, 'pdf-certification', undefined, false, true);
    this.pdfService.print('MeetingInvitation.pdf', 'pdf-invitation', undefined, false, true, true, undefined, undefined, { mode: ['css'] });

    let search = new ParticipantFilter(1, 25, null, null);
    search.meetingId = this.meeting?.meetingId;
    this.restService.allAcceptedSingups(search).subscribe({
      next: res => {
        this.isErrorChanges = false;
        this.list = res.result;
        this.pdfService.print('Unterlagen_' + this.selectedParticipation.lastName, 'pdf-participant-documents', undefined, false, true, undefined, undefined, undefined, { mode: ['legacy', 'css'] });
      }, error: err => {
        console.log(err);
        this.isErrorChanges = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });
  }

  downloadCsv() {
    let search = new ParticipantFilter(1, 25);
    search.meetingId = this.meeting?.meetingId;

    this.restService.createCsv(search).subscribe({
      next: res => {
        this.isErrorChanges = false;
        const blob = new Blob([res], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = 'rechnung_export.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
      }, error: err => {
        console.log(err);
        this.isErrorChanges = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });
  }

  setMeetingInvoice(event: Meeting) {
    this.meeting = event;
  }

  sendAll(modal: any, ariaLabelledBy: string, size: string) {
    this.sendAllParticipations = true;
    this.restMethod = 'confirmAndCancellSignups';
    this.translate = this.translatePrefix + '.MODAL.CONFIRMANDREJECT';
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }
}