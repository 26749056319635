<ng-template #createSpeaker let-modal>
    <app-create-speaker-modal [translatePrefix]=" translatePrefix + '.MODAL'" [modal]="modal" [speaker]="selectedSpeaker"></app-create-speaker-modal>
</ng-template>

<ng-template #deleteSpeaker let-modal>
    <app-generic-modal [translatePrefix]="'DELETE.SPEAKER'" [modal]="modal" [payload]="payload" [method]="deleteMethod" [diffServerErrorMessage]="'SPEAKERINUSE'"></app-generic-modal>
</ng-template>

<app-list-pdf [year]="yearSelected" [list]="speakerList" [propertyNames]="printPropertyNames" [translatePrefix]="'PDF.LIST.SPEAKER'" [translates]="printTranslates" [subHeading]="subHeading" [types]="printTypes"></app-list-pdf>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>
    <div class="mb-3">
        <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)"></app-filter-menu>
    </div>
    <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
        <div class="d-flex">
            <i class="fa-solid fa-xmark mt-1 me-2"></i>
            {{ 'ERRORCODE.' + errorCode | translate }}
        </div>
    </div>
    <div class="d-flex my-3">
        <button type="submit" class="btn btn-primary me-3" style="width: 100px;" (click)="openModal(createSpeaker, 'create-speaker-modal', 'xl')" placement="bottom" ngbTooltip="{{ translatePrefix + '.ADD' | translate }}">
            <i class="fa-solid fa-chalkboard-user"></i>&nbsp;
            <i class="fa-solid fa-plus"></i>
        </button>
        <div class="d-flex">
            <button type="submit" class="btn btn-primary me-2" style="width: 100px;" (click)="print()" [disabled]="isLoadingPrint" placement="bottom" ngbTooltip="{{ translatePrefix + '.EXPORT' | translate }}">
                <i class="fa-solid fa-file-export"></i>
            </button>
            <input type="number" style="width: 100px;" [min]="yearsMin" [max]="yearsMax" [(ngModel)]="yearSelected" class="form-control">
        </div>
    </div>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" 
        [getAllMethod]="getAllMethod" [search]="search" (deleteEvent)="setPayload($event); openModal(deleteSpeaker, 'delete-speaker-modal', '')" (editEvent)="openModal(createSpeaker, 'create-speaker-modal', 'xl', $event)"></app-universal-table>
</div>