<div id="pdf-container">
    <div id="pdf-invitation">
        <h2 class="fw-bold mb-5" style="width: 500px;">{{ translatePrefix + '.HEADER' | translate }}</h2>
        <div class="d-flex flex-row align-items-stretch">        
            <div class="d-flex flex-column flex-grow-1">            
                <div class="d-flex flex-column mb-5" style="margin-left: 4px;">
                    <div> {{ participation?.reportingOffice }} </div>
                    <div> {{ participation?.street }} </div>
                    <div> {{ participation?.zip }}&nbsp;{{ participation?.location }} </div>
                </div>
                <div class="d-flex flex-row mb-5" style="font-size: 10px; margin-left: 15px;">
                    <div class="flex-column flex-grow-0 flex-shrink-0 me-1" style="flex-basis: 20%;">
                        <div class="row fw-bold">
                            {{ translatePrefix + '.YOURFILENUMBER' | translate }}
                        </div>
                        <div class="row">
                            {{ seminar?.fileNumber }}
                        </div>
                        <div class="row">
                            {{ translatePrefix + '.PLEASESPECIFY' | translate }}
                        </div>
                    </div>
                    <!--<div class="flex-column flex-fill">
                        <div class="row fw-bold">
                            {{ translatePrefix + '.YOURLETTER' | translate }}
                        </div>
                        <div class="row"></div>
                        <div class="row"></div>
                    </div>-->
                    <div class="flex-column flex-grow-0 flex-shrink-0 me-1" style="flex-basis: 60%">
                        <div class="row fw-bold">
                            {{ translatePrefix + '.CONTACT' | translate }}
                        </div>
                        <div class="row">
                            {{ participation?.editUserSalutationId | salutation }}&nbsp;{{ participation?.editUserFirstName }}&nbsp;{{ participation?.editUserLastName }}
                        </div>
                        <div class="row">
                            {{ participation?.editUserEmail }}
                        </div>
                    </div>
                    <div class="flex-column flex-grow-0 flex-shrink-0 pe-1" style="flex-basis: 15%">
                        <div class="row fw-bold">
                            {{ translatePrefix + '.PHONEFAX' | translate }}</div>
                        <div class="row">
                            {{ translatePrefix + '.PHONEFAXPREFIX' | translate }}{{ participation?.editUserDirectDialing }}
                        </div>
                        <div class="row">
                            {{ translatePrefix + '.PHONEFAXPREFIX' | translate }}{{ participation?.editUserDirectDialing }}
                        </div>
                    </div>
                </div>
                <div class="mb-5" style="font-size: 13px;">
                    <div class="fw-bold"> 
                        {{ translatePrefix + '.LINE1' | translate }}
                    </div>
                    <div class="fw-bold">
                        {{ meeting?.startDate | datediff: meeting?.endDate }}{{ translatePrefix + '.LINE2' | translate }}&nbsp;&quot;{{ seminar?.name }}&quot;&nbsp;{{ meeting?.startDate | date: 'dd.MM.yyyy' }}&nbsp;-&nbsp;{{ meeting?.endDate | date: 'dd.MM.yyyy' }}&nbsp;{{ translatePrefix + '.IN' | translate }}&nbsp;{{ conferenceVenue?.location }}
                    </div>
                    <div class="fw-bold" >
                        {{ translatePrefix + '.LINE3' | translate }}&nbsp;{{ seminar?.year }}&nbsp;{{ translatePrefix + '.PAGE' | translate }}&nbsp;{{ seminar?.pageJfp }}
                    </div>
                </div>
                <div class="mb-3">
                    {{ translatePrefix + '.SALUTATION' | translate }}
                </div>            
                <div class="mb-3" [innerHTML]="meeting?.invitation"></div>
                <div>
                    {{ translatePrefix + '.COMPLIMENTARYCLOSE' | translate }}
                </div>
                <div class="mb-3">
                    {{ translatePrefix + '.ORDER' | translate }}
                </div>
                <div class="mb-4">{{ participation?.signiture }}</div>
                <div>
                    {{ translatePrefix + '.ATTACHMENT' | translate }}
                </div>
                <div>
                    {{ translatePrefix + '.SEMINARDOCUMENTS' | translate }}
                </div>
            </div>
            <div class="d-flex flex-column text-nowrap" style="font-size: 10px;">
                <div>
                    {{ translatePrefix + '.ADRESSLINE1' | translate }}
                </div>
                <div>
                    {{ translatePrefix + '.ADRESSLINE2' | translate }}
                </div>
                <div>
                    {{ translatePrefix + '.ADRESSLINE3' | translate }}
                </div>
                <div>
                    {{ translatePrefix + '.ADRESSLINE4' | translate }}
                </div>
                <div>
                    {{ translatePrefix + '.ADRESSLINE5' | translate }}
                </div>
                <div class="mb-3">
                    {{ translatePrefix + '.ADRESSLINE6' | translate }}
                </div>
                <div>
                    {{ today | date: 'd. LLLL yyyy' }}
                </div>
            </div>
        </div>
    </div>
</div>