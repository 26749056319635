import { Pipe, PipeTransform } from "@angular/core";
import { PermissionService } from "../services/permission.service";

@Pipe({
    name: 'checkRole'
})
export class RolePipe implements PipeTransform {

    constructor(private permissionService: PermissionService) { }

    transform(roleName: string, role: string) {
        return this.permissionService.isRole(role);
    }
}