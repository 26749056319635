import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-pdf',
  styleUrl: './list-pdf.component.scss',
  templateUrl: './list-pdf.component.html',
})
export class ListPdfComponent {
  @Input() translatePrefix: string = '';
  @Input() list: any[] = [];
  @Input() types: string[] = [];
  @Input() property2: string[] = [];
  @Input() propertyNames: string[][] = [];
  @Input() translates: string[] = [];
  @Input() year?: number | null;
  @Input() subHeading: string[] = [];
    
  constructor() { }

  getProperty(obj: any, property: string) {
    if(!obj) {
      return null;
    }
    return obj[property];
  }
}