<ng-template #createReportingOffice let-modal>
    <app-create-reporting-office-modal [translatePrefix]=" translatePrefix + '.MODAL'" [modal]="modal" [user]="selectedUser"></app-create-reporting-office-modal>
</ng-template>

<ng-template #deleteReportingOffice let-modal>
    <app-generic-modal [translatePrefix]="'DELETE.REPORTING_OFFICE'" [modal]="modal" [payload]="payload" [method]="deleteMethod"></app-generic-modal>
</ng-template>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>
    <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)"></app-filter-menu>
    <button type="submit" class="btn btn-primary my-3" style="width: 100px;" (click)="openModal(createReportingOffice, 'create-reporting-office-modal', 'xl')" placement="bottom" ngbTooltip="{{ translatePrefix + '.ADD' | translate }}">
        <i class="fa-solid fa-building-user"></i>&nbsp;
        <i class="fa-solid fa-plus"></i>
    </button>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" 
        [getAllMethod]="getAllMethod" [search]="search" (deleteEvent)="setPayload($event); openModal(deleteReportingOffice, 'delete-reporting-office-modal', '')" (editEvent)="openModal(createReportingOffice, 'create-seminar-modal', 'xl', $event)"></app-universal-table>
</div>