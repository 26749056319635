<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ translatePrefix + '.HEADER' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body" *ngIf="meeting">
        <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
            <div class="d-flex">
                <i class="fa-solid fa-xmark mt-1 me-2"></i>
                {{ 'ERRORCODE.' + errorCode | translate }}
            </div>
        </div>
        <textarea class="form-control" id="info" name="info" rows="4" [(ngModel)]="meeting.invoiceInfo"></textarea>
    </div>
    <div class="modal-footer">
        <div class="d-flex w-100 justify-content-end">
            <button class="btn btn-secondary me-1" (click)="submit()" [disabled]="isLoading || isError">
                {{ translatePrefix + '.BUTTONS.SAVE' | translate }}
                <i *ngIf="isLoading" class="fa-solid fa-circle-notch fa-spin"></i>
            </button>
            <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-container>