<div class="d-flex flex-column align-items-start bg-secondary navbar">
    <div class="w-100 d-flex bg-primary header">
        <div class="container d-flex flex-row justify-content-between">
            <ng-container *ngIf="role | checkRole : adminRole">
                <button *ngIf="!hideUi" class="btn btn-primary menu-button me-2" (click)="open()"> 
                    <img class="h-auto" src="./assets/svg/menu.svg" width="auto" height="auto" >
                </button>
            </ng-container>
            <ng-container *ngIf="role | checkRole : participantRole">
                <button class="btn btn-primary menu-button me-2" [routerLink]="registerPath"> 
                    <i class="fa-solid fa-chalkboard"></i>
                </button>
            </ng-container>
            <div *ngIf="!hideUi" class="d-flex">
                <div ngbDropdown container="body" display="dynamic" placement="bottom-end">
                    <button ngbDropdownToggle id="userCardDropdown" type="button" class="btn btn-primary pt-2"> 
                        <i class="fa-regular fa-address-card pe-1"></i>
                    </button>
                    <div ngbDropdownMenu *ngIf="currentUser" class="dropdown-menu" style="min-width: 325px;">
                        <div class="d-flex flex-row m-3">
                            <i class="fa-solid fa-user me-2 pt-1"></i>
                            <div class="flex-nowrap text-truncate d-block" [title]="currentUser!.userName">{{ currentUser!.userName }}</div>
                        </div>
                        <div class="d-flex flex-row mt-3 mx-3 pb-3 border-bottom">
                            <i class="fa-solid fa-envelope me-2 pt-1"></i>
                            <div class="flex-nowrap text-truncate d-block" [title]="currentUser!.eMail">{{ currentUser!.eMail }}</div>
                        </div>
                        <div class="d-flex mx-3 mt-3">
                            <button class="btn btn-primary w-100" (click)="logout()">{{ translatePrefix + '.BUTTONS.LOGOUT' | translate }}</button>
                        </div>
                        <a [routerLink]="changePassword" class="mt-1 mx-3">{{ translatePrefix + '.PASSWORD' | translate }}</a>
                    </div>
                </div>
                <button class="btn btn-primary menu-button" (click)="logout()"> 
                    <i class="fa-solid fa-arrow-right-from-bracket"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="d-flex container mb-4">
        <img src="./assets/svg/mdi_logo.svg" id="headerImg" name="headerImg" width="225px" height="auto" [routerLink]="homePath">
        <h3 id="headerText" name="headerText">{{ 'MAINHEADER' | translate }}</h3>
    </div>
</div>