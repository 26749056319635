import { PageableFilter } from "./pageable-filter.model";

export class UserFilter extends PageableFilter {
    firstName?: string | null = null;
    lastName?: string | null = null;
    eMail?: string | null = null;
    roleName?: string | null = null;
    creationFrom?: Date | null = null;
    creationTo?: Date | null = null;
    lastLoginFrom?: Date | null = null;
    lastLoginTo?: Date |null = null;
}