import { PageableFilter } from "./pageable-filter.model";

export class ConferenceVenueFilter extends PageableFilter {
    name?: string | null = null;
    street?: string | null = null;
    location?: string | null = null;
    phone?: string | null = null;
    fax?: string | null = null;
    year?: number | null = null;

    constructor(page: number, pageSize: number, orderColumn?: string | null, orderDirection?: string | null, name: string | null = null, street: string | null = null, 
        location: string | null = null, phone: string | null = null, fax: string | null = null, year?: number | null ) {
        super(page, pageSize, orderColumn, orderDirection);
        this.name = name;
        this.street = street;
        this.location = location;
        this.phone = phone;
        this.fax = fax;
        this.year = year;
    }
}