import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class RefreshService {
    private refreshSubject: Subject<any> = new Subject();
    refresh: Observable<any> = this.refreshSubject.asObservable();

    constructor() { }

    emit(value: any) {
        this.refreshSubject.next(value);
    }
}