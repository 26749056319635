<ng-template #delete let-modal>
    <app-generic-modal [translatePrefix]="'DELETE.UNIVERSAL'" [modal]="modal" [payload]="payload" [method]="deleteMethod" [diffServerErrorMessage]="diffServerErrorMessage"></app-generic-modal>
</ng-template>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3 d-flex align-items-center">
            <h4 class="me-2">{{ translatePrefix + '.HEADER' | translate }}</h4>
            <i class="fa-solid fa-circle-info text-red opacity-50" placement="end" ngbTooltip="{{ 'EDIT.INFO' | translate }}"></i>
        </div>
    </header>
    <app-universal-edit-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [tableContent]="tableContent" [isLoading]="isLoading" [search]="search" 
        [varKeys]="varKeys" [isError]="isError" [errorCode]="errorCode" [orderDirection]="orderDirection" (addEvent)="add($event)" (editEvent)="edit($event)" 
        (delEvent)="setPayload($event); openModal(delete, 'delete-modal', '')" (filterEvent)="filter($event)"></app-universal-edit-table>
</div>