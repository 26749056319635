import { PageableFilter } from "./pageable-filter.model";

export class ParticipationFilter extends PageableFilter {
    seminarName?: string | null  = '';
    firstName?: string | null  = '';
    lastName?: string | null = '';
    info?: string | null = '';

    constructor(page: number, pageSize: number, orderColumn?: string | null, orderDirection?: string | null, firstName?: string | null, 
        lastName?: string | null, seminarName?: string | null, info?: string | null) {
        super(page, pageSize, orderColumn, orderDirection);
        this.firstName = firstName;
        this.lastName = lastName;
        this.seminarName = seminarName;
        this.info = info;
    }
}