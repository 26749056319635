<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ translatePrefix + '.HEADER' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <p class="mb-5">{{ translatePrefix + '.INFO' | translate }}</p>
        <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
            <div class="d-flex">
                <i class="fa-solid fa-xmark mt-1 me-2"></i>
                {{ 'ERRORCODE.' + errorCode | translate }}
            </div>
        </div>
        <div *ngIf="isSend" class="mb-4 alert alert-info" role="alert">
            <div class="d-flex">
                <ng-container *ngIf="restMethod !== 'confirmAndCancellSignups'">
                    {{ translatePrefix + '.MAILSEND' | translate }}&nbsp;{{ participation!.participantEmail }}
                </ng-container>
                <ng-container *ngIf="restMethod === 'confirmAndCancellSignups'">
                    {{ translatePrefix + '.MAILSEND' | translate }}
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="(restMethod === 'confirmSignUp' || restMethod === 'confirmAndCancellSignups') && meeting && conferenceVenue && !isSend && !isLoading">
            <div class="mb-3">
                <label for="tinyEmail" class="mb-1">{{ translatePrefix + '.EMAIL' | translate }}</label>
                <editor [init]="tinyConfig" name="tinyEmail" [(ngModel)]="meeting!.invitation"></editor>
            </div>
            <div>
                <label for="tinyNotice" class="mb-1">{{ translatePrefix + '.NOTICE' | translate }}</label>
                <editor [init]="tinyConfig" name="tinyNotice" [(ngModel)]="conferenceVenue!.notice"></editor>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary me-1" (click)="submit()" [disabled]="isLoading || isError || validate() || isSend">
            {{ translatePrefix + '.BUTTONS.SEND' | translate }}
            <i *ngIf="isLoading" class="fa-solid fa-circle-notch fa-spin"></i>
        </button>
        <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
    </div>
</ng-container>