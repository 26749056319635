import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as GLOBALS from "../../globals";
import { FormService } from 'src/app/services/form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nav-bar',
  styleUrl: './nav-bar.component.scss',
  templateUrl: './nav-bar.component.html'
})
export class NavBarComponent implements OnInit, OnDestroy {
  translatePrefix: string = 'NAVIGATIONBAR.ITEMS';
  adminRoutes : string[][] = GLOBALS.adminRoutes;
  homePath: string = '/' + GLOBALS.routeParameterHome;
  signUps?: number = 0;
  formSubscription?: Subscription;

  @Output() openMenuEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(private formService: FormService) { }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.formSubscription = this.formService.refresh.subscribe({
      next: (res: boolean) => {
        if(!res) {
          this.signUps = this.formService.data?.unconfirmedSignUps;
        }
      }
    });
    
    this.formService.updateData();
  }

  close() {
    this.openMenuEvent.emit(false)
  }
}