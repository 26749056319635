import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { UiControlService } from './services/uiControl.service';
import { FormService } from './services/form.service';
import { AuthService } from './services/auth.service';
import { Subscription } from 'rxjs';
import { PermissionService } from './services/permission.service';

@Component({
  selector: 'app-root',
  styleUrl: './app.component.scss',
  templateUrl: './app.component.html',
  animations: [
    trigger('slideMenu',  [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ]),
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-out')
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ opacity: 0 }))
      ]),
    ])
  ]
}) export class AppComponent implements OnInit, OnDestroy {
  title: string = 'app'
  triggerAnimation?: boolean;
  hideUi?: boolean;
  routerSubscription?: Subscription;
  uiSubscription?: Subscription;
  permissionSubscription?: Subscription;
  isLoadingPermission: boolean = false;

  constructor(translate: TranslateService, private router: Router, private uiControl: UiControlService, 
    private formService: FormService, private authService: AuthService, private permissionService: PermissionService) {

    translate.setDefaultLang('de');
    translate.use('de');

    this.permissionSubscription = this.permissionService.initEvent.subscribe((event: boolean) => {
      this.isLoadingPermission = event;
    });

    this.permissionService.initAuthInfo();
    this.authService.info();
    
    this.routerSubscription = this.router.events.subscribe((event: any) => {
      if(event instanceof NavigationEnd) {
        this.hideUi = this.uiControl.checkVisibility();
      }
    });

    this.uiSubscription = this.uiControl.uiEvent.subscribe((event: boolean) => {
      this.hideUi = event;
    });
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
    this.uiSubscription?.unsubscribe();
    this.permissionSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.formService.updateData();
  }

  open(event: any) {
    this.triggerAnimation = event;
  }
}