import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { ChildNode } from 'src/app/models/child-node.model';
import { StatisticsFilter } from 'src/app/models/statistics-filter.model';
import { AuthService } from 'src/app/services/auth.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-statistic',
  styleUrl: './statistic.component.scss',
  templateUrl: './statistic.component.html',
})
export class StatisticComponent implements OnInit {
  translatePrefix: string = 'FORMS.STATISTIC';
  columnsFilterMenu: string[] = ['dateFrom', 'dateTo', 'areaId', 'categoryId', 'organisationId'];
  filtersFilterMenu: string[] = ['date', 'date', 'area', 'categorie', 'organisation'];
  search?: StatisticsFilter;
  isLoadingSummary: boolean = false;
  isErrorSummary: boolean = false;
  isLoadingInfo: boolean = false;
  isErrorInfo: boolean = false;
  errorCode: string = '';
  summaryData?: ChildNode;
  infoData?: any[]; 
  summaryStatus?: string;
  filterYear?: number = this.getCurrentYear();

  constructor(private restService: RestService, private authService: AuthService) { }

  ngOnInit() {
    let date1 = new Date('2024-01-01').setFullYear(this.getCurrentYear());
    let date2 = new Date('2024-12-31').setFullYear(this.getCurrentYear());
    
    this.search = new StatisticsFilter(1, 25, null, null, new Date(date2).toISOString().split('T')[0], new Date(date1).toISOString().split('T')[0]);
    this.update();
  }

  resetFilter() {
    this.filterYear = this.getCurrentYear();
    this.search = new StatisticsFilter(1, 25, null, null, null, null, null, null, null);
  }

  setSearch(event: StatisticsFilter) {
    this.search = new StatisticsFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.dateTo, event.dateFrom, event.areaId, event.categoryId, event.organisationId);
    this.update();
  }

  setYear(event: number) {
    this.filterYear = event;

    this.search!.dateFrom = new Date(new Date(this.search!.dateFrom!).setFullYear(event)).toISOString().split('T')[0];
    this.search!.dateTo = new Date(new Date(this.search!.dateTo!).setFullYear(event)).toISOString().split('T')[0];
    
    this.setSearch(this.search!);
  }

  setQuarter(event: [string, string]) {
    this.search!.dateFrom = new Date(new Date(event[0]).setFullYear(this.filterYear!)).toISOString().split('T')[0];
    this.search!.dateTo = new Date(new Date(event[1]).setFullYear(this.filterYear!)).toISOString().split('T')[0];
   
    this.setSearch(this.search!);
  }

  private getCurrentYear() {
    return new Date().getFullYear();
  }

  private update() {
    this.isLoadingSummary = true;

    this.restService.getStatisticsSummary(this.search!).pipe(
      finalize(() => {
        this.isLoadingSummary = false;
      })
    ).subscribe({
      next: res => {
        this.isErrorSummary = false;
        this.errorCode = '';
        this.summaryStatus = res.info;
        this.summaryData = JSON.parse(res.payload);
      }, error: err => {
        console.log(err);
        this.isErrorSummary = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });

    this.isLoadingInfo = true;

    this.restService.getStatisticsInfo(this.search!).pipe(
      finalize(() => {
        this.isLoadingInfo = false;
      })
    ).subscribe({
      next: res => {
        this.isErrorInfo = false;
        this.errorCode = '';
        this.infoData = JSON.parse(res.payload);
      }, error: err => {
        console.log(err);
        this.isErrorInfo = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });
  }

  getTooltipText(meeting: any) {
    let text: string = '';

    for(let career of meeting.CareerList) {
      text += career.name + ': ' + career.count + '; ';
    }

    for(let status of meeting.StatusList) {
      text += status.name + ': ' + status.count + '; ';
    }

    for(let salutation of meeting.SalutationList) {
      text += salutation.name + ': ' + salutation.count + '; ';
    }

    return text;
  }
}