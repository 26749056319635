import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetQuery } from 'src/app/models/get-query.model';
import { ParticipantFilter } from 'src/app/models/participant-filter.model';
import { Participant } from 'src/app/models/participant.model';
import * as GLOBALS from "../../globals";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-participant',
  styleUrl: './participant.component.scss',
  templateUrl: './participant.component.html',
})
export class ParticipantComponent implements OnInit, OnDestroy {
  @Input() translatePrefix: string = 'PARTICIPANT';
  columns: string[] = ['firstName', 'lastName'];
  buttons: string[] = ['editBtn', 'delBtn'];
  filters: string[] = ['text', 'text'];
  columnsFilterMenu: string[] = ['firstName', 'lastName'];
  filtersFilterMenu: string[] = ['text', 'text'];
  getAllMethod: string = 'allParticipants';
  deleteMethod: string = 'deleteParticipant';
  payload?: GetQuery;
  search?: ParticipantFilter = new ParticipantFilter (1, 25, 'fileNumber', 'asc');
  selectedParticipant?: Participant;
  seminarSelected: string = '';
  registerPath: string = '/' + GLOBALS.routeParameterSeminarRegistration;
  meetingId: string = '';
  seminarname: string = '';
  routeSubscription?: Subscription;
    
  constructor(private modalService: NgbModal, private route: ActivatedRoute) { }
  
  ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
  }

  ngOnInit() { 
    this.routeSubscription = this.route.params.subscribe( params => {
      if(params['meetingId']) {
        this.meetingId = params['meetingId']
      }
      if(params['seminarname']) {
        this.seminarname = params['seminarname']
      }
    });

    if(this.meetingId != '') {
      this.buttons.push('addParticipantBtn');
      this.seminarSelected = this.seminarname;
      this.search = new ParticipantFilter(1, 25, 'fileNumber', null, null, null, null, this.meetingId);
    } else {
      this.resetFilter();
      this.seminarSelected = '';
    }
  }

  openModal(modal: any, ariaLabelledBy: string, size: string, participant?: Participant) {
    this.selectedParticipant = participant;
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }

  resetFilter() {
    this.search = new ParticipantFilter(1, 25, 'fileNumber', null);
  }

  setSearch(event: ParticipantFilter) {
    this.search = new ParticipantFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.firstName, event.lastName, event.eMail);
  }

  setPayload(event: Participant) {
    this.payload = new GetQuery();
    this.payload.keyMap.set('uniqueIdentifier', event.participantId??'');
  }
}