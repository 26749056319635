export class Department {
    departmentId?: string = '';
    shorthand?: string = '';
    name?: string = '';
    street?: string = '';
    zip?: string = '';
    location?: string = '';

    constructor(departmentId?: string, shorthand?: string, name?: string, street?: string, zip?: string, location?: string) {
        this.departmentId = departmentId;
        this.shorthand = shorthand;
        this.name = name;
        this.street = street;
        this.zip = zip;
        this.location = location;
    }
}