import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, finalize } from 'rxjs';
import { Participation } from 'src/app/models/participation.model';
import { AuthService } from 'src/app/services/auth.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../../globals";
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { ParticipantConfirmation, ParticipationsAll } from 'src/app/models/participant-confirmation.model';
import { Meeting } from 'src/app/models/meeting.model';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-participation-accept-modal',
  styleUrl: './participation-accept-modal.component.scss',
  templateUrl: './participation-accept-modal.component.html',
})
export class ParticipationAcceptModalComponent implements OnInit, OnDestroy {
  @Input() modal?: NgbActiveModal;
  @Input() translatePrefix: string = '';
  @Input() restMethod: string = '';
  @Input() participation?: Participation;
  @Input() meeting?: Meeting;
  @Input() conferenceVenue?: ConferenceVenue;
  @Input() isSendAll: boolean = false;
  @Input() sendAllParticipations: Participation[] = [];
  isLoading: boolean = false;
  isSend: boolean = false;
  isError: boolean = false;
  errorCode: string = '';  
  tinyConfig = GLOBALS.tinyConfig;
  pdfSubscription?: Subscription;
  signUp: any;
    
  constructor(private restService: RestService, private refreshService: RefreshService, 
    private authService: AuthService, private pdfService: PdfService, private ref: ChangeDetectorRef) { 

  }

  ngOnDestroy() {
    this.pdfService.clear();
    this.pdfSubscription?.unsubscribe();
  }

  ngOnInit() { 
    this.pdfSubscription = this.pdfService.printCompleted.subscribe({
      next: (res: boolean) => {
        if(!res) {
          this.signUp.append('file', this.pdfService.savedFiles[0]); // ConferenceVenueNotice.pdf

          if(!this.isSendAll) {
            this.signUp.append('file', this.pdfService.savedFiles[1]); // MeetingInvitation.pdf
          } else {
            for(let i = 1; i < this.pdfService.savedFiles.length; i++) {
              this.signUp.append('file', this.pdfService.savedFiles[i]); // MeetingInvitation_{id}.pdf
            }
          }
          this.pdfService.clear();
          this.submitData();
        }
      }
    });
  }

  submit() {
    this.isLoading = true;

    this.pdfService.print('ConferenceVenueNotice.pdf', 'pdf-conference-notice', undefined, undefined, true, true, true, true);
    
    if(!this.isSendAll) this.sendSingle();
    else this.sendMultiples();
  }

  private sendSingle() {
    if(this.checkParticipation(this.participation)) {
      if(this.restMethod !== 'confirmSignUp') {
        this.signUp = new ParticipantConfirmation(this.participation?.meetingId!, this.participation?.participantId!, this.participation?.participantEmail!, this.meeting?.invitation!, this.conferenceVenue?.conferenceVenueId!, this.conferenceVenue?.notice!);
      } else {
        this.signUp = new FormData();
        this.signUp.append('data', JSON.stringify(new ParticipantConfirmation(this.participation?.meetingId!, this.participation?.participantId!, this.participation?.participantEmail!, this.meeting?.invitation!, this.conferenceVenue?.conferenceVenueId!, this.conferenceVenue?.notice!)));
      }

      if(this.restMethod !== 'confirmSignUp') this.submitData(); // reject Signup
      else this.pdfService.print('MeetingInvitation.pdf', 'pdf-invitation', undefined, undefined, true, true, true); // accept Signup
    }
  }

  private sendMultiples() {
    if(this.sendAllParticipations?.length === 0) return;
    
    this.signUp = new FormData();
    this.signUp.append('data', JSON.stringify(new ParticipationsAll(this.meeting?.meetingId!, this.conferenceVenue?.conferenceVenueId!, this.meeting?.invitation!, this.conferenceVenue?.notice!)));

    this.sendAllParticipations.forEach((participation: Participation, index: number, array: Participation[]) => {
      if(this.checkParticipation(participation)) { 
        this.pdfService.print('MeetingInvitation_' + participation.participantId + '.pdf', 'pdf-invitation', undefined, undefined, true, true, true, index + 1 !== array.length ? true : false);
      }
    });
  }

  private checkParticipation(participation?: Participation): boolean {
    if(participation && participation.meetingId && participation.participantId && this.meeting?.invitation && this.conferenceVenue?.conferenceVenueId && this.conferenceVenue.notice) return true;
    else return false;
  }

  private submitData() {
    (this.restService as any)[this.restMethod](this.signUp).pipe(
      finalize(() => {
        this.isLoading = false;
        if(this.restMethod === 'confirmSignUp' || this.restMethod === 'confirmAndCancellSignups') this.ref.detectChanges();
      })
    ).subscribe(
      (response: any) => {
        this.isError = false;
        this.errorCode = '';
        this.isSend = true;
        this.refreshService.emit(true);
      },
      (error: any) => {
        console.log(error)
        this.isError = true;
        this.errorCode = String(error.status);
        this.authService.checkAuth(error.status);
      }
    );
  }

  validate() {
    if(this.restMethod !== 'confirmSignUp') (!this.conferenceVenue?.notice || !this.meeting?.invitation);
    return false;
  }
}