export class GetQuery {
    keyMap: Map<string, number | string>;

    constructor() {
        this.keyMap = new Map<string, number | string>();
    }

    toQuery(): string {
        let query = "";
        for(let [key, value] of this.keyMap) {
            query += query ? "&" : "?";
            query += key + "=" + encodeURIComponent(value);
        }
        return query;
    }
}