export class Meeting {
    meetingId?: string = '';
    seminarId?: string = '';
    shorthand?: string = '';
    series?: string = '';
    startDate?: Date;
    endDate?: Date;
    overnightStays?: number = 0;
    externalReportingDate?: Date;
    internalReportingDate?: Date;
    documentDispatchDate?: Date;
    invoiceDate?: Date;
    dueDate?: Date;
    signedParticipants?: number = 0;
    expectedParticipants?: number = 0;
    conferenceVenueId?: string = '';
    media?: string = '';
    userId?: string = '';
    userSignId?: string = '';
    speakerIds?: string[] = [];
    invitation?: string = '';
    invoiceInfo?: string = '';

    selected?: boolean = false;

    constructor(meetingId?: string, seminarId?: string, shorthand?: string, series?: string, startDate?: Date, endDate?: Date, overnightStays?: number, externalReportingDate?: Date,
        internalReportingDate?: Date, documentDispatchDate?: Date, invoiceDate?: Date, dueDate?: Date, signedParticipants?: number, expectedParticipants?: number, conferenceVenueId?: string,
        media?: string, userId?: string, userSignId?: string, speakerIds?: string[],invitation?: string, invoiceInfo?: string) {
        
        this.meetingId = meetingId;
        this.seminarId = seminarId;
        this.shorthand = shorthand;
        this.series = series;
        this.startDate = startDate;
        this.endDate = endDate;
        this.overnightStays = overnightStays;
        this.externalReportingDate = externalReportingDate;
        this.internalReportingDate = internalReportingDate;
        this.documentDispatchDate = documentDispatchDate;
        this.invoiceDate = invoiceDate;
        this.dueDate = dueDate;
        this.signedParticipants = signedParticipants;
        this.expectedParticipants = expectedParticipants;
        this.conferenceVenueId = conferenceVenueId;
        this.media = media;
        this.userId = userId;
        this.userSignId = userSignId;
        this.speakerIds = speakerIds;
        this.invitation = invitation;
        this.invoiceInfo = invoiceInfo;
    }
}