<ng-container *ngIf="modal && participant">
    <div class="modal-header">
        <h4 class="modal-title">{{ participant.hasSignUp === 0 ? (translatePrefix + '.HEADER' | translate) : (translatePrefix + '.HEADER2' | translate) }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <div *ngIf="participant.hasSignUp && participant.hasSignUp > 0" class="mb-4 alert alert-info" role="alert">
            {{ translatePrefix + '.REGISTERED' | translate }}
        </div>
        <div *ngIf="isError" class="mb-4 alert" [ngClass]="errorCode === 'REGISTERED' ? 'alert-info': 'alert-danger'" role="alert">
            <div class="d-flex">
                <i class="fa-solid fa-xmark mt-1 me-2" [ngClass]="{'d-none': errorCode === 'REGISTERED'}"></i>
                {{ 'ERRORCODE.' + errorCode | translate }}
            </div>
        </div>
        <div *ngIf="isLoadingSignUp" class="col d-flex justify-content-center mt-4">
            <i class="fa-solid fa-circle-notch fa-spin fa-2xl"></i>
        </div>
        <p *ngIf="participant.hasSignUp === null || participant.hasSignUp === 0" class="mb-4">{{ translatePrefix + '.REGISTER' | translate }}</p>
        <div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="position" class="mb-1">{{ translatePrefix + '.POSITION' | translate }}</label>
                    <input type="number" class="form-control" id="position" name="position" [(ngModel)]="meetingSignUp.position" [ngClass]="{'required-input': !meetingSignUp.position}" required>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="department" class="mb-1">{{ translatePrefix + '.DEPARTMENT' | translate }}</label>
                    <select class="form-select" id="department" name="department" [(ngModel)]="meetingSignUp.departmentId" [ngClass]="{'required-input': !meetingSignUp.departmentId}" required>
                        <option [value]="''"></option>
                        <ng-container *ngIf="departments && departments.length != 0">
                            <option *ngFor="let department of departments" class="form-option" [value]="department.departmentId">{{ department.name }}</option>
                        </ng-container>
                        <option *ngIf="departments && departments.length == 0" class="form-option">{{ translatePrefix + '.NODEPARTMENT' | translate }}</option>
                        <option *ngIf="isErrorDepartments" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                    </select>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="careerId" class="mb-1">{{ translatePrefix + '.CAREER' | translate }}</label>
                    <select class="form-select" id="careerId" name="careerId" [(ngModel)]="meetingSignUp.careerId" [ngClass]="{'required-input': !meetingSignUp.careerId}" required>
                        <ng-container *ngIf="!isLoadingForm && !isErrorForm">
                            <option [value]="''"></option>
                            <option *ngFor="let career of careers" class="form-option" [value]="career.careerId">{{ career.careerValue }}</option>
                        </ng-container>
                        <ng-container *ngIf="isLoadingForm || isErrorForm">
                            <option *ngIf="isLoadingForm" class="form-option">{{ translatePrefix + '.NOCAREER' | translate }}</option>
                            <option *ngIf="isErrorForm" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="statusId" class="mb-1">{{ translatePrefix + '.STATUS' | translate }}</label>
                    <select class="form-select" id="statusId" name="statusId" [(ngModel)]="meetingSignUp.statusId" [ngClass]="{'required-input': !meetingSignUp.statusId}" required>
                        <ng-container *ngIf="!isLoadingForm && !isErrorForm">
                            <option [value]="''"></option>
                            <option *ngFor="let status of statuses" class="form-option" [value]="status.statusId">{{ status.statusValue }}</option>
                        </ng-container>
                        <ng-container *ngIf="isLoadingForm || isErrorForm">
                            <option *ngIf="isLoadingForm" class="form-option">{{ translatePrefix + '.NOSTATUS' | translate }}</option>
                            <option *ngIf="isErrorForm" class="form-option">{{ translatePrefix + '.ERROR' | translate }}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <div class="form-check-label mb-1">{{ translatePrefix + '.PARTTIME' | translate }}</div>
                    <div class="d-flex">
                        <div class="form-check me-3">
                            <label for="parttime_yes" class="form-check-label mb-1">{{ translatePrefix + '.YES' | translate }}</label>
                            <input type="radio" class="form-check-input" id="parttime_yes" name="parttime_yes" [checked]="meetingSignUp.partTime" (click)="setProperty(meetingSignUp, 'partTime', true)" required>
                        </div>
                        <div class="form-check">
                            <label for="parttime_no" class="form-check-label mb-1">{{ translatePrefix + '.NO' | translate }}</label>
                            <input type="radio" class="form-check-input" id="parttime_no" name="parttime_no" [checked]="!meetingSignUp.partTime" (click)="setProperty(meetingSignUp, 'partTime', false)" required>
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label class="form-check-label mb-1">{{ translatePrefix + '.OVERNIGHTSTAY' | translate }}</label>
                    <div class="d-flex">
                        <div class="form-check me-3">
                            <label for="overnight_yes" class="form-check-label mb-1">{{ translatePrefix + '.YES' | translate }}</label>
                            <input type="radio" class="form-check-input" id="overnight_yes" name="overnight_yes" [checked]="meetingSignUp.overnightStay" (click)="setProperty(meetingSignUp, 'overnightStay', true)" required>
                        </div>
                        <div class="form-check">
                            <label for="overnight_no" class="form-check-label mb-1">{{ translatePrefix + '.NO' | translate }}</label>
                            <input type="radio" class="form-check-input" id="overnight_no" name="overnight_no" [checked]="!meetingSignUp.overnightStay" (click)="setProperty(meetingSignUp, 'overnightStay', false)" required>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!(role | checkRole : adminRole)">
                <div *ngIf="participant.hasSignUp && participant.hasSignUp > 0" class="form-group mb-3">
                    <label for="tinyReason" class="mb-1">{{ translatePrefix + '.REASON' | translate }}</label>
                    <editor [init]="tinyConfig" [(ngModel)]="meetingSignUp.reason" name="tinyReason" [ngClass]="{'required-input-tiny': !meetingSignUp.reason}"></editor>
                </div>
            </ng-container>

        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex w-100 justify-content-end">
            <button class="btn btn-secondary me-1" (click)="submit()" [disabled]="isLoading || isLoadingSignUp || validate()">
                {{ participant.hasSignUp === 0 ? (translatePrefix + '.BUTTONS.SAVE' | translate) : (translatePrefix + '.BUTTONS.CHANGE' | translate) }}
                <i *ngIf="isLoading" class="fa-solid fa-circle-notch fa-spin"></i>
            </button>
            <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-container>