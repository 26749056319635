import { Component, OnInit } from '@angular/core';
import * as GLOBALS from "../../globals";

@Component({
  selector: 'app-home',
  styleUrl: './home.component.scss',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit { 
  translatePrefix: string = 'NAVIGATIONBAR.ITEMS';
  adminRoutes : string[][] = GLOBALS.adminRoutes;

  constructor() { }

  ngOnInit() { }
}