import { Meeting } from "./meeting.model";
import { Participation } from "./participation.model";
import { Seminar } from "./seminar.model";

export class ParticipationCertification {
    participationCertInfo: [Participation, Meeting, Seminar];

    constructor(participation: Participation, meeting: Meeting, seminar: Seminar) {
        this.participationCertInfo = [participation, meeting, seminar];
    }
}