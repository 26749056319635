import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'replaceEmpty'
})
export class ReplaceEmptyPipe implements PipeTransform {
    transform(input: string | number | null | undefined , replacement?: string): string | number {
        if((!input || !input.toString()) && replacement) {
            return replacement;
        } else if((!input || !input.toString()) && !replacement) {
            return "-";
        } else {
            return input!;
        }
    }
}