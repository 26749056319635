import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as GLOBALS from "../../globals";
import { GetQuery } from 'src/app/models/get-query.model';
import { SpeakerFilter } from 'src/app/models/speaker-filter.model';
import { Speaker } from 'src/app/models/speaker.model';
import { RestService } from 'src/app/services/rest.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SpeakerResult } from 'src/app/models/speaker-result.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-speaker',
  styleUrl: './speaker.component.scss',
  templateUrl: './speaker.component.html',
})
export class SpeakerComponent implements OnInit { 
  translatePrefix: string = 'FORMS.SPEAKER';
  year: number = GLOBALS.yearsMin;
  yearsMin: number = GLOBALS.yearsMin;
  columns: string[] = ['firstName', 'lastName', 'workLocation', 'serviceAuthority'];
  buttons: string[] = ['editBtn', 'delBtn'];
  filters: string[] = ['text', 'text', 'text', 'text'];
  columnsFilterMenu: string[] = ['academicTitle', 'firstName', 'lastName', 'workLocation', 'serviceAuthority'];
  filtersFilterMenu: string[] = ['text', 'text', 'text', 'text', 'text'];
  getAllMethod: string = 'allSpeakers';
  deleteMethod: string = 'deleteSpeaker';
  payload?: GetQuery;
  selectedSpeaker?: Speaker;
  search?: SpeakerFilter = new SpeakerFilter(1, 25, 'firstName', 'asc');
  isError: boolean = false;
  errorCode: string = '';

  // print
  isLoadingPrint: boolean = false;
  yearsMax: number | null = new Date().getFullYear();
  speakerList: Speaker[] = [];
  yearSelected?: number | null = this.yearsMax;
  printPropertyNames: string[][] = [['academicTitle', 'firstName', 'lastName'], ['serviceAuthority'], ['workStreet'], ['workZip', 'workLocation'], ['workPhone'], ['workMail'], ['workWebsite'], ['privateStreet'], ['privateZip', 'privateLocation']];
  printTypes: string[] = ['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text'];
  printTranslates: string[] = ['', '', '', '', 'PHONE', 'EMAIL', 'WEBSITE', '', ''];
  subHeading: string[] = ['', 'SUBHEADER1', '', '', '', '', 'SUBHEADER2', '', '']

  constructor(private modalService: NgbModal, private restService: RestService, private pdfService: PdfService, private authService: AuthService) { }

  ngOnInit() {
    this.year = new Date().getFullYear();
  }

  openModal(modal: any, ariaLabelledBy: string, size: string, speaker?: Speaker) {
    this.selectedSpeaker = speaker;
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }

  resetFilter() {
    this.search = new SpeakerFilter(1, 25, 'firstName', null);
  }

  setSearch(event: SpeakerFilter) {
    this.search = new SpeakerFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.academicTitle, event.firstName, event.lastName, event.workLocation, event.serviceAuthority);
  }

  setPayload(event: Speaker) {
    this.payload = new GetQuery();
    this.payload.keyMap.set('uniqueIdentifier', event.speakerId??'');
  }

  print() {
    this.isLoadingPrint = true;

    this.restService.allSpeakers(new SpeakerFilter(1, 25, null, null, null, null, null, null, null, this.yearSelected)).subscribe({
      next: res => {
        this.speakerList = (res as SpeakerResult).result;
        this.errorCode = '';
        this.isError = false;
        this.pdfService.print('Referneten_' + this.yearSelected, 'pdf-list', undefined, true, true);
        this.isLoadingPrint = false;
      }, error: err => {
        console.log(err);
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
        this.isError = true;
      }
    });
  }
}