import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, finalize } from 'rxjs';
import { Area, Category } from 'src/app/models/form.model';
import { Seminar } from 'src/app/models/seminar.model';
import { AuthService } from 'src/app/services/auth.service';
import { FormService } from 'src/app/services/form.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../../globals";
import { SeminarFilter } from 'src/app/models/seminar-filter.model';
import { SeminarResult } from 'src/app/models/seminar-result.model';

@Component({
  selector: 'app-create-seminar-modal',
  styleUrl: './create-seminar-modal.component.scss',
  templateUrl: './create-seminar-modal.component.html'
})
export class CreateSeminarModalComponent implements OnInit, OnDestroy {
  @Input() translatePrefix?: string;
  @Input() modal?: NgbActiveModal;
  @Input() seminar?: Seminar;
  years: number[] = [];
  isLoadingForm: boolean = false;
  isErrorForm: boolean = false;
  categories?: Category[];
  areas?: Area[];
  restMethod: string = '';
  isError: boolean = false;
  isLoading: boolean = false;
  errorCode: string = '';
  formSubscription?: Subscription;
  seminarList: Seminar[] = [];
  isLoadingSeminar: boolean = false;
  isErrorSeminar: boolean = false;
  tinyConfig = GLOBALS.tinyConfig;
  
  constructor(private formService: FormService, private restService: RestService, private authService: AuthService, private refreshService: RefreshService) { }
  
  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  ngOnInit() {
    if(this.formService.data) {
      this.categories = this.formService.data.categories;
      this.areas = this.formService.data.areas;
    } else {
      this.formService.updateData();
      this.isLoadingForm = true;

      this.formSubscription = this.formService.refresh.subscribe({
        next: (res: boolean) => {
          this.isLoadingForm = res;
          if(!this.isLoadingForm) {
            this.categories = this.formService.data?.categories;
            this.isErrorForm = this.formService.isError;
          }
        }
      });
    }

    let currentYear = new Date().getFullYear();

    for(let i = currentYear + 2; i >= GLOBALS.yearsMin ; i--) {
      this.years.push(i);
    }

    this.restMethod = this.seminar == undefined ? 'addSeminar' : 'editSeminar';
    this.seminar = this.seminar == undefined ? new Seminar() : new Seminar(this.seminar.seminarId, this.seminar.areaId, this.seminar.name, this.seminar.categoryId, 
      this.seminar.fileNumber, this.seminar.fileIdentifier, this.seminar.pageJfp, this.seminar.participationFee, this.seminar.areaOfAuthorization, this.seminar.chapter, 
      this.seminar.title, this.seminar.shortDescription, this.seminar.targetAudience, this.seminar.gain, this.seminar.seminarContent, this.seminar.notice, this.seminar.expandsOnSid, currentYear, this.seminar.meetings);
    
    let search = new SeminarFilter(1, 25, '', '');
    this.restService.allSeminars(search).pipe(
      finalize(() => {
        this.isLoadingSeminar = false;
      })
    ).subscribe({
      next: (res:  SeminarResult)=> {
        this.seminarList = res.result;
        this.isErrorSeminar = false;
        this.errorCode = '';
      }, error: err => {
        this.isErrorSeminar = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });
  }
  
  submit() {
    this.isLoading = true;

    (this.restService as any)[this.restMethod](this.seminar).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.isError = false;
        this.errorCode = '';
        this.refreshService.emit(true);
        this.modal?.dismiss();
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = String(error.status);
        this.authService.checkAuth(error.status);
      }
    );
  }

  validate() {
    if(!this.seminar) return true;

    return !this.seminar.name || !this.seminar.year || !this.seminar.areaId || !this.seminar.categoryId || !this.seminar.participationFee || 
      !this.seminar.areaOfAuthorization || !this.seminar.chapter || !this.seminar.title || !this.seminar.shortDescription || !this.seminar.seminarContent;
  }
}