import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, catchError , debounceTime, distinctUntilChanged, merge, repeat, retry, switchMap, tap } from 'rxjs';
import { SimpleFilterTableComponent } from 'src/app/extendables/simple-filter-table.component';
import { ConferenceVenueResult } from 'src/app/models/conference-venue-result.model';
import { DepartmentResult } from 'src/app/models/department-result.model';
import { NextMeeting } from 'src/app/models/next-meeting.model';
import { SeminarResult } from 'src/app/models/seminar-result.model';
import { SpeakerResult } from 'src/app/models/speaker-result.model';
import { UserResult } from 'src/app/models/user-result.model';
import { AuthService } from 'src/app/services/auth.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../globals";
import { ParticipantResult } from 'src/app/models/participant-result.model';
import { ParticipationResult } from 'src/app/models/participation-result.model';
import { MeetingResult } from 'src/app/models/meeting-result.model';
import { StatisticsResult } from 'src/app/models/statistics-result.model';

@Component({
  selector: 'app-universal-table',
  styleUrl: './universal-table.component.scss',
  templateUrl: './universal-table.component.html'
})
export class UniversalTableComponent extends SimpleFilterTableComponent<any> implements OnInit, OnChanges, OnDestroy {
  @Input() translatePrefix?: string;
  @Input() columns: string[] = [];
  @Input() buttons: string[] = [];
  @Input() subTableButtons: string[] = [];
  @Input() filters: string[] = [];
  @Input() fieldTypes: string[] = [];
  @Input() getAllMethod: string = '';
  @Input() search?: any;
  @Input() noFilterInTable: boolean = false;
  @Input() noPaging: boolean = false;
  @Input() noSorting: boolean = false;
  @Input() property2Columns: string[] = [];
  @Input() maxTextLength: number = 0;
  @Input() meetingSelection: boolean = false;
  @Input() registeredParticipantPage: boolean = false;
  searchResult?: any;
  isError: boolean = false;
  errorCode: string = '';
  refreshSubscription?: Subscription;
  invoiceStatus: [number, string][] = GLOBALS.invoiceStatus;

  @Output() deleteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editEvent: EventEmitter<any> = new EventEmitter();
  @Output() addEvent: EventEmitter<any> = new EventEmitter();
  @Output() exportEvent: EventEmitter<any> = new EventEmitter();
  @Output() subTableDelEvent: EventEmitter<any> = new EventEmitter();
  @Output() subTableEditEvent: EventEmitter<any> = new EventEmitter();
  @Output() subTableAddEvent: EventEmitter<any> = new EventEmitter();

  @Output() mailAcceptEvent: EventEmitter<any> = new EventEmitter();
  @Output() mailRejectEvent: EventEmitter<any> = new EventEmitter();

  @Output() tableContent: EventEmitter<any> = new EventEmitter();

  constructor(private restService: RestService, private router: Router, private authService: AuthService, private refreshService: RefreshService) { 
    super();
  }

  ngOnDestroy() {
    this.refreshSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!changes['search'].firstChange) {
      this.setPaging();
      this.emitFilterChange();
    }
  }

  ngOnInit() {
    this.refreshSubscription = this.refreshService.refresh.subscribe({
      next: (res: boolean) => {
        if(res) this.emitFilterChange();
      }
    });
    
    this.setPaging();
    this.loadTable();
  }

  loadTable() {
    merge(this.filterChanges).pipe(
      tap(() => {
        this.pages = [];
        this.list = [];
        this.isLoading = true;
      }),
      switchMap(() => (this.restService as any)[this.getAllMethod](this.search)),
      debounceTime(400),
      distinctUntilChanged(),
      catchError( err => {
        console.log(err);
        this.authService.checkAuth(err.status, this.router.url);
        this.searchResult = undefined;
        this.isLoading = false;
        this.isError = true;
        this.errorCode = String(err.status);
        return [];
      }),
      retry(),
      repeat()
    ).subscribe({
      next: (res: any) => {
        this.searchResult = res as UserResult | SeminarResult | ConferenceVenueResult | SpeakerResult | DepartmentResult | ParticipantResult | ParticipationResult | MeetingResult | NextMeeting[] | StatisticsResult;
        this.list = (this.getAllMethod !== 'allSeminarMeetings') ? this.searchResult.result : this.searchResult;

        if (this.registeredParticipantPage) { 
          this.list = this.list.sort((a, b) => a.reportingOffice.localeCompare(b.reportingOffice));
        }

        if(!this.noPaging) this.pages = [].constructor(Math.ceil(this.searchResult.totalCount / this.search!.pageSize));
        
        this.tableContent.emit(this.list);

        this.isLoading = false;
        this.isError = false;
      }
    });

    this.emitFilterChange();
  }

  emitMailAccept(value: any) {
    this.mailAcceptEvent.emit(value);
  }

  emitMailReject(value: any) {
    this.mailRejectEvent.emit(value);
  }

  emitDelete(value: any) {
    this.deleteEvent.emit(value);
  }

  emitEdit(value: any) {
    this.editEvent.emit(value);
  }

  emitAdd(value: any) {
    this.addEvent.emit(value);
  }

  emitExport(value: any) {
    this.exportEvent.emit(value);
  }

  emitSubTableEdit(value: any) {
    this.subTableEditEvent.emit(value);
  }

  emitSubTableDelete(value: any) {
    this.subTableDelEvent.emit(value);
  }

  emitSubTableAdd(value: any[]) {
    this.subTableAddEvent.emit(value);
  }

  emitTableContent() {
    this.tableContent.emit(this.list);
  }

  setPaging() {
    if(!this.noPaging) {
      this.paging.orderColumn = this.search?.orderColumn;
      this.paging.orderDirection = this.search?.orderDirection;
    }
  }

  checkHeader(header: string, header2: string) {
    if (header != header2) {
      return true;
    } else {
      return false;
    }
  }
}