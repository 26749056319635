import { Injectable, OnDestroy, inject } from "@angular/core";
import { User } from "../models/user.model";
import * as GLOBALS from ".././globals";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlSegment, UrlTree } from "@angular/router";
import { Observable, Subject, Subscription, from, map } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class PermissionService implements OnDestroy {
    authUser: User | undefined;
    isLoading: boolean = false;
    route?: ActivatedRouteSnapshot;
    authSubscription?: Subscription;
    rolePermissions: any = {
        'admin': [
            GLOBALS.routeParameterSeminar,
            GLOBALS.routeParameterSpeaker,
            GLOBALS.routeParameterConferenceVenue,
            GLOBALS.routeParameterDepartment,
            GLOBALS.routeParameterReportingOffice,
            GLOBALS.routeParameterRevisor,
            GLOBALS.routeParameterEditSeminarArea,
            GLOBALS.routeParameterEditParticipantStatus,
            GLOBALS.routeParameterEditOrganisation,
            GLOBALS.routeParameterEditCareer,
            GLOBALS.routeParameterEditCategory,
            GLOBALS.routeParameterParticipantApplication,
            GLOBALS.routeParameterHome,
            GLOBALS.routeParameterCreateAccount,
            GLOBALS.routeParameterLogin,
            GLOBALS.routeParameterRequestPasswordReset,
            GLOBALS.routeParameterSetInitialPassword,
            GLOBALS.routeParameterSetPassword,
            GLOBALS.routeParameterForbidden,
            GLOBALS.routeParameterKeydates,
            GLOBALS.routeParameterRegisteredParticipant,
            GLOBALS.routeParameterStatistic,
            GLOBALS.routeParameterParticipant,
            GLOBALS.routeParameterCertification
        ],   
        'participant': [
            GLOBALS.routeParameterCreateAccount,
            GLOBALS.routeParameterLogin,
            GLOBALS.routeParameterRequestPasswordReset,
            GLOBALS.routeParameterSetInitialPassword,
            GLOBALS.routeParameterSetPassword,
            GLOBALS.routeParameterParticipant,
            GLOBALS.routeParameterForbidden
        ], 
        'noaccount': [
            GLOBALS.routeParameterLogin,
            GLOBALS.routeParameterCreateAccount,
            GLOBALS.routeParameterRequestPasswordReset,
            GLOBALS.routeParameterSetInitialPassword,
            GLOBALS.routeParameterSetPassword
        ] 
    }

    private initEventSubject: Subject<boolean> = new Subject();
    initEvent: Observable<boolean> = this.initEventSubject.asObservable();

    constructor(private authService: AuthService, private router: Router) { 
        this.authSubscription = this.authService.loginEvent.subscribe({
            next: (res: boolean) => {
                if(!res) {
                    this.authUser = this.authService.authUser;
                    this.isLoading = false;
                }
            }
        });
    }

    ngOnDestroy() {
        this.authSubscription?.unsubscribe();
    }

    async hasPermission(urlSegments: UrlSegment[]): Promise<any> {
        let hasPermission: boolean = false;
        if(!this.authUser) return hasPermission;
        let checkPath = urlSegments[urlSegments.length - 1].path;

        switch (this.authUser?.roleName) {
            case GLOBALS.adminRole:
                hasPermission = this.rolePermissions[GLOBALS.adminRole].includes(checkPath);
                break;
            case GLOBALS.participantRole:
                hasPermission = this.rolePermissions[GLOBALS.participantRole].includes(checkPath);
                break;
            case GLOBALS.noaccountRole:
                hasPermission = this.rolePermissions[GLOBALS.noaccountRole].includes(checkPath);
                break;
        }

        return hasPermission;
    }

    isRole(role: string): boolean {
        return this.authUser?.roleName === role;
    }

    private delay(ms: number): Promise<any> {
        return new Promise(done => {
            setTimeout(() => {
                done(null);
            }, ms);
        });
    }

    async initAuthInfo(): Promise<any> { 
        this.isLoading = true;
        this.initEventSubject.next(true);
        await this.authInfo();
    }

    async authInfo(): Promise<any> {
        if (this.authUser !== undefined) {
            if(this.route) {
                this.hasPermission(this.route?.url).then((value: boolean) => {
                    if(!value) {
                        if(this.isRole(GLOBALS.participantRole) && this.route?.url[0].path === GLOBALS.routeParameterHome) { // participant has no access to home
                            this.router.navigate(['/' + GLOBALS.routeParameterSeminarRegistration]);
                        }
                    }
                    if(value) {
                        this.router.navigate([this.route?.url[this.route?.url.length - 1].path, this.route?.params ? this.route?.params : undefined]);
                    }
                });
            }

            this.initEventSubject.next(false);
            return;
        } else if (this.isLoading) {
            await this.delay(100);
            await this.authInfo();
        } else if (!this.isLoading) {
            this.initEventSubject.next(false);
            return; // info() redirects to login. user is not auth
        }
    }   
}

export const PermissionGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const permissionService: PermissionService = inject(PermissionService);
    permissionService.route = route;
    
    return from(permissionService.hasPermission(route.url)).pipe(map((value: boolean) => {
        return value;
    }));
}