<ng-template #createRevisor let-modal>
    <app-create-revisor-modal [translatePrefix]=" translatePrefix + '.MODAL'" [modal]="modal" [user]="selectedRevisor"></app-create-revisor-modal>
</ng-template>

<ng-template #deleteUser let-modal>
    <app-generic-modal [translatePrefix]="'DELETE.UNIVERSAL'" [modal]="modal" [payload]="payload" [method]="deleteMethod" [diffServerErrorMessage]="'REVISORINUSE'"></app-generic-modal>
</ng-template>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>
    <div class="d-flex mt-1 mb-3">
        <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-primary me-3" style="width: 100px;" (click)="openModal(createRevisor, 'create-revisor-modal', 'xl')" placement="end" ngbTooltip="{{ translatePrefix + '.ADD' | translate }}">
                <i class="fa-solid fa-user-plus"></i>
            </button>
        </div>
    </div>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" [getAllMethod]="getAllMethod" [search]="search"
        (deleteEvent)="setPayload($event); openModal(deleteUser, 'delete-user-modal', '')" (editEvent)="openModal(createRevisor, 'create-revisor-modal', 'xl', $event)"></app-universal-table>
</div>