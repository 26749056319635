import * as GLOBALS from "././globals";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { SeminarComponent } from "./components/seminar/seminar.component";
import { CreateAccountComponent } from "./components/create-account/create-account.component";
import { SpeakerComponent } from "./components/speaker/speaker.component";
import { ConferenceVenueComponent } from "./components/conference-venue/conference-venue.component";
import { DepartmentComponent } from "./components/department/department.component";
import { SetPasswordComponent } from "./components/set-password/set-password.component";
import { RequestPasswordResetComponent } from "./components/request-password-reset/request-password-reset.component";
import { SetInitialPasswordComponent } from "./components/set-initial-password/set-initial-password.component";
import { RevisorComponent } from "./components/revisor/revisor.component";
import { EditCareerComponent } from "./components/edit-career/edit-career.component";
import { EditCategoryComponent } from "./components/edit-category/edit-category.component";
import { EditOrganisationComponent } from "./components/edit-organisation/edit-organisation.component";
import { EditParticipantStatusComponent } from "./components/edit-participant-status/edit-participant-status.component";
import { EditSeminarAreaComponent } from "./components/edit-seminar-area/edit-seminar-area.component";
import { ForbiddenAccessComponent } from "./components/forbidden-access/forbidden-access.component";
import { ReportingOfficeComponent } from "./components/reporting-office/reporting-office.component";
import { PermissionGuard } from "./services/permission.service";
import { ParticipantApplicationComponent } from "./components/participant-application/participant-application.component";
import { ParticipantComponent } from "./components/participant/participant.component";
import { SeminarRegistrationComponent } from "./components/seminar-registration/seminar-registration.component";
import { KeydatesComponent } from "./components/keydates/keydates.component";
import { RegisteredParticipantComponent } from "./components/registered-participant/registered-participant.component";
import { StatisticComponent } from "./components/statistic/statistic.component";
import { ModuleCertificationComponent } from "./components/module-certification/module-certification.component";

const routes: Routes = [
    { path: GLOBALS.routeParameterHome , component: HomeComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterDepartment, component: DepartmentComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterConferenceVenue, component: ConferenceVenueComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterSpeaker, component: SpeakerComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterSeminar, component: SeminarComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterCreateAccount, component: CreateAccountComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterLogin, component: LoginComponent },
    { path: GLOBALS.routeParameterSetPassword, component: SetPasswordComponent },
    { path: GLOBALS.routeParameterRequestPasswordReset, component: RequestPasswordResetComponent },
    { path: GLOBALS.routeParameterSetInitialPassword, component: SetInitialPasswordComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterRevisor, component: RevisorComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterEditCareer, component: EditCareerComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterEditCategory, component: EditCategoryComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterEditOrganisation, component: EditOrganisationComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterEditParticipantStatus, component: EditParticipantStatusComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterEditSeminarArea, component: EditSeminarAreaComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterForbidden, component: ForbiddenAccessComponent },
    { path: GLOBALS.routeParameterReportingOffice, component: ReportingOfficeComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterParticipantApplication, component: ParticipantApplicationComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterParticipant, component: ParticipantComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterKeydates, component: KeydatesComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterRegisteredParticipant, component: RegisteredParticipantComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterStatistic, component: StatisticComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterCertification, component: ModuleCertificationComponent, canActivate: [PermissionGuard] },
    { path: GLOBALS.routeParameterSeminarRegistration, component: SeminarRegistrationComponent},
    { path: '**', redirectTo: '/' + GLOBALS.routeParameterHome }
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: "reload" })],
    exports: [RouterModule]
})
export class AppRoutingModule { }