<div class="d-flex form-group">
    <ng-container [ngTemplateOutlet]="filter"></ng-container>
</div>

<ng-template #filter>
    <!--texts-->
    <input *ngIf="type == 'text'" type="text" class="form-control" [ngClass]="{'me-2': resetButton}" id="{{'filter_' + column + '_id' + resetButton}}" name="{{'filter_' + column}}" [ngModel]="search" (ngModelChange)="delayedInput($event)">
    <!--numbers-->
    <input *ngIf="type == 'number'" type="number" min="0" class="form-control" [ngClass]="{'me-2': resetButton}" id="{{'filter_' + column + '_id' + resetButton}}" name="{{'filter_' + column}}" [ngModel]="search" (ngModelChange)="delayedInput($event)">
    <!--years only-->
    <input *ngIf="type == 'year'" type="number" [min]="yearsMin" [max]="yearsMax" class="form-control" [ngClass]="{'me-2': resetButton}" id="{{'filter_' + column + '_id' + resetButton}}" name="{{'filter_' + column}}" [ngModel]="search" (ngModelChange)="delayedInput($event)">
    <!--dates-->
    <input *ngIf="type == 'date'" type="date" class="form-control" [ngClass]="{'me-2': resetButton}" id="{{'filter_' + column + 'id' + resetButton}}" name="{{'filter_' + column + '_id'}}" [ngModel]="search" (ngModelChange)="delayedInput($event)">
    <!--dropdown with list-->
    <select *ngIf="type == 'dropdown'" class="form-select" id="{{'filter_' + column + '_id' + resetButton}}" name="{{'filter_' + column}}" [ngModel]="search" (ngModelChange)="emitFilter($event)" [ngClass]="{'me-2': resetButton}">
        <option *ngIf="emptyOption" [value]="" selected></option>
        <option *ngFor="let item of dropdownSelect; index as index" class="form-option" [ngValue]="item">
            <ng-container *ngIf="dropdownTranslates.length !== 0">
                {{ dropdownTranslates[index] | translate }}
            </ng-container>
            <ng-container *ngIf="dropdownTranslates.length === 0">
                {{ item }}
            </ng-container>
        </option>
    </select>
    <!--quarter-->
    <select *ngIf="type == 'quarter'" class="form-select" id="{{'filter_' + column + '_id' + resetButton}}" name="{{'filter_' + column}}" [ngModel]="search" (ngModelChange)="emitFilter($event)" [ngClass]="{'me-2': resetButton}">
        <option [ngValue]="" selected></option>
        <option *ngFor="let item of quarterFilter" class="form-option" [ngValue]="item[1]">{{ item[0] }}</option>
    </select>
    <!--area-->
    <select *ngIf="type == 'area'" class="form-select" id="{{'filter_' + column + '_id' + resetButton}}" name="{{'filter_' + column}}" [ngModel]="search" (ngModelChange)="emitFilter($event)" [ngClass]="{'me-2': resetButton}">
        <option [ngValue]="null" selected></option>
        <option *ngFor="let item of areas" class="form-option" [ngValue]="item.areaId">{{ item.areaValue }}</option>
    </select>
    <!--categorie-->
    <select *ngIf="type == 'categorie'" class="form-select" id="{{'filter_' + column + '_id' + resetButton}}" name="{{'filter_' + column}}" [ngModel]="search" (ngModelChange)="emitFilter($event)" [ngClass]="{'me-2': resetButton}">
        <option [ngValue]="null" selected></option>
        <option *ngFor="let item of categories" class="form-option" [ngValue]="item.categoryId">{{ item.categoryValue }}</option>
    </select>
    <!--organisation-->
    <select *ngIf="type == 'organisation'" class="form-select" id="{{'filter_' + column + '_id' + resetButton}}" name="{{'filter_' + column}}" [ngModel]="search" (ngModelChange)="emitFilter($event)" [ngClass]="{'me-2': resetButton}">
        <option [ngValue]="null" selected></option>
        <option *ngFor="let item of organisations" class="form-option" [ngValue]="item.organisationId">{{ item.organisationValue }}</option>
    </select>

    <button *ngIf="resetButton" type="button" class="btn btn-primary" (click)="resetFilter()"> <i class="fa-solid fa-rotate-left"></i> </button>
</ng-template>