import { Component, Input, OnInit } from '@angular/core';
import { Meeting } from 'src/app/models/meeting.model';
import { ParticipationCertification } from 'src/app/models/participation-certification.model';
import { Participation } from 'src/app/models/participation.model';
import { Seminar } from 'src/app/models/seminar.model';

@Component({
  selector: 'app-participant-certification-pdf',
  styleUrl: './participant-certification-pdf.component.scss',
  templateUrl: './participant-certification-pdf.component.html',
})
export class ParticipantCertificationPdfComponent implements OnInit {
  @Input() translatePrefix: string = 'PDF.PARTICIPANTCERTIFICATION';
  @Input() participations?: Participation[];
  @Input() meeting?: Meeting;
  @Input() seminar?: Seminar;
  today: Date = new Date();

  // module certification
  @Input() moduleCertification: boolean = false;
  @Input() participationCert?: Map<string,  Map<string, ParticipationCertification[]>>;
  @Input() isBase = true;
    
  constructor() { }

  ngOnInit() { }

  getParticipation(cert: ParticipationCertification[]): Participation {
    return cert[0].participationCertInfo[0];
  }

  getMeeting(cert: any): Meeting {
    return cert.participationCertInfo[2];
  }

  getSeminar(cert: any): Seminar {
    return cert.participationCertInfo[1];
  }
}