<ng-container *ngIf="modal && meeting">
    <div class="modal-header">
        <h4 class="modal-title">{{ meeting.seminar?.name }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <div class="alert alert-secondary" role="alert">
            <div class="d-flex mb-3">
                <div class="w-50">
                    <label class="mb-1"> <b>{{ translatePrefix + '.APPOINTMENT' | translate }}:</b> </label>
                    <div>{{ meeting.meeting?.startDate | date:'dd.MM.yyyy' }}&nbsp;-&nbsp;{{ meeting.meeting?.endDate | date:'dd.MM.yyyy'}}</div>
                </div>
                <div class="w-50">
                    <label class="mb-1"> <b>{{ translatePrefix + '.REPORTINGDATE' | translate }}:</b> </label>
                    <div>{{ meeting.meeting?.externalReportingDate | date:'dd.MM.yyyy' | replaceEmpty }}</div>
                </div>
            </div>
            <div class="d-flex mb-3">
                <div class="w-50">
                    <label class="mb-1"> <b>{{ translatePrefix + '.FEE' | translate }}:</b> </label>
                    <div>{{ meeting.seminar?.participationFee }}&nbsp;{{ translatePrefix + '.CURRENCY' | translate }}</div>
                </div>
                <div class="w-50">
                    <label class="mb-1"> <b>{{ translatePrefix + '.EXPECTEDPARTICIPANTS' | translate }}:</b> </label>
                    <div>{{ meeting.meeting?.expectedParticipants }}</div>
                </div>
            </div>
            <div class="d-flex mb-3">
                <div class="w-50">
                    <label class="mb-1"> <b>{{ translatePrefix + '.CONFERENCE_VENUE' | translate }}:</b> </label>
                    <div>{{ meeting.conferenceVenue?.name }}</div>
                </div>
                <div class="w-50">
                    <label class="mb-1"> <b>{{ translatePrefix + '.MAINSPEAKER' | translate }}:</b> </label>
                    <div>{{ meeting.mainSpeakerName | replaceEmpty }}</div>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <label class="mb-1"> <b>{{ translatePrefix + '.CONTENT' | translate }}:</b> </label>
            <p [innerHTML]="meeting.seminar?.seminarContent | replaceEmpty"></p>
        </div>
        <div class="mb-3">
            <label class="mb-1"> <b>{{ translatePrefix + '.GAIN' | translate }}:</b> </label>
            <p [innerHTML]="meeting.seminar?.gain | replaceEmpty"></p>
        </div>
        <div class="mb-3">
            <label class="mb-1"> <b>{{ translatePrefix + '.AUDIENCE' | translate }}:</b> </label>
            <p [innerHTML]="meeting.seminar?.targetAudience | replaceEmpty"></p>
        </div>
        <div class="mb-3">
            <label class="mb-3"> <b>{{ translatePrefix + '.CONFERENCE_VENUE' | translate }}:</b> </label>
            <div class="mb-1">{{ meeting.conferenceVenue?.name }}</div>
            <div>{{ meeting.conferenceVenue?.street }}</div>
            <div class="mb-3">{{ meeting.conferenceVenue?.zip }}&nbsp;{{ meeting.conferenceVenue?.location }}</div>
            <label class="d-flex w-25"> 
                {{ translatePrefix + '.TEL' | translate }}:&nbsp;{{ meeting.conferenceVenue?.phone | replaceEmpty }}
            </label>
            <label class="d-flex w-25"> 
                {{ translatePrefix + '.FAX' | translate }}:&nbsp;{{ meeting.conferenceVenue?.fax | replaceEmpty }}
            </label>
            <label class="d-flex w-50"> 
                {{ translatePrefix + '.EMAIL' | translate }}:&nbsp;{{ meeting.conferenceVenue?.fax | replaceEmpty }}
            </label>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
    </div>
</ng-container>