export class ParticipantConfirmation {
    meetingId: string = '';
    participantId: string = '';
    participantEmail: string = '';
    invitation: string = '';
    conferenceVenueId: string = '';
    notice: string = '';

    constructor(meetingId: string, participantId: string, participantEmail: string, invitation: string, conferenceVenueId: string, notice: string) {
        this.meetingId = meetingId;
        this.participantId = participantId;
        this.participantEmail = participantEmail;
        this.invitation = invitation;
        this.conferenceVenueId = conferenceVenueId;
        this.notice = notice;
    }
}

export class ParticipationsAll {
    meetingId: string = '';
    conferenceVenueId: string = '';
    invitation: string = '';
    notice: string = '';

    constructor(meetingId: string, conferenceVenueId: string, invitation: string, notice: string) {
        this.meetingId = meetingId;
        this.conferenceVenueId = conferenceVenueId;
        this.invitation = invitation;
        this.notice = notice;
    }
}