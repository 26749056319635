import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { RestService } from './services/rest.service';
import { LoginComponent } from './components/login/login.component';
import { AuthService } from './services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SeminarComponent } from './components/seminar/seminar.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { DepartmentComponent } from './components/department/department.component';
import { SpeakerComponent } from './components/speaker/speaker.component';
import { ConferenceVenueComponent } from './components/conference-venue/conference-venue.component';
import { CreateSeminarModalComponent } from './components/seminar/create-seminar-modal/create-seminar-modal.component';
import { CreateSpeakerModalComponent } from './components/speaker/create-speaker-modal/create-speaker-modal.component';
import { CreateConferenceVenueModalComponent } from './components/conference-venue/create-conference-venue/create-conference-venue.component';
import { CreateDepartmentModalComponent } from './components/department/create-department/create-department.component';
import { NgbAccordionModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UniversalTableComponent } from './components/universal-table/universal-table.component';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { SetInitialPasswordComponent } from './components/set-initial-password/set-initial-password.component';
import { UiControlService } from './services/uiControl.service';
import { RevisorComponent } from './components/revisor/revisor.component';
import { FilterComponent } from './components/filter/filter.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { UniversalEditComponent } from './components/universal-edit/universal-edit.component';
import { EditCareerComponent } from './components/edit-career/edit-career.component';
import { EditCategoryComponent } from './components/edit-category/edit-category.component';
import { EditOrganisationComponent } from './components/edit-organisation/edit-organisation.component';
import { EditSeminarAreaComponent } from './components/edit-seminar-area/edit-seminar-area.component';
import { EditParticipantStatusComponent } from './components/edit-participant-status/edit-participant-status.component';
import { ForbiddenAccessComponent } from './components/forbidden-access/forbidden-access.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { RefreshService } from './services/refresh.service';
import { FormService } from './services/form.service';
import { UniversalEditTableComponent } from './components/universal-edit-table/universal-edit-table.component';
import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { CreateRevisorModalComponent } from './components/revisor/create-revisor-modal/create-revisor-modal.component';
import { ReportingOfficeComponent } from './components/reporting-office/reporting-office.component';
import { CreateReportingOfficeModalComponent } from './components/reporting-office/create-reporting-office-modal/create-reporting-office-modal.component';
import { CreateMeetingModalComponent } from './components/create-meeting-modal/create-meeting-modal.component';
import { PermissionService } from './services/permission.service';
import { RolePipe } from './pipes/role.pipe';
import { CreateParticipantModalComponent } from './components/participant/create-participant-modal/create-participant-modal.component';
import { SeminarRegistrationComponent } from './components/seminar-registration/seminar-registration.component';
import { ParticipantApplicationComponent } from './components/participant-application/participant-application.component';
import { ParticipantComponent } from './components/participant/participant.component';
import { MeetingInfoModalComponent } from './components/seminar-registration/meeting-info-modal/meeting-info-modal.component';
import { EditApplicationModalComponent } from './components/edit-application/edit-application.component';
import { ReplaceEmptyPipe } from './pipes/replace-empty.pipe';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { KeydatesComponent } from './components/keydates/keydates.component';
import { RegisterParticipantModalComponent } from './components/participant/register-participant-modal/register-participant-modal.component';
import { RegisteredParticipantComponent } from './components/registered-participant/registered-participant.component';
import { StatisticComponent } from './components/statistic/statistic.component';
import { ParticipationAcceptModalComponent } from './components/registered-participant/participation-accept-modal/participation-accept-modal.component';
import { PdfService } from './services/pdf.service';
import { ParticipantCertificationPdfComponent } from './components/pdf/participant-certification-pdf/participant-certification-pdf.component';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { SalutationPipe } from './pipes/salutation.pipe';
import { ParticipantDocumentsPdfComponent } from './components/pdf/participant-documents-pdf/participant-documents-pdf.component';
import { ListPdfComponent } from './components/pdf/list-pdf/list-pdf.component';
import { InvitationPdfComponent } from './components/pdf/invitation-pdf/invitation-pdf.component';
import { SpeakerInvitationPdfComponent } from './components/pdf/speaker-invitation-pdf/speaker-invitation-pdf.component';
import { SubjectOverviewPdfComponent } from './components/pdf/subject-overview-pdf/subject-overview-pdf.component';
import { ConferenceVenueLetterPdfComponent } from './components/pdf/conference-venue-letter-pdf/conference-venue-letter-pdf.component';
import { registerLocaleData } from '@angular/common';
import * as de from '@angular/common/locales/de';
import { ParticipantListPdfComponent } from './components/pdf/participant-list-pdf/participant-list-pdf.component';
import { ConferenceVenueNoticePdfComponent } from './components/pdf/conference-venue-notice-pdf/conference-venue-notice-pdf.component';
import { AttendanceListPdfComponent } from './components/pdf/attendance-list-pdf/attendance-list-pdf.component';
import { TreeNodeComponent } from './components/tree-node/tree-node.component';
import { ModuleCertificationComponent } from './components/module-certification/module-certification.component';
import { InvoiceInfoModalComponent } from './components/registered-participant/invoice-info-modal/invoice-info-modal.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    NavBarComponent,
    LoginComponent,
    CreateAccountComponent,
    SeminarComponent,
    CreateSeminarModalComponent,
    DepartmentComponent,
    CreateDepartmentModalComponent,
    SpeakerComponent,
    CreateSpeakerModalComponent,
    ConferenceVenueComponent,
    CreateConferenceVenueModalComponent,
    UniversalTableComponent,
    RequestPasswordResetComponent,
    SetPasswordComponent,
    SetInitialPasswordComponent,
    RevisorComponent,
    FilterComponent,
    PaginationComponent,
    UniversalEditComponent,
    EditCareerComponent,
    EditCategoryComponent,
    EditOrganisationComponent,
    EditSeminarAreaComponent,
    EditParticipantStatusComponent,
    ForbiddenAccessComponent,
    ErrorPageComponent,
    UniversalEditTableComponent,
    FilterMenuComponent,
    GenericModalComponent,
    CreateRevisorModalComponent,
    ReportingOfficeComponent,
    CreateReportingOfficeModalComponent,
    ParticipantApplicationComponent,
    CreateMeetingModalComponent,
    RolePipe,
    CreateParticipantModalComponent,
    SeminarRegistrationComponent,
    ParticipantComponent,
    MeetingInfoModalComponent,
    EditApplicationModalComponent,
    ReplaceEmptyPipe,
    KeydatesComponent,
    RegisterParticipantModalComponent,
    RegisteredParticipantComponent,
    StatisticComponent,
    ParticipationAcceptModalComponent,
    ParticipantCertificationPdfComponent,
    DateDiffPipe,
    SalutationPipe,
    ParticipantDocumentsPdfComponent,
    ListPdfComponent,
    InvitationPdfComponent,
    SpeakerInvitationPdfComponent, // not in use
    SubjectOverviewPdfComponent,
    ConferenceVenueLetterPdfComponent, // not in use
    ParticipantListPdfComponent,
    ConferenceVenueNoticePdfComponent, 
    AttendanceListPdfComponent,
    TreeNodeComponent,
    ModuleCertificationComponent,
    InvoiceInfoModalComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'de'
    }),
    EditorModule
  ],
  providers: [
    RestService,
    AuthService,
    UiControlService,
    RefreshService,
    FormService,
    PermissionService,
    PdfService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: LOCALE_ID, useValue: "de-DE" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor() {
    registerLocaleData(de.default);
  }
}