export class NextMeetingFilter {
    name?: string | null;
    pageJfp?: number | null;
    yearMonth?: Date | null;

    constructor(name?: string | null, pageJfp?: number | null, yearMonth?: Date | null) {
        this.name = name;
        this.pageJfp = pageJfp;
        this.yearMonth = yearMonth;
    }
}