<div id="pdf-container">
    <div id="pdf-certification">
        <ng-container *ngIf="!moduleCertification">
            <ng-container *ngFor="let participant of participations">
                <div class="text-center" style="margin-bottom: 7.5rem;">
                    <div class="mb-4" style="font-size: x-large;"> <b>{{ translatePrefix + '.HEADER' | translate }}</b> </div>
                    <div>
                        <div style="font-size: large;">{{ participant?.salutationId | salutation }}&nbsp;</div>
                        <div style="font-size: large;">{{ participant?.academicTitle }}&nbsp;</div>
                        <div style="font-size: large;">{{ participant?.firstName }}&nbsp;{{ participant?.lastName }}</div>
                    </div>
                    <div class="mb-3">
                        <b style="font-size: large;">{{ participant?.departmentName }}</b>
                    </div>
                    <div class="mb-5" style="font-size: large;">
                        {{ translatePrefix + '.FROM' | translate }}&nbsp;
                        {{ meeting?.startDate | date: 'dd.MM.yyyy' }}&nbsp;
                        {{ translatePrefix + '.TO' | translate }}&nbsp;
                        {{ meeting?.endDate | date: 'dd.MM.yyyy' }}
                    </div>
                    <div style="font-size: large;">{{ translatePrefix + '.LINE1' | translate }}</div>
                    <div class="mb-3" style="font-size: large;">
                        {{ translatePrefix + '.LINE2' | translate }}&nbsp;
                        {{ meeting?.startDate | datediff: meeting?.endDate }} 
                        {{ translatePrefix + '.DAYS' | translate }}
                    </div>
                    <div class="mb-3"> 
                        <b>&quot;{{ seminar?.name }}&quot;</b> 
                    </div>
                    <div style="font-size: large;">{{ translatePrefix + '.LINE3' | translate }}</div>
                </div>
                <div>
                    <div class="mb-3">{{ translatePrefix + '.DATE' | translate }}&nbsp;{{ today | date: 'dd.MM.yyyy' }}</div>
                    <div class="mb-4">{{ translatePrefix + '.ORDER' | translate }}</div>
                    <div>{{ participant?.signiture }}</div>
                </div>
                <div class="html2pdf__page-break"></div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="moduleCertification">
            <ng-container *ngFor="let participant of participationCert | keyvalue">
                <ng-container *ngFor="let department of participant.value | keyvalue">
                    <div class="text-center" style="margin-bottom: 6.5rem;">
                        <div class="mb-4" style="font-size: x-large;"> <b>{{ translatePrefix + '.HEADER' | translate }}</b> </div>
                        <div>
                            <div style="font-size: large;">{{ getParticipation(department.value).salutationId | salutation }}&nbsp;</div>
                            <div style="font-size: large;">{{ getParticipation(department.value).academicTitle }}&nbsp;</div>
                            <div style="font-size: large;">{{ getParticipation(department.value).firstName }}&nbsp;{{ getParticipation(department.value).lastName }}</div>
                        </div>
                        <div class="mb-3">
                            <b style="font-size: large;">{{ getParticipation(department.value).departmentName }}</b>
                        </div>
                        <ng-container>
                            <div class="mb-3" style="font-size: large;">
                                {{ translatePrefix + '.LINE4' | translate }}<br>
                                {{ translatePrefix + '.LINE5' | translate }}<br>
                                <ng-container *ngIf="isBase">
                                    {{ translatePrefix + '.BASE' | translate }}
                                </ng-container>
                                <ng-container *ngIf="!isBase">
                                    {{ translatePrefix + '.EXPANSION' | translate }}
                                </ng-container>
                            </div>
                            <div class="mb-3 bg-light fw-bold">
                                <ng-container *ngFor="let cert of department.value | keyvalue">
                                    {{ getSeminar(cert.value).name }}&nbsp;{{ getMeeting(cert.value).startDate | date: 'd. LLLL' }}&nbsp;-&nbsp;{{ getMeeting(cert.value).endDate | date: 'd. LLLL' }} <br>
                                </ng-container>
                            </div>
                        </ng-container>
                        <div style="font-size: large;">{{ translatePrefix + '.LINE3' | translate }}</div>
                    </div>
                    <div>
                        <div class="mb-3">{{ translatePrefix + '.DATE' | translate }}&nbsp;{{ today | date: 'dd.MM.yyyy' }}</div>
                        <div class="mb-4">{{ translatePrefix + '.ORDER' | translate }}</div>
                        <div>{{ getParticipation(department.value).signiture }}</div>
                    </div>
                    <div class="html2pdf__page-break"></div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>