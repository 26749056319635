import { Component } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../globals";
import { GetQuery } from 'src/app/models/get-query.model';

@Component({
  selector: 'app-request-password-reset',
  styleUrl: './request-password-reset.component.scss',
  templateUrl: './request-password-reset.component.html'
})
export class RequestPasswordResetComponent {
  translatePrefix: string = 'REQUESTPASSWORDCHANGE';
  loginPath: string = '/' + GLOBALS.routeParameterLogin;
  eMail?: string;
  isSuccess: boolean = false;
  isError: boolean = false;
  errorCode: string = '';

  constructor(private restService: RestService) { }

  request() {
    let getQuery: GetQuery = new GetQuery();
    getQuery.keyMap.set("eMail", this.eMail??"");

    this.restService.requestPassword(getQuery).subscribe({
      next: res => {
        this.isSuccess = true;
        this.isError = false; 
        this.errorCode = '';
      },
      error: err => {
        console.log(err);
        this.isError = true;
        this.errorCode = String(err.status);
      }
    });
  }

  validate(): boolean {
    return !this.eMail;
  }
}