<div class="d-flex flex-wrap justify-content-center">
    <ng-container *ngFor="let route of adminRoutes">
        <div class="col-sm-3 mb-3 mx-1 border" style="width: 225px;" [routerLink]="route[1]">
            <div class="d-flex justify-content-center m-3" placement="bottom" ngbTooltip="{{ translatePrefix + '.' + route[0] | translate }}">
                <button class="nav-button">
                    <div class="d-flex justify-content-center mb-2">
                        <div class="text-primary m-2" [ngClass]="route[2]" style="transform: scale(2)"></div> 
                    </div>
                    <div class="text-center" style="font-size: large; font-weight: 600;">{{ translatePrefix + '.' + route[0] | translate }}</div>
                </button>
            </div>
        </div>
    </ng-container>
</div>