import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Status } from 'src/app/models/form.model';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'edit-participant-status',
  styleUrl: './edit-participant-status.component.scss',
  templateUrl: './edit-participant-status.component.html',
})
export class EditParticipantStatusComponent implements OnInit, OnDestroy { 
  translatePrefix: string = 'EDIT.EDIT_PARTICIPANT_STATUS';
  columns: string[] = ['statusValue'];
  buttons: string[] = ['editDelBtn'];
  statuses?: Status[] = [];
  addMethod: string = 'addStatus';
  editMethod: string = 'editStatus';
  deleteMethod: string = 'deleteStatus';
  keys: string[] = ['statusId', 'statusFilter'];
  formSubscription?: Subscription;
  
  constructor(private formService: FormService) { }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }
  
  ngOnInit() { 
    this.formSubscription = this.formService.refresh.subscribe({
      next: (res: boolean) => {
        if(!res) {
          this.statuses = this.formService.data?.statuses;
        }
      }
    });

    this.formService.updateData();
  }
}