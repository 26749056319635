import { PageableFilter } from "./pageable-filter.model";

export class StatisticsFilter extends PageableFilter {
    dateFrom?: Date | null | string = null;
    dateTo?: Date | null | string = null;
    areaId?: number | null = null;
    categoryId?: number | null = null;
    organisationId?: number | null = null;

    constructor(page: number, pageSize: number, orderColumn?: string | null, orderDirection?: string | null, dateTo?: Date | null | string, dateFrom?: Date | null | string, 
        areaId?: number | null, categoryId?: number | null, organisationId?: number | null) {
        super(page, pageSize, orderColumn, orderDirection);
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.areaId = areaId;
        this.categoryId = categoryId;
        this.organisationId = organisationId;
    }
}