import { Component, Input } from '@angular/core';
import { ChildNode } from 'src/app/models/child-node.model';

@Component({
  selector: 'app-tree-node',
  styleUrl: './tree-node.component.scss',
  templateUrl: './tree-node.component.html',
})
export class TreeNodeComponent {
  @Input() name?: string;
  @Input() value?: string;
  @Input() children?: ChildNode[];
    
  constructor() { }
}