import { Component, OnInit } from '@angular/core';
import * as GLOBALS from "../../globals";
import { UserFilter } from 'src/app/models/user-filter.model';
import { User } from 'src/app/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetQuery } from 'src/app/models/get-query.model';

@Component({
  selector: 'app-revisor',
  styleUrl: './revisor.component.scss',
  templateUrl: './revisor.component.html',
})
export class RevisorComponent implements OnInit {
  translatePrefix?: string = 'FORMS.REVISOR';
  columns: string[] = ['firstName', 'lastName'];;
  buttons: string[] = ['editBtn', 'delBtn'];
  filters: string[] = ['text', 'text'];;
  getAllMethod: string = 'allUsers';
  search?: UserFilter = new UserFilter(1, 25, 'lastName', 'asc');
  selectedRevisor?: User;
  deleteMethod: string = 'deleteAccount';
  payload?: GetQuery;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.search!.roleName = GLOBALS.adminRole;
  }

  openModal(modal: any, ariaLabelledBy: string, size: string, revisor?: User) {
    this.selectedRevisor = revisor;
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }

  setPayload(event: User) {
    this.payload = new GetQuery();
    this.payload.keyMap.set('uniqueIdentifier', event.uniqueIdentifier??'');
  }
}