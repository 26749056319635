import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetQuery } from 'src/app/models/get-query.model';
import { ConferenceVenueFilter } from 'src/app/models/conference-venue-filter.model';
import { ConferenceVenue } from 'src/app/models/conference-venue.model';
import { RestService } from 'src/app/services/rest.service';
import { ConferenceVenueResult } from 'src/app/models/conference-venue-result.model';
import { PdfService } from 'src/app/services/pdf.service';
import * as GLOBALS from "../../globals";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-conference-venue',
  styleUrl: './conference-venue.component.scss',
  templateUrl: './conference-venue.component.html',
})
export class ConferenceVenueComponent { 
  translatePrefix: string = 'FORMS.CONFERENCE_VENUE';
  columns: string[] = ['name', 'street', 'location', 'phone', 'fax'];
  buttons: string[] = ['editBtn', 'delBtn', 'addConferenceVenueBtn'];
  filters: string[] = ['text', 'text', 'text', 'text', 'text'];
  columnsFilterMenu: string[] = ['name', 'street', 'location', 'phone', 'fax'];
  filtersFilterMenu: string[] = ['text', 'text', 'text', 'text', 'text'];
  getAllMethod: string = 'allConferenceVenue';
  deleteMethod: string = 'deleteConferenceVenue';
  payload?: GetQuery;
  selectedConferenceVenue?: ConferenceVenue;
  search?: ConferenceVenueFilter = new ConferenceVenueFilter(1, 25, 'name', 'asc');
  isError: boolean = false;
  errorCode: string = '';

  // print
  isLoadingPrint: boolean = false;
  yearsMin: number = GLOBALS.yearsMin;
  yearsMax: number | null = new Date().getFullYear();
  venueList: ConferenceVenue[] = [];
  yearSelected?: number | null = this.yearsMax;
  printPropertyNames: string[][] = [['name'], ['street'], ['location', 'zip'], ['phone'], ['mail'], ['contactFirstName', 'contactLastName']];
  printTypes: string[] = ['text', 'text', 'text', 'text', 'text'];
  printTranslates: string[] = ['', '', '', 'PHONE', 'EMAIL', 'CONTACT'];

  constructor(private modalService: NgbModal, private restService: RestService, private pdfService: PdfService, private authService: AuthService) { } 
  
  openModal(modal: any, ariaLabelledBy: string, size: string, conferenceVenue?: ConferenceVenue) {
    this.selectedConferenceVenue = conferenceVenue;
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }
  
  resetFilter() {
    this.search = new ConferenceVenueFilter(1, 25, 'name', null);
  }

  setSearch(event: ConferenceVenueFilter) {
    this.search = new ConferenceVenueFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.name, event.street, event.location, event.phone, event.fax);
    
  }

  setPayload(event: ConferenceVenue) {
    this.payload = new GetQuery();
    this.payload.keyMap.set('uniqueIdentifier', event.conferenceVenueId ?? '');
  }

  print() {
    this.isLoadingPrint = true;

    this.restService.allConferenceVenue(new ConferenceVenueFilter(1, 25, null, null, null, null, null, null, null, this.yearSelected)).subscribe({
      next: res => {
        this.venueList = (res as ConferenceVenueResult).result;
        this.errorCode = '';
        this.isError = false;
        this.pdfService.print('Tagungsstätten_' + this.yearSelected, 'pdf-list', undefined, true);
        this.isLoadingPrint = false;
      }, error: err => {
        console.log(err);
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
        this.isError = true;
      }
    });
  }
}