export class FormFilter {
    areaFilter?: string | null = null;
    careerFilter?: string | null = null;
    categoryFilter?: string | null = null;
    organisationFilter?: string | null = null;
    statusFilter?: string | null = null;

    constructor(areaFilter?: string | null, careerFilter?: string | null, categoryFilter?: string | null, organisationFilter?: string | null, statusFilter?: string | null) {
        this.areaFilter = areaFilter;
        this.careerFilter = careerFilter;
        this.categoryFilter = categoryFilter;
        this.organisationFilter = organisationFilter;
        this.statusFilter = statusFilter;
    }
}