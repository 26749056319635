<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ translatePrefix + '.HEADER' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form name="createConsultantForm" *ngIf="speaker">
            <div class="mb-4 alert alert-info" role="alert">
                {{ 'FORMS.REQUIRED' | translate }}
            </div>
            <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                <div class="d-flex">
                    <i class="fa-solid fa-xmark mt-1 me-2"></i>
                    {{ 'ERRORCODE.' + errorCode | translate }}
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="salutaion" class="mb-1">{{ translatePrefix + '.SALUTATION' | translate }}</label>
                    <select class="form-select" id="salutaion" name="salutaion" [(ngModel)]="speaker.salutationId" [ngClass]="{'required-input': !speaker.salutationId}">
                        <ng-container *ngFor="let salutaion of salutaions">
                            <option class="form-option" [value]="salutaion[0]">{{ translatePrefix + '.SALUTATIONS.' + salutaion[1] | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="academicTitle" class="mb-1">{{ translatePrefix + '.ACADEMICTITLE' | translate }}</label>
                    <input type="text" class="form-control" id="academicTitle" name="academicTitle" [(ngModel)]="speaker.academicTitle">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="firstname" class="mb-1">{{ translatePrefix + '.FIRSTNAME' | translate }}</label>
                    <input type="text" class="form-control" id="firstname" name="firstname" [(ngModel)]="speaker.firstName" [ngClass]="{'required-input': !speaker.firstName}">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="lastname" class="mb-1">{{ translatePrefix + '.LASTNAME' | translate }}</label>
                    <input type="text" class="form-control" id="lastname" name="lastname" [(ngModel)]="speaker.lastName" [ngClass]="{'required-input': !speaker.lastName}">
                </div>
            </div>
            <div class="form-group mb-3">
                <label for="serviceAuthority" class="mb-1">{{ translatePrefix + '.SERVICEAUTHORITY' | translate }}</label>
                <input type="text" class="form-control" id="serviceAuthority" name="serviceAuthority" [(ngModel)]="speaker.serviceAuthority" [ngClass]="{'required-input': !speaker.serviceAuthority}">
            </div>
            <h5 class="border-bottom pb-3 my-4">{{ translatePrefix + '.SUBHEADER1' | translate }}</h5>
            <div class="row gx-2">
                <div class="form-group col-sm-3 mb-3">
                    <label for="postalCode" class="mb-1">{{ translatePrefix + '.ZIP' | translate }}</label>
                    <input type="text" class="form-control me-2" id="postalCode" name="postalCode" [(ngModel)]="speaker.workZip">
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="place" class="mb-1">{{ translatePrefix + '.PLACE' | translate }}</label>
                    <input type="text" class="form-control" id="place" name="place" [(ngModel)]="speaker.workLocation">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="street" class="mb-1">{{ translatePrefix + '.STREET' | translate }}</label>
                    <input type="text" class="form-control" id="street" name="street" [(ngModel)]="speaker.workStreet">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="phone" class="mb-1">{{ translatePrefix + '.PHONE' | translate }}</label>
                    <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="speaker.workPhone">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="fax" class="mb-1">{{ translatePrefix + '.FAX' | translate }}</label>
                    <input type="text" class="form-control" id="fax" name="fax" [(ngModel)]="speaker.workFax">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="email" class="mb-1">{{ translatePrefix + '.EMAIL' | translate }}</label>
                    <input type="email" class="form-control" id="email" name="email" [(ngModel)]="speaker.workMail">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="website" class="mb-1">{{ translatePrefix + '.WEBSITE' | translate }}</label>
                    <input type="text" class="form-control" id="website" name="website" [(ngModel)]="speaker.workWebsite">
                </div>
            </div>
            <h5 class="border-bottom pb-3 my-4">{{ translatePrefix + '.SUBHEADER2' | translate }}</h5>
            <div class="row gx-2">
                <div class="form-group col-sm-3 mb-3">
                    <label for="privatepostalCode" class="mb-1">{{ translatePrefix + '.ZIP' | translate }}</label>
                    <input type="text" class="form-control me-2" id="privatepostalCode" name="privatepostalCode" [(ngModel)]="speaker.privateZip">
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="privateplace" class="mb-1">{{ translatePrefix + '.PLACE' | translate }}</label>
                    <input type="text" class="form-control" id="privateplace" name="privateplace" [(ngModel)]="speaker.privateLocation">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="privatestreet" class="mb-1">{{ translatePrefix + '.STREET' | translate }}</label>
                    <input type="text" class="form-control" id="privatestreet" name="privatestreet" [(ngModel)]="speaker.privateStreet">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="privatephone" class="mb-1">{{ translatePrefix + '.PHONE' | translate }}</label>
                    <input type="text" class="form-control" id="privatephone" name="privatephone" [(ngModel)]="speaker.privatePhone">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="privatefax" class="mb-1">{{ translatePrefix + '.FAX' | translate }}</label>
                    <input type="text" class="form-control" id="privatefax" name="privatefax" [(ngModel)]="speaker.privateFax">
                </div>
            </div>
            <h5 class="border-bottom pb-3 my-4">{{ translatePrefix + '.SUBHEADER3' | translate }}</h5>
            <div class="form-group mb-3">
                <label for="accountOwner" class="mb-1">{{ translatePrefix + '.ACCOUNT_OWNER' | translate }}</label>
                <input type="text" class="form-control" id="accountOwner" name="accountOwner" [(ngModel)]="speaker.bankAccountHolder">
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-3 mb-3">
                    <label for="bic" class="mb-1">{{ translatePrefix + '.BIC' | translate }}</label>
                    <input type="text" class="form-control" id="bic" name="bic" [(ngModel)]="speaker.bankBic">
                </div>
                <div class="form-group col-sm-3 mb-3">
                    <label for="blz" class="mb-1">{{ translatePrefix + '.BLZ' | translate }}</label>
                    <input type="text" class="form-control" id="blz" name="blz" [(ngModel)]="speaker.bankBlz">
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="iban" class="mb-1">{{ translatePrefix + '.IBAN' | translate }}</label>
                    <input type="text" class="form-control" id="iban" name="iban" [(ngModel)]="speaker.bankIban">
                </div>
            </div> 
        </form>
    </div>
    <div class="modal-footer">
        <div class="d-flex w-100 justify-content-end">
            <button class="btn btn-primary me-1" (click)="submit()" [disabled]="validate()">{{ translatePrefix + '.BUTTONS.SAVE' | translate }}</button>
            <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-container>