import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, finalize } from 'rxjs';
import { DepartmentFilter } from 'src/app/models/department-filter.model';
import { DepartmentResult } from 'src/app/models/department-result.model';
import { Department } from 'src/app/models/department.model';
import { Career, Status } from 'src/app/models/form.model';
import { GetQuery } from 'src/app/models/get-query.model';
import { MeetingSingUp } from 'src/app/models/meeting-sign-up.model';
import { Participant } from 'src/app/models/participant.model';
import { AuthService } from 'src/app/services/auth.service';
import { FormService } from 'src/app/services/form.service';
import { RestService } from 'src/app/services/rest.service';
import * as GLOBALS from "../../../globals";
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-register-participant-modal',
  styleUrl: './register-participant-modal.component.scss',
  templateUrl: './register-participant-modal.component.html'
})
export class RegisterParticipantModalComponent implements OnInit, OnDestroy {
  @Input() translatePrefix: string = '';
  @Input() modal?: NgbActiveModal;
  @Input() participant?: Participant;
  @Input() meetingId?: string = '';
  isLoading: boolean = false;
  isLoadingSignUp: boolean = false;
  meetingSignUp: MeetingSingUp = new MeetingSingUp();
  errorCode: string = '';
  isError: boolean = false;
  departments?: Department[] = [];
  careers?: Career[] = [];
  statuses?: Status[] = [];
  isLoadingDepartments: boolean = false;
  isLoadingForm: boolean = false;
  formSubscription?: Subscription;
  isErrorForm: boolean = false;
  isErrorDepartments: boolean = false;
  tinyConfig = GLOBALS.tinyConfig;
  role: string = '';
  adminRole: string = GLOBALS.adminRole;
    
  constructor(private restService: RestService, private authService: AuthService, private formService: FormService, private refreshService: RefreshService) { }
  
  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  ngOnInit() {
    if(this.authService.authUser?.roleName) this.role = this.authService.authUser?.roleName;

    if(this.formService.data) {
      this.careers = this.formService.data.careers;
      this.statuses = this.formService.data.statuses;
    } else {
      this.formService.updateData();
      this.isLoadingForm = true;

      this.formSubscription = this.formService.refresh.subscribe({
        next: (res: boolean) => {
          this.isLoadingForm = res;
          if(!this.isLoadingForm) {
            this.careers = this.formService.data?.careers;
            this.statuses = this.formService.data?.statuses;
            this.isErrorForm = this.formService.isError;
          }
        }
      });
    }

    this.isLoadingDepartments = true;
    this.restService.allDepartments(new DepartmentFilter(1, 25, null, null)).pipe(
      finalize(() => {
        this.isLoadingDepartments = false;
      })
    ).subscribe({
      next: res => {
        this.departments = (res as DepartmentResult).result;
        this.isErrorDepartments = false;
      }, error: err => {
        console.log(err);
        this.isErrorDepartments = true;
        this.authService.checkAuth(err);
      }
    });

    if(this.participant?.hasSignUp && this.participant?.hasSignUp !== 0) {
      this.isLoadingSignUp = true;

      let payload = new GetQuery();
      payload.keyMap.set('meetingGuid', this.meetingId??'');
      payload.keyMap.set('participantId', this.participant?.participantId??'');

      this.restService.getMeetingSignUps(payload).pipe(
        finalize(() => {
          this.isLoadingSignUp = false;
        })
      ).subscribe({
        next: res => {
          this.isError = false;
          this.meetingSignUp = res;
        }, error: err => {
          this.isError = true;
          this.errorCode = String(err.status);
          this.authService.checkAuth(err.status);
        }
      });
    }
  }

  setProperty(obj: any, property: string, value: any): void {
    obj[property] = value;
  }

  submit() {
    this.isLoading = true;
    this.meetingSignUp.meetingId = this.meetingId;
    this.meetingSignUp.participantId = this.participant?.participantId;

    this.restService.signUpParticipant(this.meetingSignUp).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe({
      next: res => {
        this.isError = false;
        this.errorCode = '';
        this.refreshService.emit(true);
        this.modal?.dismiss();
      }, error: err => {
        this.isError = true;
        this.errorCode = String(err.status);
        this.authService.checkAuth(err.status);
      }
    });
  }

  validate() {
    return !this.meetingSignUp.position || !this.meetingSignUp.departmentId || !this.meetingSignUp.statusId || !this.meetingSignUp.careerId || 
      (this.participant?.hasSignUp === 0 || this.role === this.adminRole ? false : !this.meetingSignUp.reason);
  }
}

