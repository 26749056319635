// Routes
export const routeParameterLogin: string = "login";
export const routeParameterCreateAccount: string = "createAccount";
export const routeParameterSeminar: string = "seminars";
export const routeParameterSpeaker: string = "speakers";
export const routeParameterConferenceVenue: string = "conferenceVenues";
export const routeParameterDepartment: string = "departments";
export const routeParameterRequestPasswordReset: string = "requestPasswordReset";
export const routeParameterSetPassword: string = "passwordReset";
export const routeParameterSetInitialPassword: string = "setInitialPassword";
export const routeParameterReportingOffice: string = "reportingOffices";
export const routeParameterRevisor: string = "revisors";
export const routeParameterEditSeminarArea: string = "editSeminarAreas";
export const routeParameterEditParticipantStatus: string = "editParticipantStatus";
export const routeParameterEditOrganisation: string = "editOrganisations";
export const routeParameterEditCareer: string = "editCareers";
export const routeParameterEditCategory: string = "editCategories";
export const routeParameterForbidden: string = "forbidden";
export const routeParameterHome: string = "home";
export const routeParameterParticipantApplication: string = "participantApplications";
export const routeParameterParticipant: string = "participants";
export const routeParameterSeminarRegistration: string = "seminarRegistration";
export const routeParameterKeydates: string = "keydates";
export const routeParameterRegisteredParticipant: string = "registeredParicipants";
export const routeParameterStatistic: string = "statistics";
export const routeParameterCertification: string = "certification";

// Roles
export const adminRole: string = 'admin';
export const participantRole: string = 'participant'; // = reporting office
export const noaccountRole: string = 'noaccount';
export const roles: string[] = [adminRole, participantRole, noaccountRole];
export const userAssignableRoles: string[] = [roles[0], roles[1]];

export const salutationIds: [number, string][] = [[1, 'MR'], [2, 'MRS'], [3, 'VARIOUS']];

export const yearsMin: number = 2000;

export const tinyConfig = { base_url: '/tinymce', suffix: '.min', promotion: false, height: 200, plugins: ['lists'], toolbar: ['undo redo | bold underline italic | bullist numlist outdent indent'], menubar: false};

export const invoiceStatus: [number, string][] = [[-1, '-'], [0, 'R'], [1, 'L'], [2, 'A'], [3, 'Z']];

export const quarterFilter: any = [
    ['1. Halbjahr', ['2024-01-01', '2024-06-30']], 
    ['2. Halbjahr', ['2024-07-01', '2024-12-31']],
    ['1. Quartal', ['2024-01-01', '2024-03-31']],
    ['2. Quartal', ['2024-04-01', '2024-06-30']],
    ['3. Quartal', ['2024-07-01', '2024-09-30']],
    ['4. Quartal', ['2024-10-01', '2024-12-31']]
];

// Routes for Router
export const adminRoutes: string[][] = [
    ['SEMINAR', '/' + routeParameterSeminar, 'fa-solid fa-chalkboard'],
    ['SPEAKER', '/' + routeParameterSpeaker, 'fa-solid fa-chalkboard-user'],
    ['CONFERENCE_VENUE', '/' + routeParameterConferenceVenue, 'fa-solid fa-hotel'],
    ['DEPARTMENT', '/' + routeParameterDepartment, 'fa-solid fa-building'],
    ['REPORTING_OFFICE', '/' + routeParameterReportingOffice, 'fa-solid fa-building-user'],
    ['PARTICIPANT', '/' + routeParameterParticipantApplication, 'fa-solid fa-user-graduate'],
    ['PARTICIPANT_CERTIFICATION', '/' + routeParameterCertification, 'fa-solid fa-certificate'],
    ['KEYDATES', '/' + routeParameterKeydates, 'fa-regular fa-calendar-check'],
    ['REVISOR', '/' + routeParameterRevisor, 'fa-solid fa-user'],
    ['STATISTIC', '/' + routeParameterStatistic, 'fa-solid fa-magnifying-glass-chart'],
    ['EDIT_SEMINAR_AREA', '/' + routeParameterEditSeminarArea, 'fa-solid fa-pencil'],
    ['EDIT_ORGANISATION', '/' + routeParameterEditOrganisation, 'fa-solid fa-pencil'],
    ['EDIT_CATEGORY', '/' + routeParameterEditCategory, 'fa-solid fa-pencil'],
    ['EDIT_CAREER', '/' + routeParameterEditCareer, 'fa-solid fa-pencil'],
    ['EDIT_PARTICIPANT_STATUS', '/' + routeParameterEditParticipantStatus, 'fa-solid fa-pencil']
];

// Rest
export const restParameterAccount: string = '/account';
export const restParameterAuthorize: string = '/authorize';
export const restParameterLogin: string = '/login';
export const restParameterLogout: string = '/logout';
export const restParameterUser: string = '/user';
export const restParameterCreate: string = '/create';
export const restParameterDelete: string = '/delete';
export const restParameterAll: string = '/all';
export const restParameterPassword: string = '/password';
export const restParameterForgotten: string = '/forgotten';
export const restParameterRequest: string = '/request';
export const restParameterSet: string = '/set';
export const restParameterInitial: string = '/initial';
export const restParameterForm: string = '/form';
export const restParameterArea: string = '/area';
export const restParameterCareer: string = '/career';
export const restParameterOrganisation: string = '/organisation';
export const restParameterStatus: string = '/status';
export const restParameterCategory: string = '/category';
export const restParameterAdd: string = '/add';
export const restParameterEdit: string = '/edit';
export const restParameterSeminar: string = '/seminar';
export const restParameterInfo: string = '/info';
export const restParameterConferenceVenue: string = '/conferenceVenue';
export const restParameterDepartment: string = '/department';
export const restParameterSpeaker: string = '/speaker';
export const restParameterParticipant: string = '/participant';
export const restParameterParticipants: string = '/participants';
export const restParameterSignup: string = '/signup';
export const restParameterGetById: string = '/getById';
export const restParameterMeeting: string = '/meeting';
export const restParameterNextMeeding: string = '/nextMeeting';
export const restParameterNames: string = '/names';
export const restParameterKeydates: string = '/keydates';
export const restParameterGet: string = '/get';
export const restParameterConfirmation: string = '/confirmation';
export const restParameterCancellation: string = '/cancellation';
export const restParameterUnconfirmed: string = '/unconfirmed';
export const restParameterAccept: string = '/accept';
export const restParameterReject: string = '/reject';
export const restParameterSave: string = '/save';
export const restParameterCsv: string = '/csv';
export const restParameterStatistics: string = '/statistics';
export const restParameterSummary: string = '/summary';
export const restParameterInvoiceInfo: string = '/invoiceInfo';
export const restParameterSendAll: string = '/sendall';