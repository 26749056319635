<ng-template #meetingInfo let-modal>
    <app-meeting-info-modal [translatePrefix]="translatePrefix + '.MODAL'" [modal]="modal" [meeting]="selectedMeeting"></app-meeting-info-modal>
</ng-template>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>
    <div class="mb-3">
        <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" 
            (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)" [dropdownSelect]="dropdownSelect"></app-filter-menu>
    </div>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" 
        [getAllMethod]="getAllMethod" [search]="search" [property2Columns]="property2Columns" (deleteEvent)="openModal(meetingInfo, 'meeting-info-modal', 'xl', $event)"
        (addEvent)="storeData($event)" [noPaging]="true"></app-universal-table>
</div>