<div id="pdf-container">
    <div id="pdf-participant-documents">
        <div>
            <header class="text-right fs-2" style="margin-bottom: 15rem;">
                <div class="row">
                    <div class="col-7 text-end">
                        <div>{{ translatePrefix + '.HEADER1' | translate }}</div>
                        <div>{{ translatePrefix + '.HEADER2' | translate }}</div>
                    </div>
                    <div class="col-5"></div>
                </div>
            </header>
            <div class="border border-dark fs-3">
                <div class="fw-bold d-flex justify-content-center"> {{ translatePrefix + '.SEMINAR' | translate }} </div>
                <div *ngIf="seminar" class="text-center"> <b>{{ seminar.name }}</b> </div>
                <div *ngIf="meeting" class="fw-bold row d-flex justify-content-center"> {{ meeting.startDate | date: 'd.' }} - {{ meeting.endDate | date: 'd. LLLL yyyy' }} </div>
            </div>
        </div>
        <div class="html2pdf__page-break"></div>
        <div>
            <div class="mb-5" *ngIf="seminar">
                <div class="row">
                    <div class="fs-4 text-decoration-underline d-flex justify-content-center"> {{ translatePrefix + '.SUBHEADER' | translate }} </div>
                </div>
                <div class="text-center mt-1">
                    <b>&quot;{{ seminar.name }}&quot;</b>
                </div>
                <div class="row">
                    <div class="mt-5" [innerHTML]="seminar.seminarContent"></div>
                </div>
            </div>
        <div>
        <div class="html2pdf__page-break"></div>   
        <div>
            <div class="fs-4 text-decoration-underline d-flex justify-content-center mb-3">{{ translatePrefix + '.PARTICIPANTLIST' | translate }}</div>
            <div class="mb-3">
                <div class="row">
                    <div class="col-2 fw-bold"> {{ translatePrefix + '.SEMINAR' | translate }} </div>
                    <div class="col-5" *ngIf="seminar"> {{ seminar.name }} </div>
                    <div class="col-5"> {{ translatePrefix + '.RLP1' | translate }} </div>
                </div>
                <div class="row">
                    <div class="col-2 fw-bold"> {{ translatePrefix + '.MEETINGDATE' | translate }} </div>
                    <div class="col-5" *ngIf="meeting"> {{ meeting.startDate | date: 'dd.MM.yyyy'}}&nbsp;-&nbsp;{{ meeting.endDate | date: 'dd.MM.yyyy' }} </div>
                    <div class="col-5"></div>
                </div>
                <div class="row">
                    <div class="col-2 fw-bold"> {{ translatePrefix + '.VENUE' | translate }} </div>
                    <div class="col-5" *ngIf="conferenceVenue">{{ conferenceVenue.name }} </div>
                    <div class="col-5 fw-bold"> {{ translatePrefix + '.RLP2' | translate }} </div>
                </div>
                <div class="row">
                    <div class="col-2 fw-bold"> {{ translatePrefix + '.IN' | translate }} </div>
                    <div class="col-5" *ngIf="conferenceVenue"> {{ conferenceVenue.location }} </div>
                    <div class="col-5 fw-bold"> {{ translatePrefix + '.RLP3' | translate }} </div>
                </div>
                <div class="row">
                    <div class="col-2 fw-bold"> {{ translatePrefix + '.FILENUMBER' | translate }} </div>
                    <div class="col-5" *ngIf="seminar"> {{ seminar.fileNumber }} </div>
                    <div class="col-5"></div>
                </div>
                <div class="row">
                    <div class="col-2 fw-bold"> {{ translatePrefix + '.PAGEJFP' | translate }} </div>
                    <div class="col-5" *ngIf="seminar"> {{ seminar.pageJfp }} </div>
                    <div class="col-5 fw-bold"> {{ translatePrefix + '.DATE' | translate }}:&nbsp;{{ today | date: 'dd.MM.yyyy' }} </div>
                </div>
            </div>
            <div>
                <div class="row mb-2">
                    <div class="col-5"> {{ translatePrefix + '.NAME' | translate }} </div>
                    <div class="col-7"> {{ translatePrefix + '.DEPARTMENT' | translate }} </div>
                </div>
                <div *ngFor="let participation of participations" class="row border-bottom border-dark">
                    <div class="col-5"> 
                        {{ participation.firstName }}&nbsp;{{ participation.lastName }} 
                        <ng-container *ngIf="participation.overnightStay">
                            &nbsp;*
                        </ng-container> 
                    </div>
                    <div class="col-7">{{  participation?.departmentName }}</div>
                </div>
                <div>{{ translatePrefix + '.NOOVERNIGHT' | translate }}</div>
            </div>
        </div>
        <div class="html2pdf__page-break"></div> 
        </div>
            <div class="row">
                <div class="col-5 text-decoration-underline">
                    {{ translatePrefix + '.COURSE' | translate }}
                </div>
                <div class="col-7">
                    <div *ngIf="seminar" class="row fw-bold fs-5"> {{ seminar.name }} </div>
                    <div *ngIf="meeting" class="row"> {{ translatePrefix + '.BEGIN' | translate }}:&nbsp;{{ meeting.startDate | date: 'dd.MM.yyyy' }} </div>
                    <div *ngIf="meeting" class="row"> {{ translatePrefix + '.END' | translate }}:&nbsp;{{ meeting.endDate | date: 'dd.MM.yyyy' }} </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 text-decoration-underline">{{ translatePrefix + '.SPEAKER' | translate }}</div>
                <div class="col-7">
                    <div class="row">refernet?</div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 text-decoration-underline"> {{ translatePrefix + '.VENUE' | translate }} </div>
                <div class="col-7" *ngIf="conferenceVenue">
                    <div class="row"> {{ conferenceVenue.name }} </div>
                    <div class="row"> {{ conferenceVenue.street }} </div>
                    <div class="row mb-3"> {{ conferenceVenue.zip }}&nbsp;{{ conferenceVenue.location }} </div>
                    <div class="row"> {{ translatePrefix + '.PHONE' | translate }}&nbsp;{{ conferenceVenue.phone }} </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 text-decoration-underline"> {{ translatePrefix + '.NOTICE' | translate }} </div>
                <div class="col-7 gx-0">
                    <div *ngIf="seminar" [innerHTML]="seminar.notice"></div>
                </div>
            </div>
        </div>
    </div>
</div>