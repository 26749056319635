import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Career } from 'src/app/models/form.model';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'edit-career',
  styleUrl: './edit-career.component.scss',
  templateUrl: './edit-career.component.html',
})
export class EditCareerComponent implements OnInit, OnDestroy { 
  translatePrefix: string = 'EDIT.EDIT_CAREER';
  columns: string[] = ['careerValue'];
  buttons: string[] = ['editDelBtn'];
  careers?: Career[] = [];
  addMethod: string = 'addCareer';
  editMethod: string = 'editCareer';
  deleteMethod: string = 'deleteCareer';
  keys: string[] = ['careerId', 'careerFilter'];
  formSubscription?: Subscription;

  constructor(private formService: FormService) { }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }
  
  ngOnInit() { 
    this.formSubscription = this.formService.refresh.subscribe({
      next: (res: boolean) => {
        if(!res) {
          this.careers = this.formService.data?.careers;
        }
      }
    });

    this.formService.updateData();
  }
}