import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

// @ts-ignore
import * as html2pdf from 'html2pdf.js';
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class PdfService {
    private pdfEventSubject: Subject<boolean> = new Subject();
    printCompleted: Observable<boolean> = this.pdfEventSubject.asObservable();
    footerTranslates: string[] = [
        'TIMELINE1', 'TIMELINE2', 'TIMELINE3', 'TIMELINE4', // footer first block
        'TRAFFICLINE1', 'TRAFFICLINE2', 'TRAFFICLINE3', 'TRAFFICLINE4', // etc.
        'PARKLINE1', 'PARKLINE2', 'PARKLINE3', 'PARKLINE4'
    ];
    savedFiles: File[] = [];

    constructor(private translateService: TranslateService) { }

    private generatePdf(element: HTMLElement, filename: string, printdialog?: boolean, header?: boolean, footer?: boolean, saveOnly?: boolean, skipEmit?: boolean, pagebreak?: any) {
        this.pdfEventSubject.next(true);
        let pdfContainer = element.cloneNode(true);
        let opt = {
            useCORS: true,
            margin: [1.5, 1, 1, 1],
            filename: filename + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            pagebreak: pagebreak ? pagebreak :  { mode: ['avoid-all', 'css'] }
        };

        html2pdf().from(pdfContainer).set(opt).toPdf().get('pdf').then( (pdf: any) => {
            if(footer || header) {
                let totalpages = pdf.internal.getNumberOfPages();
                let img = new Image();
                img.src = 'assets/png/mdi_logo.png';

                for(let i = 1; i <= totalpages; i++) {
                    pdf.setPage(i);
                    if(header) pdf.addImage(img, 'png', 5.6, 0.2, 2.6, 1.15);

                    let marginX = 1;
                    let marginY = 0;
                    if(footer) {
                        pdf.setFontSize(7);
                        pdf.setTextColor(40, 40, 40);
                        for(let y = 0; y < this.footerTranslates.length; y++) {
                            y % 4 !== 0 ? pdf.setFont('helvetica', 'normal') : pdf.setFont('helvetica', 'bold');

                            if(y % 4 === 0 && y !== 0) {
                                marginX = marginX + 2.5;
                                marginY = 0;
                            }

                            marginY++;
                            pdf.text(1 * marginX, 11 + (0.1 * marginY), this.translateService.instant('PDF.FOOTER.' + this.footerTranslates[y]));
                        }
                    }
                }
            }

            let url = window.URL.createObjectURL(pdf.output('blob'));
            if(printdialog === false) {
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.download = filename + '.pdf';
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else if(printdialog === true) {
                window.open(url, '_blank');
            } 
            
            if(saveOnly === true) {
                this.savedFiles.push(new File([pdf.output('blob')], filename));
            }

            if(skipEmit === undefined || skipEmit === false) this.pdfEventSubject.next(false);
        });
    }

    print(filename: string, id?: string, timeout?: number, printdialog?: boolean, header?: boolean, footer?: boolean, saveOnly?: boolean, skipEmit?: boolean, pagebreak?: any) {
        let element = document.getElementById(id ? id : 'pdf');
        if(!element) return;

        setTimeout(() => {
            this.generatePdf(element!, filename, printdialog, header, footer, saveOnly, skipEmit, pagebreak);
        }, timeout ? timeout : 10);
    }

    clear() {
        this.savedFiles = [];
    }
}