import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Area } from 'src/app/models/form.model';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'edit-seminar-area',
  styleUrl: './edit-seminar-area.component.scss',
  templateUrl: './edit-seminar-area.component.html',
})
export class EditSeminarAreaComponent implements OnInit, OnDestroy { 
  translatePrefix: string = 'EDIT.EDIT_SEMINAR_AREA';
  columns: string[] = ['areaValue'];
  buttons: string[] = ['editDelBtn'];
  areas?: Area[] = [];
  addMethod: string = 'addArea';
  editMethod: string = 'editArea';
  deleteMethod: string = 'deleteArea';
  keys: string[] = ['areaId', 'areaFilter'];
  formSubscription?: Subscription;
  
  constructor(private formService: FormService) { }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  ngOnInit() { 
    this.formSubscription = this.formService.refresh.subscribe({
      next: (res: boolean) => {
        if(!res) {
          this.areas = this.formService.data?.areas;
        }
      }
    });

    this.formService.updateData();
  }
}