<ng-template #tablebuttons let-type="type" let-item="item" let-item2="item2" let-last="last">
    <button *ngIf="type === 'delBtn'" type="button" class="btn btn-primary" [ngClass]="{'me-1': !last}" (click)="emitDelete(item)" placement="bottom" ngbTooltip="{{ 'TABLE.DEL' | translate }}">
        <i class="fa-solid fa-trash-can"></i>
    </button>
    <button *ngIf="type === 'editBtn'" type="button" class="btn btn-primary" [ngClass]="{'me-1': !last}" (click)="emitEdit(item)" placement="bottom" ngbTooltip="{{ 'TABLE.EDIT' | translate }}">
        <i class="fa-solid fa-pencil"></i>
    </button>
    <button *ngIf="type === 'addMeetingBtn'" type="button" class="btn btn-primary" [ngClass]="{'me-1': !last}" (click)="emitAdd(item)" placement="bottom" ngbTooltip="{{ 'TABLE.ADD' | translate }}">
        <i class="fa-solid fa-calendar-plus"></i>
    </button>
    <button *ngIf="type === 'addParticipantBtn'" type="button" class="btn btn-primary" [ngClass]="{'me-1': !last}" (click)="emitAdd(item)" placement="bottom" ngbTooltip="{{ 'TABLE.REGISTERPARTICIPANT' | translate }}">
        <ng-container *ngIf="getAllMethod === 'allParticipants'">
            <i *ngIf="item.hasSignUp === 0 || item.hasSignUp === null" class="fa-solid fa-plus"></i>
            <i *ngIf="item.hasSignUp > 0" class="fa-solid fa-square-pen"></i>
        </ng-container>
        <i  *ngIf="getAllMethod !== 'allParticipants'" class="fa-solid fa-plus"></i>
    </button>
    <button *ngIf="type === 'editSubTableBtn'" type="button" class="btn btn-primary" [ngClass]="{'me-1': !last}" (click)="emitSubTableEdit(item)" placement="bottom" ngbTooltip="{{ 'TABLE.EDIT' | translate }}">
        <i class="fa-solid fa-pencil"></i>
    </button>
    <button *ngIf="type === 'deleteSubTableBtn'" type="button" class="btn btn-primary" [ngClass]="{'me-1': !last}" (click)="emitSubTableDelete(item)" placement="bottom" ngbTooltip="{{ 'TABLE.DEL' | translate }}">
        <i class="fa-solid fa-trash-can"></i>
    </button>
    <button *ngIf="type === 'exportBtn'" type="button" class="btn btn-primary" [ngClass]="{'me-1': !last}" (click)="emitExport([item, item2])" placement="bottom" ngbTooltip="{{ 'TABLE.EXPORT' | translate }}">
        <i class="fa-solid fa-file-export"></i>
    </button>
    <button *ngIf="type === 'infoBtn'" type="button" class="btn btn-primary" (click)="getAllMethod !== 'allSeminars' ? emitDelete(item) : emitSubTableAdd([item, item2])" [ngClass]="{'me-1': !last}" placement="bottom" ngbTooltip="{{ getAllMethod !== 'allSeminars' ? ('TABLE.INFO' | translate) : ('TABLE.PARTICIPANT' | translate) }}">
        <i class="fa-solid fa-circle-info"></i>
    </button>
    <button *ngIf="type === 'eyeInfoBtn'" type="button" class="btn btn-primary" (click)="emitEdit(item)" [ngClass]="{'me-1': !last}" placement="bottom" ngbTooltip="{{ 'TABLE.INFO' | translate }}">
        <i class="fa-solid fa-eye"></i>
    </button>
    <button *ngIf="type === 'registrationBtn'" type="button" class="btn btn-primary" (click)="emitAdd(item)" [ngClass]="{'me-1': !last}" placement="bottom" ngbTooltip="{{ 'TABLE.REGISTERPARTICIPANT' | translate }}">
        <div class="d-flex flex-nowrap">
            <i class="fa-solid fa-user-graduate"></i>
            <i class="fa-solid fa-plus"></i>
        </div>
    </button>
    <button *ngIf="type === 'mailAck'" type="button" class="btn btn-primary" (click)="emitMailAccept(item)" [ngClass]="{'me-1': !last}" placement="bottom">
        <div class="d-flex flex-nowrap">
            <i class="fa-regular fa-envelope me-1"></i>
            <i class="fa-solid fa-check"></i>
        </div>
    </button>
    <button *ngIf="type === 'mailNack'" type="button" class="btn btn-primary" (click)="emitMailReject(item)" [ngClass]="{'me-1': !last}" placement="bottom">
        <div class="d-flex flex-nowrap">
            <i class="fa-regular fa-envelope me-1"></i>
            <i class="fa-solid fa-xmark"></i>
        </div>
    </button>
</ng-template>

<ng-template #tablecols let-col="col" let-item="item" let-type="type"> 
    <ng-container *ngIf="type === 'text'">
        <div *ngIf="maxTextLength === 0" [title]="getProperty(item, col)">{{ getProperty(item, col) }}</div>
        <div *ngIf="maxTextLength !== 0 && getProperty(item, col) && getProperty(item, col).length <= maxTextLength" [title]="getProperty(item, col)">{{ getProperty(item, col) }}</div>
        <div *ngIf="maxTextLength !== 0 && getProperty(item, col) && getProperty(item, col).length > maxTextLength" class="d-inline-block text-truncate" style="max-width: 12rem;" [title]="getProperty(item, col)">{{ getProperty(item, col) }}</div>
    </ng-container>
    <ng-container *ngIf="type === 'number'">
        <div [title]="getProperty(item, col)">{{ getProperty(item, col) }}</div>
    </ng-container>
    <ng-container *ngIf="type === 'bool'">
        <input type="checkbox" [checked]="getProperty(item, col)" (click)="setProperty(item, col, !getProperty(item, col)); emitTableContent()">
    </ng-container>
    <ng-container *ngIf="type === 'date'">
        <div [title]="getProperty(item, col) | date:'dd.MM.yyyy'">{{ getProperty(item, col) | date:'dd.MM.yyyy' }}</div>
    </ng-container>
    <ng-container *ngIf="type === 'invoiceStatusDropdown'">
        <select class="form-select" [ngModel]="getProperty(item, col)" (ngModelChange)="setProperty(item, col, $event); emitTableContent()">
            <option *ngFor="let status of invoiceStatus" class="form-option" [ngValue]="status[0]">{{ status[1] }}</option>
        </select>
    </ng-container>
    <ng-container *ngIf="type === 'status'">
        <i *ngIf="getProperty(item, col) === 1" class="fa-solid fa-check text-green"></i>
        <i *ngIf="getProperty(item, col) === 2" class="fa-solid fa-xmark text-red"></i>
    </ng-container>
</ng-template>

<div>
    <div class="table-responsive">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th *ngFor="let col of columns; index as index">
                        <div class="d-flex">
                            {{ translatePrefix + '.COL.' + col | uppercase | translate }}
                            <div *ngIf="filters[index] && search && getAllMethod !== 'allSeminarMeetings' && !noSorting" class="ms-3 filter-wrap">
                                <div class="filter-button-up">
                                    <i class="fa-solid fa-caret-up row g-0" [ngClass]="{'text-red opacity-50': paging.orderColumn === col && paging.orderDirection === 'asc'}" (click)="toggleSort(col, paging.orderDirection === 'asc' ? '' : 'asc')"></i>
                                </div>
                                <div class="filter-button-down">
                                    <i class="fa-solid fa-caret-down row g-0" [ngClass]="{'text-red opacity-50': paging.orderColumn === col && paging.orderDirection === 'desc'}" (click)="toggleSort(col, paging.orderDirection === 'desc' ? '' : 'desc')"></i>
                                </div>
                            </div>
                            <div *ngIf="filters[index] && getAllMethod !== 'allSeminarMeetings' && !noFilterInTable" ngbDropdown class="ms-3" container="body">
                                <i ngbDropdownToggle id="filterDropdown" class="fa-solid fa-filter pt-1" [ngClass]="{'text-red opacity-50': getProperty(search, col) != null}"></i>
                                <div ngbDropdownMenu aria-labelledby="filterDropdown" style="max-width: 200px;">
                                    <div class="mx-3 mb-1">
                                        <div class="mb-1"> {{ translatePrefix + '.COL.' + col | uppercase | translate }} </div>
                                        <filter [type]="filters[index]" [resetButton]="true" [search]="getProperty(search, col)" [column]="col" (filterEvent)="setProperty(search, col, $event); emitFilterChange();"></filter>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </th>
                    <th *ngIf="buttons.length !== 0"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of list; index as indexlist; first as first">
                    <ng-container *ngIf="registeredParticipantPage && first">
                        <tr>
                            <td colspan="100" class="bg-primary" style="color: white;">{{item.reportingOffice}}</td>
                        </tr>
                    </ng-container>
                    <tr class="align-middle">
                        <ng-container *ngFor="let col of columns; last as last; index as index">
                            <td *ngIf="property2Columns.length === 0" [ngClass]="{'min-size': buttons.length === 0}">
                                <ng-container [ngTemplateOutlet]="tablecols" [ngTemplateOutletContext]="{col: col, item: item, type: fieldTypes.length !== 0 ? fieldTypes[index] : filters[index]}"></ng-container>
                            </td>
                            <td *ngIf="property2Columns.length !== 0" [ngClass]="{'min-size': buttons.length === 0}">
                                <ng-container [ngTemplateOutlet]="tablecols" [ngTemplateOutletContext]="{col: col, item: getProperty(item, property2Columns[index]), type: fieldTypes.length !== 0 ? fieldTypes[index] : filters[index]}"></ng-container>
                            </td>
                            <td *ngIf="last && buttons.length !== 0" [ngClass]="{'min-size': buttons.length === 0}">
                                <div class="d-flex justify-content-end">
                                    <ng-container *ngFor="let button of buttons; last as last" [ngTemplateOutlet]="tablebuttons" [ngTemplateOutletContext]="{type: button, item: item, last: last}"></ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                    <ng-container *ngIf="registeredParticipantPage && list.length !== indexlist + 1 && checkHeader(item.reportingOffice, list[indexlist + 1].reportingOffice)">
                        <tr>
                            <td colspan="100" class="bg-primary" style="color: white;">{{list[indexlist + 1].reportingOffice}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="getAllMethod === 'allSeminars'">
                        <tr *ngFor="let meeting of item.meetings" class="align-middle">
                            <td colspan="2" [ngClass]="{'min-size': subTableButtons.length === 0}">
                                <div class="d-flex align-items-center">
                                    <div *ngIf="meetingSelection" class="me-2">
                                        <ng-container [ngTemplateOutlet]="tablecols" [ngTemplateOutletContext]="{col: 'selected', item: meeting, type: 'bool'}"></ng-container>
                                    </div>
                                    <i *ngIf="!meetingSelection" class="fa-solid fa-calendar-days fa-lg text-primary"></i>
                                </div>
                            </td>
                            <td [ngClass]="{'min-size': subTableButtons.length === 0}">
                                <div class="d-flex">
                                    <ng-container [ngTemplateOutlet]="tablecols" [ngTemplateOutletContext]="{col: 'startDate', item: meeting, type: 'date'}"></ng-container>&nbsp;-&nbsp;
                                    <ng-container [ngTemplateOutlet]="tablecols" [ngTemplateOutletContext]="{col: 'endDate', item: meeting, type: 'date'}"></ng-container> 
                                </div>
                            </td>
                            <td [ngClass]="{'min-size': subTableButtons.length === 0}">
                                <div class="d-flex">
                                    <ng-container [ngTemplateOutlet]="tablecols" [ngTemplateOutletContext]="{col: 'signedParticipants', item: meeting, type: 'number'}"></ng-container>&nbsp;/&nbsp;
                                    <ng-container [ngTemplateOutlet]="tablecols" [ngTemplateOutletContext]="{col: 'expectedParticipants', item: meeting, type: 'number'}"></ng-container>
                                </div>
                            </td>
                            <td *ngIf="subTableButtons.length !== 0">
                                <div class="d-flex justify-content-end">
                                    <ng-container *ngFor="let button of subTableButtons; last as last" [ngTemplateOutlet]="tablebuttons" [ngTemplateOutletContext]="{type: button, item: meeting, item2: item, last: last}"></ng-container>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <tr *ngIf="isLoading && !isError">
                    <td colspan="100">
                        <div class="text-center w-100">
                            <i class="fa-solid fa-circle-notch fa-spin"></i>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="!isLoading && isError">
                    <td colspan="100" class="table-danger">
                        <div class="text-center w-100">
                            {{ 'ERRORCODE.' + errorCode | translate }}
                        </div>
                    </td>
                </tr>
                <tr *ngIf="list.length == 0 && !isLoading && !isError">
                    <td colspan="100">
                        <div class="text-center w-100">
                            {{ translatePrefix + '.NORESULT' | translate }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-container *ngIf="!noPaging">
        <app-pagination *ngIf="search && searchResult && list.length != 0" class="mt-3" [isLoading]="isLoading" [pages]="pages" [currentPage]="search.page" [pageSize]="search.pageSize" 
            [totalCount]="searchResult.totalCount" (resultsChanged)="setProperty(search, 'pageSize', $event); changeResultsAmount($event);" (pageChanged)="setProperty(search, 'page', $event); changePage($event)"></app-pagination>
    </ng-container>
</div>