<div>
    <div class="row justify-content-center mx-auto">
        <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 d-flex justify-content-center">
            <div class="card w-100">
                <div class="card-header">
                    <h5 class="card-title">{{ translatePrefix + '.HEADER' | translate }}</h5>
                </div>
                <div class="card-body">
                    <form name="RequestPasswordForm">
                        <div [ngClass]="{'mb-4': !isSuccess, 'mb-3': isSuccess}">
                            {{ translatePrefix + '.DESCRIPTION' | translate }}
                        </div>
                        <div *ngIf="isSuccess" class="mb-4 alert alert-success" role="alert">
                            <div class="d-flex">
                                <i class="fa-solid fa-check mt-1 me-2"></i>
                                {{ translatePrefix + '.SENDSUCCESS' | translate }}&nbsp;
                                {{ eMail }}
                            </div>
                        </div>
                        <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                            <div class="d-flex">
                                <i class="fa-solid fa-xmark mt-1 me-2"></i>
                                {{ 'ERRORCODE.' + errorCode | translate }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email" class="mb-1">{{ translatePrefix + '.EMAIL' | translate }}*</label>
                            <input type="email" class="form-control" id="email" name="email" [(ngModel)]="eMail" required>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center my-1">
                        <a [routerLink]="loginPath">
                            <i class="fa-solid fa-caret-left me-1"></i>
                            {{ translatePrefix + '.BUTTONS.LOGIN' | translate }}
                        </a>
                        <button type="submit" class="btn btn-primary" style="width: 150px;" (click)="request()" [disabled]="validate()">{{ translatePrefix + '.BUTTONS.REQUEST' | translate }}</button>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>