import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { RefreshService } from 'src/app/services/refresh.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html', 
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent {
  @Input() modal?: NgbActiveModal;
  @Input() translatePrefix: string = '';
  @Input() method?: string;
  @Input() payload?: any;
  @Input() diffServerErrorMessage?: string;
  isLoading: boolean = false;
  isError: boolean = false;
  errorCode: string = '';

  constructor(private restService: RestService, private refreshService: RefreshService) { }

  submit() {
    if(!this.method) return;
    
    this.isLoading = true;
    this.errorCode = "";

    (this.payload ? (this.restService as any)[this.method](this.payload) : (this.restService as any)[this.method]()).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    ).subscribe(
      (response: any) => {
        this.refreshService.emit(true);
        this.modal?.dismiss();
      },
      (error: any) => {
        this.isError = true;
        this.errorCode = error.status == 500 ? (this.diffServerErrorMessage ? this.diffServerErrorMessage : String(error.status)) : String(error.status);
      }
    );
  }
}