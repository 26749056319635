<ng-container *ngIf="modal">
    <div class="modal-header">
        <h4 class="modal-title">{{ translatePrefix + '.HEADER' | translate }}</h4>
        <button type="button" class="close btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form name="createParticipantForm" *ngIf="participant">
            <div class="mb-4 alert alert-info" role="alert">
                {{ 'FORMS.REQUIRED' | translate }}
            </div>
            <div *ngIf="isError" class="mb-4 alert alert-danger" role="alert">
                <div class="d-flex">
                    <i class="fa-solid fa-xmark mt-1 me-2"></i>
                    {{ 'ERRORCODE.' + errorCode | translate }}
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="salutaion" class="mb-1">{{ translatePrefix + '.SALUTATION' | translate }}</label>
                    <select class="form-select" id="salutaion" name="salutaion" [(ngModel)]="participant.salutationId" [ngClass]="{'required-input': !participant.salutationId}" required>
                        <ng-container *ngFor="let salutaion of salutaions">
                            <option class="form-option" [value]="salutaion[0]">{{ translatePrefix + '.SALUTATIONS.' + salutaion[1] | translate }}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="academicTitle" class="mb-1">{{ translatePrefix + '.TITLE' | translate }}</label>
                    <input type="text" class="form-control" id="academicTitle" name="academicTitle" [(ngModel)]="participant.academicTitle">
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-6 mb-3">
                    <label for="firstname" class="mb-1">{{ translatePrefix + '.FIRSTNAME' | translate }}</label>
                    <input type="text" class="form-control" id="firstname" name="firstname" [(ngModel)]="participant.firstName" [ngClass]="{'required-input': !participant.firstName}" required>
                </div>
                <div class="form-group col-sm-6 mb-3">
                    <label for="lastname" class="mb-1">{{ translatePrefix + '.LASTNAME' | translate }}</label>
                    <input type="text" class="form-control" id="lastname" name="lastname" [(ngModel)]="participant.lastName" [ngClass]="{'required-input': !participant.lastName}" required>
                </div>
            </div>
            <div class="row gx-2">
                <div class="form-group col-sm-12 mb-3">
                    <label for="email" class="mb-1">{{ translatePrefix + '.EMAIL' | translate }}</label>
                    <input type="email" class="form-control" id="email" name="email" autocomplete="email" [(ngModel)]="participant.participantEmail">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="d-flex w-100 justify-content-end">
            <button class="btn btn-secondary me-1" (click)="submit()" [disabled]="validate() || isLoading">
                {{ translatePrefix + '.BUTTONS.SAVE' | translate }}
                <i *ngIf="isLoading" class="fa-solid fa-circle-notch fa-spin"></i>
            </button>
            <button class="btn btn-primary" (click)="modal.dismiss()">{{ translatePrefix + '.BUTTONS.CANCEL' | translate }}</button>
        </div>
    </div>
</ng-container>