<ng-template #deleteParticipation let-modal>
    <app-generic-modal [translatePrefix]="'DELETE.PARTICIPANT'" [modal]="modal" [payload]="payload" [method]="deleteMethod"></app-generic-modal>
</ng-template>

<ng-template #editParticipation let-modal>
    <app-edit-application [translatePrefix]="translatePrefix + '.MODAL'" [modal]="modal" [participation]="selectedParticipation" [isAccepted]="isAccepted"></app-edit-application>
</ng-template>

<div>
    <header class="border-bottom mb-4">
        <div class="mb-3">
            <h4>{{ translatePrefix + '.HEADER' | translate }}</h4>
        </div>
    </header>    
    <div class="alert alert-danger" role="alert">
        {{ translatePrefix + '.REMAININGAPPLICATIONS' | translate }}&nbsp;{{ totalRemaining }}
    </div>
    <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU.REMAINING'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" (resetEvent)="resetFilter()" (filterEvent)="setSearch($event)"></app-filter-menu>
    <button type="submit" class="btn btn-primary me-2 my-3" style="width: 100px;" placement="bottom" ngbTooltip="{{ translatePrefix + '.ADDPARTICIPANT' | translate }}" [routerLink]="seminarSearchPath">
        <i class="fa-solid fa-user-graduate"></i>
        <i class="fa-solid fa-plus"></i>
    </button>
    <div class="mb-5">
        <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttons" [filters]="filters" 
            [getAllMethod]="getAllUnconfirmedMethod" [search]="search" (deleteEvent)="openModal(editParticipation, 'edit-participation-modal', 'xl', $event)" 
            [noPaging]="true" [noSorting]="true" [property2Columns]="property2Columns" [fieldTypes]="fieldTypes" [maxTextLength]="30"></app-universal-table>
    </div>
    <div class="mb-3">
        <app-filter-menu [translatePrefix]="translatePrefix + '.FILTERMENU.ACCEPTED'" [columns]="columnsFilterMenu" [filters]="filtersFilterMenu" [search]="search" (resetEvent)="resetAcceptedFilter()" (filterEvent)="setSearchAccepted($event)" [isClosed]="true"></app-filter-menu>
    </div>
    <app-universal-table [translatePrefix]="translatePrefix + '.TABLE'" [columns]="columns" [buttons]="buttonsAccepted" [filters]="filters" (editEvent)="openModal(editParticipation, 'edit-participation-modal', 'xl', $event, true)"
        [getAllMethod]="getAllMethod" [search]="searchAccepted" (deleteEvent)="setPayload($event); openModal(deleteParticipation, 'delete-participation-modal', '')" [fieldTypes]="fieldTypes" [maxTextLength]="30"></app-universal-table>
</div>