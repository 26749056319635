export class Speaker {
    speakerId?: string = '';
    academicTitle?: string | null = null;
    salutationId?: number = 255;
    firstName?: string = '';
    lastName?: string = '';
    serviceAuthority?: string = '';
    workStreet?: string | null = null;
    workZip?: string | null = null;
    workLocation?: string | null = null;
    workPhone?: string | null = null;
    workFax?: string | null = null;
    workMail?: string | null = null;
    workWebsite?: string | null = null;
    privateStreet?: string | null = null;
    privateZip?: string | null = null;
    privateLocation?: string | null = null;
    privatePhone?: string | null = null;
    privateFax?: string | null = null;
    bankAccountHolder?: string | null = null;
    bankBlz?: string | null = null;
    bankIban?: string | null = null;
    bankBic?: string | null = null;

    constructor(speakerId?: string, academicTitle?: string | null, salutationId?: number, firstName?: string, lastName?: string, serviceAuthority?: string, workStreet?: string | null,
        workZip?: string | null, workLocation?: string | null, workPhone?: string | null, workFax?: string | null, workMail?: string | null, workWebsite?: string | null,
        privateStreet?: string | null, privateZip?: string | null, privateLocation?: string | null, privatePhone?: string | null, privateFax?: string | null, bankAccountHolder?: string | null,
        bankBlz?: string | null, bankIban?: string | null, bankBic?: string | null) {

        this.speakerId = speakerId;
        this.academicTitle = academicTitle;
        this.salutationId = salutationId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.serviceAuthority = serviceAuthority;
        this.workStreet = workStreet;
        this.workZip = workZip;
        this.workLocation = workLocation;
        this.workPhone = workPhone;
        this.workFax = workFax;
        this.workMail = workMail;
        this.workWebsite = workWebsite;
        this.privateStreet = privateStreet;
        this.privateZip = privateZip;
        this.privateLocation = privateLocation;
        this.privatePhone = privatePhone;
        this.privateFax = privateFax;
        this.bankAccountHolder = bankAccountHolder;
        this.bankBlz = bankBlz;
        this.bankIban = bankIban;
        this.bankBic = bankBic;
    }
}

export class SpeakerNames {
    fullName: string = "";
    speakerId: string = "";
    isHidden: boolean = false;
}