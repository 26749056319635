import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DepartmentFilter } from 'src/app/models/department-filter.model';
import { Department } from 'src/app/models/department.model';
import { GetQuery } from 'src/app/models/get-query.model';

@Component({
  selector: 'app-department',
  styleUrl: './department.component.scss',
  templateUrl: './department.component.html',
})
export class DepartmentComponent { 
  translatePrefix: string = 'FORMS.DEPARTMENT';
  columns: string[] = ['shorthand', 'name'];
  buttons: string[] = ['editBtn', 'delBtn'];
  filters: string[] = ['text', 'text'];
  columnsFilterMenu: string[] = this.columns;
  filtersFilterMenu: string[] = this.filters;
  getAllMethod: string = 'allDepartments';
  deleteMethod: string = 'deleteDepartment';
  payload?: GetQuery;
  selectedDepartment?: Department;
  search?: DepartmentFilter = new DepartmentFilter(1, 25, 'shorthand', 'asc');

  constructor(private modalService: NgbModal) { }

  openModal(modal: any, ariaLabelledBy: string, size: string, department?: Department) {
    this.selectedDepartment = department;
    this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
  }

  resetFilter() {
    this.search = new DepartmentFilter(1, 25, 'shorthand', null);
  }

  setSearch(event: DepartmentFilter) {
    this.search = new DepartmentFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection, event.shorthand, event.name);
  }

  setPayload(event: Department) {
    this.payload = new GetQuery();
    this.payload.keyMap.set('uniqueIdentifier', event.departmentId??'');
  }
}