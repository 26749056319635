import { Component } from '@angular/core';
import { GetQuery } from 'src/app/models/get-query.model';
import { UserFilter } from 'src/app/models/user-filter.model';
import { User } from 'src/app/models/user.model';
import * as GLOBALS from "../../globals";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reporting-office',
  styleUrl: './reporting-office.component.scss',
  templateUrl: './reporting-office.component.html',
})
export class ReportingOfficeComponent {
    translatePrefix: string = 'FORMS.REPORTING_OFFICE';
    columns: string[] = ['userName', 'firstName', 'location'];
    buttons: string[] = ['editBtn', 'delBtn'];
    filters: string[] = ['text', 'text', 'text'];
    columnsFilterMenu: string[] = this.columns;
    filtersFilterMenu: string[] = this.filters;
    getAllMethod: string = 'allUsers';
    deleteMethod: string = 'deleteAccount';
    payload?: GetQuery;
    selectedUser?: User;
    search?: UserFilter = new UserFilter(1, 25, 'userName', 'asc');

    constructor(private modalService: NgbModal) { }

    ngOnInit() {
      this.search!.roleName = GLOBALS.participantRole;
    }

    openModal(modal: any, ariaLabelledBy: string, size: string, user?: User) {
      this.selectedUser = user;
      this.modalService.open(modal, { ariaLabelledBy: ariaLabelledBy, size: size, backdrop: 'static' });
    }

    resetFilter() {
      this.search = new UserFilter(1, 25, 'userName', null);
      this.search.roleName = GLOBALS.participantRole;
    }
    
    setSearch(event: UserFilter) {
      this.search = new UserFilter(event.page, event.pageSize, event.orderColumn, event.orderDirection);
    }

    setPayload(event: User) {
      this.payload = new GetQuery();
      this.payload.keyMap.set('uniqueIdentifier', event.uniqueIdentifier??'');
    }
}